import { NgClass, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, booleanAttribute } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { APPOINTMENT_CONSTANT } from '../../../../constants/appointment-constants';
import { TIME_FORMAT } from '../../../../constants/date.constants';
import { TRANSLATION_TEMPLATES } from '../../../../constants/translation-templates-constants';
import { MAX_SLOTS_VISIBLE_LENGTH, WIDGET_CONSTANTS } from '../../../../constants/widget-constants';
import { AppointmentsSlotsDao } from '../../../../db-models/free-appointment-dao';
import { WidgetAppointmentGroupModel } from '../../../../db-models/widget-appointment-group.dao';
import { CustomBookingMessageTemplate } from '../../../../db-models/widget-conf-dao';
import { CartItem } from '../../../../models/cart.model';
import { Partner } from '../../../../models/global';
import { AppointmentState, ConferenceState } from '../../../../models/state.model';
import { WidgetColorConf } from '../../../../models/widget-color.model';
import { AppointmentService } from '../../../../services/appointment.service';
import { WidgetUtilService } from '../../../../services/widget-util.service';
import { AlertComponent } from '../../../../shared/components/alert/alert.component';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { LoaderComponent } from '../../../../shared/components/loader/loader.component';
import { DateUtcPipe } from '../../../../shared/pipes/date_utc.pipe';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';
import { CwCheckboxRadioButtonComponent } from '../../../common/theme/cw-checkbox-radio-button/cw-checkbox-radio-button.component';

@Component({
  selector: 'app-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.scss'],
  standalone: true,
  imports: [LoaderComponent, AlertComponent, ButtonComponent, FormsModule, MatTabsModule, NgTemplateOutlet, NgClass, CwCheckboxRadioButtonComponent, FontAwesomeModule, DateUtcPipe, TranslateModule, TranslationPipe]
})
export class AppointmentCardComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() freeAppointments: {
    dayPeriod: string,
    appointments: AppointmentsSlotsDao[],
    showDayPeriodLabel: boolean,
    widgetGroup: WidgetAppointmentGroupModel,
    loadMore?: boolean
    selectedSlots?: number
  }[];
  @Input() appointmentState: AppointmentState;
  @Input() noAppointments: boolean;
  @Input() cart: CartItem[];
  @Input() partner: Partner;
  @Input() lang: string;
  @Input() widgetColorConf?: WidgetColorConf;
  @Input() showEgalWer: boolean;
  @Input() freeAppointmentsCount = 0;
  @Input() widgetTemplates: CustomBookingMessageTemplate[] = [];
  @Input() conferenceState: ConferenceState;
  @Input({
    transform: booleanAttribute
  }) showAppointmentSlots = false;

  @Output() updateCartEvent = new EventEmitter<any>();
  @Output() gotoNextPageEvent = new EventEmitter<any>();

  readonly maxSlotsVisibleLength = MAX_SLOTS_VISIBLE_LENGTH;
  readonly appointmentConstant = APPOINTMENT_CONSTANT;
  readonly deployUrl = environment.deployUrl;
  readonly timeFormat = TIME_FORMAT;
  readonly templateContent = TRANSLATION_TEMPLATES;

  loadMoreSlots = false;
  widgetMorningLabelTemplate: CustomBookingMessageTemplate;
  widgetForenoonLabelTemplate: CustomBookingMessageTemplate;
  widgetNoonLabelTemplate: CustomBookingMessageTemplate;
  widgetAfternoonLabelTemplate: CustomBookingMessageTemplate;
  widgetEveningLabelTemplate: CustomBookingMessageTemplate;
  widgetNightLabelTemplate: CustomBookingMessageTemplate;
  noFreeAppointments: CustomBookingMessageTemplate;
  selectedSlotBackgroundColor = '#ebf1fb';
  selectedSlotBorderColor = '1px solid #3A4A66';
  cartSupported: boolean;
  hide_widget_cart_functionality_message: number;
  hasGroups = false;
  availableSlotsCount: number;

  constructor(
    private appointmentService: AppointmentService,
    private widgetUtilService: WidgetUtilService,
    public translate: TranslateService,
    private _renderer: Renderer2
  ) {
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
  }

  ngOnInit(): void {
    this.cartSupported = this.widgetUtilService.getWidgetConf().cartSupported;
    this.hide_widget_cart_functionality_message = this.widgetUtilService.getWidgetConf().hide_widget_cart_functionality_message;
    this.availableSlotsCount = this.freeAppointments[0]?.appointments?.length >= this.maxSlotsVisibleLength ? 10 : this.maxSlotsVisibleLength;

    this.freeAppointments.forEach(freeAppointmentGroup => {
      this.updateGroups(freeAppointmentGroup);
    });

    this.appointmentService.resetSlotSettingsEvent.subscribe(() => {
      this.freeAppointments.forEach(freeAppointmentGroup => {
        this.updateGroups(freeAppointmentGroup);
      });
    });
  }

  updateGroups(freeAppointmentGroup: {
    dayPeriod: string,
    appointments: AppointmentsSlotsDao[],
    showDayPeriodLabel: boolean,
    widgetGroup: WidgetAppointmentGroupModel,
    loadMore?: boolean
    selectedSlots?: number
  }): void {
    if (freeAppointmentGroup.widgetGroup?.only_show_one_element === 1) {
      freeAppointmentGroup.appointments = (freeAppointmentGroup.widgetGroup?.direction === 'desc')
        ? freeAppointmentGroup.appointments.slice(-1)
        : freeAppointmentGroup.appointments.slice(0, 1);
    } else {
      if (freeAppointmentGroup.widgetGroup?.direction === 'desc') {
        freeAppointmentGroup.appointments = freeAppointmentGroup.appointments.reverse();
      }
    }

    freeAppointmentGroup.loadMore = false;
    freeAppointmentGroup.selectedSlots = 0;
    freeAppointmentGroup.widgetGroup && (this.hasGroups = true);
  }

  ngAfterViewInit(): void {
    const tabInkBar = document.querySelector('mat-ink-bar');
    if (tabInkBar) {
      this._renderer.addClass(tabInkBar, 'csw-slot-selected-tab');
      this.updateTabsCounter();
    }
  }

  updateCart(event: any, appointment: any): void {
    if (!this.cartSupported) {
      this.cart = [];
    }
    this.updateCartEvent.emit({ event, appointment });
  }

  updateCartManually(id: string): void {
    document.getElementById(id).click();
  }

  isTerminInCart(cartItemId: string): boolean {
    if (this.cart != null) {
      for (let i = this.cart.length - 1; i >= 0; i--) {
        if (this.cart[i].cartItemId === cartItemId) {
          return true;
        }
      }
    }
    return false;
  }

  terminConflict(appointment: AppointmentsSlotsDao): boolean {
    for (const cartItem in this.cart) {
      if (
        appointment.start > this.cart[cartItem]['startTime'] &&
        appointment.start < this.cart[cartItem]['endTime'] ||
        appointment.start < this.cart[cartItem]['startTime'] &&
        appointment.end > this.cart[cartItem]['startTime']
      ) {
        if (appointment.worker && appointment.worker.id === this.cart[cartItem].workerId) {
          return true;
        }
      }
    }

    return false;
  }

  createCartItemId(workerId: number, selectedDay: Date, shortStart: string): string {
    return this.appointmentService.createCartItemId(workerId, selectedDay, Number(shortStart));
  }

  updateTabsCounter(): void {
    this.freeAppointments.forEach(freeAppointmentGroup => {
      const selectedAppointments = freeAppointmentGroup.appointments.filter(appointment => {
        return this.isTerminInCart(this.createCartItemId(appointment?.worker?.id, this.appointmentState.date, appointment.short_start));
      });
      freeAppointmentGroup.selectedSlots = (selectedAppointments?.length ? selectedAppointments.length : 0);
    });
  }

  loadMore(loadMoreIndex: number): void {
    this.freeAppointments.forEach((freeAppointmentGroup, index) => {
      if (loadMoreIndex === index) {
        freeAppointmentGroup.loadMore = true;
        return;
      }
    });
  }

  gotoNextPage(): void {
    this.gotoNextPageEvent.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.widgetTemplates?.currentValue) {
      this.widgetTemplates?.length && this.setupTemplates();
    }

    if (changes?.cart?.currentValue) {
      this.updateTabsCounter();
    }
  }

  setupTemplates(): void {
    this.widgetMorningLabelTemplate = this.widgetTemplates.find(template =>
      template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_MORNING_LABEL
    );
    this.widgetMorningLabelTemplate && (this.widgetMorningLabelTemplate.is_multi_language = 1);

    this.widgetForenoonLabelTemplate = this.widgetTemplates.find(template =>
      template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_FORENOON_LABEL
    );
    this.widgetForenoonLabelTemplate && (this.widgetForenoonLabelTemplate.is_multi_language = 1);

    this.widgetNoonLabelTemplate = this.widgetTemplates.find(template =>
      template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NOON_LABEL
    );
    this.widgetNoonLabelTemplate && (this.widgetNoonLabelTemplate.is_multi_language = 1);

    this.widgetAfternoonLabelTemplate = this.widgetTemplates.find(template =>
      template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_AFTERNOON_LABEL
    );
    this.widgetAfternoonLabelTemplate && (this.widgetAfternoonLabelTemplate.is_multi_language = 1);

    this.widgetEveningLabelTemplate = this.widgetTemplates.find(template =>
      template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_EVENING_LABEL
    );
    this.widgetEveningLabelTemplate && (this.widgetEveningLabelTemplate.is_multi_language = 1);

    this.widgetNightLabelTemplate = this.widgetTemplates.find(template =>
      template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NIGHT_LABEL
    );
    this.widgetNightLabelTemplate && (this.widgetNightLabelTemplate.is_multi_language = 1);

    this.noFreeAppointments = this.widgetTemplates.find(template =>
      template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NO_POSSIBLE_APPOINTMENTS_HINT
    );
    this.noFreeAppointments && (this.noFreeAppointments.is_multi_language = 1);
  }
}
