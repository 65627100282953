import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-cw-radio-tick-button',
  templateUrl: './cw-radio-tick-button.component.html',
  styleUrls: ['./cw-radio-tick-button.component.scss'],
  standalone: true,
  imports: [NgStyle, FontAwesomeModule],
})
export class CwRadioTickButtonComponent {

  @Input() checked = false;
  @Input() color: string;
}
