export const EVENT_CONSTANT = {
  EVENT_LOADER: 'EVENT_LOADER',
  EVENTS: 'events',
  DATE: 'date',
  SUMMARY: 'summary',
  EVENT_STATE_LS: 'eventStateLs-',
  EVENT_CART_LS: 'eventCartLs-',
  EVENT_GLOBAL_USER_LS: 'globalUserLs',
  FIRST_FREE_DATE: 'firstFreeDate',
  EVENT_CUSTOM_FIELDS_LS: 'eventCustomFieldsLs-',
  EVENT_BOOKING_ITEMS_LS: 'eventBookeditemsLs-',
};
