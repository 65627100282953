@if (calendarLoaded === false) {
<div class="loader-box">
  @if (!noFreeDates) {
  <app-loader [loaderType]="appointmentConstant.WORKER_LOADER">
  </app-loader>
  }
</div>
}

<div class="calendar-wrapper" [ngClass]="{ 'd-none': !calendarLoaded === true }">
  @if (excludeWeekDays) {
  <div>
    <div class="cal-header-block cbcw-cal-header-block btn-group" role="group">
      <div class="cal-month-view-block cbcw-cal-month-view-block">
        <span class="cal-month-name cbcw-cal-month-name">{{ viewDate | date:'MMMM' | translate }}</span>
        <span class="cal-year-name cbcw-cal-year-name">{{ viewDate | date:'y' }}</span>
      </div>
      <div class="cal-action-buttons-block cbcw-cal-action-buttons-block">
        <button mwlCalendarPreviousView class="btn btn-link cal-previous-block cbcw-cal-previous-block" view="month"
          [(viewDate)]="viewDate" (viewDateChange)="viewDateChanged(viewDate, false)" [disabled]="disablePrev">
          <fa-icon class="icon cbcw-prev-arrow-button" icon="chevron-left"></fa-icon>
        </button>
        <button mwlCalendarNextView class="btn btn-link cal-next-block cbcw-cal-next-block" view="month"
          [(viewDate)]="viewDate" (viewDateChange)="viewDateChanged(viewDate, true)" [disabled]="disableNext">
          <fa-icon class="icon cbcw-next-arrow-button" icon="chevron-right"></fa-icon>
        </button>
      </div>
    </div>
    <mwl-calendar-month-view [weekStartsOn]="weekStartsOn" [viewDate]="viewDate" [activeDayIsOpen]="false"
      [weekendDays]="excludeWeekDays" (dayClicked)="onDateSelect($event)"
      (beforeViewRender)="loadCalendarWithAvailabilities($event);" [headerTemplate]="shortDays"
      [cellTemplate]="customCellTemplate"></mwl-calendar-month-view>
  </div>
  } @else {
  @if (!noFreeDates) {
  <app-loader [loaderType]="appointmentConstant.WORKER_LOADER">
  </app-loader>
  }
  }
</div>

<!-- Calendar Loader template-->

<!-- Calendar subheader week days in shot form (Mo, Tu) template -->
<ng-template #shortDays>
  <div class="cal-cell-row cal-header">
    @for (day of days | slice:0:7; track day) {
    <div class="cal-cell" [class.cal-past]="day.isPast" [class.cal-today]="day.isToday"
      [class.cal-future]="day.isFuture" [class.cal-weekend]="day.isWeekend" [ngClass]="day.cssClass">
      {{ 'day' + day.date.getDay() | translate }}
    </div>
    }
  </div>
</ng-template>

<!-- Calendar cell template -->
<ng-template #customCellTemplate let-day="day" let-locale="lang">
  <div class="cal-cell-top">
    <div class="cal-day-number" [ngStyle]="getColorOfDay(day) | async">
      <div [ngClass]="{ 'calendar-total-badge' : day.badgeTotal > 0 }">
        <span>{{ day.date | calendarDate:'monthViewDayNumber' : locale }}</span>
        @if (day.badgeTotal > 0 && day.cssClass === 'cal-selected') {
        <span class="badge badge-primary custom-slots-badge" [ngClass]="{
              'single-digit-badge-adjustment': (day.date | calendarDate : 'monthViewDayNumber' : locale) < 10,
              'double-digit-badge-adjustment': (day.date | calendarDate : 'monthViewDayNumber' : locale) >= 10
            }">
          {{ day.badgeTotal }}
        </span>
        }
      </div>
    </div>
  </div>
</ng-template>
