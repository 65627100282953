<div class="card card-{{ type }} card-box bg-white rounded {{ customClass }}">
  <div class="card-body">
    <div class="d-flex justify-content-space-around align-items-center">
      <div class="text-center d-flex ps-1 pe-1">
        <div class="notification-icon">
          <img src="{{ environment.deployUrl }}/assets/images/notifications/{{type}}_ico.svg" alt="Notification" />
        </div>
      </div>
      <div class="content-block d-flex justify-content-center flex-column ps-3 pe-3">
        <div class="notification-title text-{{type}}">
          {{ title }}
        </div>
        <div class="notification-description mt-1">
          {{subTitle}}
        </div>
      </div>
    </div>
  </div>
</div>
