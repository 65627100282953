@if (showHeader) {
  <div class="cw-card-header">
    <div class="d-flex flex-column">
      <div class="cbw-card-title">
        {{ title }}
      </div>
      <div class="cbw-card-subtitle">{{ subtitle }}</div>
    </div>
  </div>
  <hr class="cw-card-divider">
}
