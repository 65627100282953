<!-- Reset Button -->
<ng-template #resetBtn>
  @if (!noWorkers) {
    <app-reset-button [type]="appointmentConstant.APPOINTMENT" (onResetFormEvent)="resetForm()" />
  }
</ng-template>

@if (
  partner.is_widget_banner_hint_enabled &&
  partner.widget_banner_hint_text !== null &&
  subscriptionTypeId >= widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id
) {
  <div
    [class]="'mt-3 cbw-widget-hint alert alert-' + partner.widget_banner_hint_color"
    [innerHTML]="partner.widget_banner_hint_text | trustHtml"
  ></div>
}

@if (!hideStoreSelection) {
  <div class="form-group mt-4">
    @if ((
      (partner?.enable_store_postcode_feature === 1 && widgetConf?.show_stores_as_dropdown === 1) ||
      partner?.automatic_store_zip_search === 1
    ) && (
      (globals?.isInternal && partner?.internal_widget_store_search_list === 0) ||
      (!globals?.isInternal)
    )) {
      <div class="row mt-3">
        <div class="col-lg-12">
          <app-cw-title class="cbw-select-store-title" title="{{ storeLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}" />
        </div>
        <div class="col-lg-12 mt-2">
          <ng-select
            #postCodeSelect
            [loading]="isStoreLoading"
            [required]="true"
            [(ngModel)]="appointmentState.store"
            #selectStore="ngModel"
            bindLabel="name"
            bindValue="id"
            [items]="stores"
            [typeahead]="searchStores"
            [searchable]="true"
            typeToSearchText="{{ 'appointments.step1.search_store_postcode_helper_text' | translate }}"
            (change)="onStoreSelected($event)"
            (keydown)="allowOnlyNumbers($event)"
            (search)="zipCode($event.term)"
            notFoundText="{{ 'store_not_found' | translate }}"
            attr.aria-labelledby="search_store_by_postcode_placeholder"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="store-name">
                @if (partner?.include_city_name_in_postcode_results === 1) {
                  {{ item?.postcode_details?.postcode }} {{ item.city }}
                  @if (item?.postcode_details?.city) {
                    ({{ item.postcode_details.city }})
                  }
                }
                @if (!partner?.include_city_name_in_postcode_results) {
                  {{ item | translation: 'name': lang }}
                }
                @if (item.distance && partner.show_distance_in_stores === 1) {
                  <span>
                    ({{ 'appointments.step1.distance_from_store' | translate: { distance: (item.distance | number:'1.0-0') } }})
                  </span>
                }
              </div>
            </ng-template>

            <ng-template ng-label-tmp let-item="item">
              <div class="store-name">
                @if (partner?.include_city_name_in_postcode_results === 1) {
                  {{ item?.postcode_details?.postcode }} {{ item.city }}
                  @if (item?.postcode_details?.city) {
                    ({{ item.postcode_details.city }})
                  }
                }
                @if (!partner?.include_city_name_in_postcode_results) {
                  {{ item | translation: 'name': lang }}
                  @if (item.distance && partner.show_distance_in_stores === 1) {
                    <span>
                      ({{ 'appointments.step1.distance_from_store' | translate: { distance: (item.distance | number:'1.0-0') } }})
                    </span>
                  }
                }
              </div>
            </ng-template>

            <ng-template ng-loadingspinner-tmp>
              <div role="status" attr.aria-live="polite" attr.aria-label="{{ 'common.loading' | translate }}" class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </ng-template>
          </ng-select>

          <div id="search_store_by_postcode_placeholder">
            <small class="calio-text-muted">
              {{ 'appointments.step1.search_store_by_postcode_placeholder' | translate }}
            </small>
          </div>
        </div>
      </div>
    } @else if (stores.length > 1 && partner.enable_store_postcode_feature !== 1 && widgetConf.show_stores_as_dropdown !== 1) {
      <div class="store-container row">
        <div class="col-lg-12">
          <app-cw-title class="cbw-select-store-title" title="{{ storeLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}" />
        </div>

        @for (store of stores; track store.id; let odd = $odd) {
          <app-cw-card class="col-lg-6 col-md-6 col-sm-6 mt-3"
            [ngClass]="{ 'odd-store': odd }"
            [cardClass]="'cbw-store-select-card'"
          >
            <div body class="d-flex align-items-center justify-content-between store-body" (click)="onStoreSelected(store)">
              <div>
                <div class="store-name">{{ store | translation: 'name': lang }}</div>
                @if (store.is_online === 0) {
                  <div class="store-address text-muted">
                    @if (store.street) {
                      <span>{{ store.street }}</span>
                    }
                    @if (store?.zip) {
                      <span>{{ ', ' + store?.zip }}</span>
                    }
                    @if (store.city) {
                      <span>{{ ' ' + store.city }}</span>
                    }
                    @if (store?.country?.name) {
                      <span>{{ ', ' + store?.country?.name }}</span>
                    }
                  </div>
                }
              </div>

              <div>
                <app-cw-radio-tick-button
                  [color]="store.id === appointmentState.store ? widgetColorConf.widget_header_active_color : ''"
                  [checked]="store.id === appointmentState.store"
                />
              </div>
            </div>
          </app-cw-card>
        }
      </div>
    } @else if ((
      stores?.length > 1 &&
      (globals?.isInternal && partner?.internal_widget_store_search_list === 1)
    ) || (
      stores?.length > 1 &&
      !globals?.isInternal &&
      partner?.enable_store_postcode_feature !== 1 &&
      partner?.automatic_store_zip_search !== 1 &&
      widgetConf?.show_stores_as_dropdown === 1
    )) {
      <div class="row mt-3">
        <div class="col-lg-12">
          <app-cw-title class="cbw-select-store-title" title="{{ storeLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}" />
        </div>
        <div class="col-lg-12 mt-2">
          <ng-select
            [required]="true"
            [(ngModel)]="appointmentState.store"
            #selectStore="ngModel"
            bindLabel="name"
            bindValue="id"
            [items]="stores"
            [notFoundText]="'store_not_found' | translate"
            (change)="onStoreSelected($event)"
          >
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <div class="">
                @if (item?.is_multi_language === 1) {
                  <div class="store-name">
                    {{ item | translation: 'name': lang }}
                  </div>
                } @else {
                  <div class="store-name">
                    {{ item.name }}
                  </div>
                }
              </div>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="">
                @if (item?.is_multi_language === 1) {
                  <div class="store-name">
                    {{ item | translation: 'name': lang }}
                  </div>
                } @else {
                  <div class="store-name">
                    {{ item.name }}
                  </div>
                }
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    }
  </div>
}

@if ((partner.enable_store_postcode_feature === 1 && appointmentState.store) || partner.enable_store_postcode_feature !== 1) {
  @if (appoinrmentServicesByCat?.length > 0) {
    <app-appointment-services
      [widgetTemplates]="widgetTemplates"
      [appoinrmentServicesByCat]="appoinrmentServicesByCat"
      [stores]="stores"
      [lang]="lang"
      [(appointmentState)]="appointmentState"
      [partner]="partner"
      [conferenceState]="conferenceState"
      (serviceChangeEvent)="serviceChangeEvent.emit($event)"
      (disabledServiceTabEvent)="disabledServiceTabEvent.emit($event)"
      (shortcutNavigateToEvent)="shortcutNavigateTo()"
      (navigateToEvent)="navigateTo($event.page, $event.isFirstTime)"
    />
  }
}
@if (conferenceState?.uuid && !appoinrmentServicesByCat) {
  <app-new-alert subTitle="{{ 'common.conference_invalid_configurations' | translate }}" type="danger" customClass="mx-3" />
}

<div class="wizard-footer clearfix">
  <div class="row margin-x-0">
    <div class="col-4 d-flex align-items-center justify-content-start">
      @if (subscriptionTypeId < widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id) {
        <div class="d-md-none">
          <img
            alt="Calenso Logo"
            src="{{ environment.deployUrl }}/assets/images/{{ environment.footerLogoUrl }}"
            width="100"
          >
        </div>
      }
    </div>
    <div class="col-4 d-flex align-items-center justify-content-center">
      @if (subscriptionTypeId < widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id) {
        <div class="d-none d-md-block">
          <img
            alt="Calenso Logo"
            src="{{ environment.deployUrl }}/assets/images/{{ environment.footerLogoUrl }}"
            width="100"
          >
        </div>
      }
    </div>
    <div class="col-4 d-flex align-items-center justify-content-end next-btn-custom-right-padding">
      @if (partner?.is_customer_profiles_feature_enabled === 1 && partner?.customer_profiles?.length > 1) {
        <calenso-button
          id="cbw-prev-button"
          class="me-2"
          text="{{ previousButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}"
          placement="left"
          [widgetColorConf]="widgetColorConf"
          (clickEvent)="navigateTo(appointmentConstant.CUSTOMER_TYPE)"
        />
      }
      <calenso-button
        id="cbw-next-button"
        text="{{ nextButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}"
        icon="chevron-right"
        placement="right"
        [disabled]="appointmentState.services.length < 1"
        [widgetColorConf]="widgetColorConf"
        (clickEvent)="navigateTo(appointmentConstant.WORKER, false)"
      />
    </div>
  </div>
</div>
