import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cw-radio-button',
  templateUrl: './cw-radio-button.component.html',
  styleUrls: ['./cw-radio-button.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: true,
  imports: [FormsModule, NgClass, TranslateModule]
})
export class CwRadioButtonComponent {

  @Input() id: any;
  @Input() name?: string;
  @Input() value?: any;
  @Input() checked?: any;
  @Input() isDisabled = false;
  @Input() fieldNgModel: any;
  @Input() isRequired = false;
  @Input() customLabelClass = 'ps-2';
  @Input() last = false;
  @Input() widgetType: string;

  @Output() selectionChange  = new EventEmitter<{ event: any, id: any }>();

  onChange(event: any, id: any): void {
    this.selectionChange.emit({event: event, id: id});
  }
}
