@if (!isCustomFieldsLoaded) {
  <div class="personal-information-ghost-wrapper cbcw-personal-information-ghost-wrapper">
    @for (item of [0, 1, 2, 3, 4, 5]; track item) {
      <div class="personal-information-ghost skeleton-animation cbcw-personal-information-ghost">
        <div class="bar bar-{{ item }}"></div>
      </div>
    }
  </div>
}

@if (isCustomFieldsLoaded) {
  <div class="personal-information-wrapper data-container cbcw-personal-information-wrapper">
    <app-customer-form
      [index]="1"
      [customerRequiredFields]="defaultRequiredCustomerFields"
      [customerFormFields]="defaultCustomerFields"
      [userState]="userState"
      [globals]="globals"
      [partner]="partner"
      [userDataLSNameKey]="userDataLSNameKey"
      [widgetTemplates]="widgetTemplates"
      [type]="appointmentConstant.APPOINTMENT"
      [lang]="lang"
      [meetingTypes]="meetingTypes"
      [appointmentCart]="appointmentCart"
      [notificationTypes]="NOTIFICATION_TYPES"
      [showBeforeCustomField]="partnerService?.customFieldsBefore?.length > 0"
      [showAfterCustomField]="partnerService?.customFieldsAfter?.length > 0"
      [appointmentState]="appointmentState"
      [stores]="store"
    >
      <app-customfields
        beforeCustomField
        [widgetType]="widgetType"
        [(userState)]="userState"
        [globals]="globals"
        [customFields]="partnerService.customFieldsBefore"
        [partner]="partner"
        [(customFieldValues)]="customFieldValues"
        [customFieldValuesLSNameKey]="customFieldValuesLSNameKey"
        [selectedJsonCustomFields]="selectedJsonCustomFields"
        [type]="appointmentConstant.APPOINTMENT"
        [renderBefore]="true"
        [lang]="lang"
      />
      <app-customfields
        afterCustomField
        [widgetType]="widgetType"
        [(userState)]="userState"
        [globals]="globals"
        [customFields]="partnerService.customFieldsAfter"
        [partner]="partner"
        [(customFieldValues)]="customFieldValues"
        [type]="appointmentConstant.APPOINTMENT"
        [customFieldValuesLSNameKey]="customFieldValuesLSNameKey"
        [selectedJsonCustomFields]="selectedJsonCustomFields"
        [renderBefore]="false"
        [lang]="lang"
      />
    </app-customer-form>
  </div>
}
