import {Injectable} from '@angular/core';
import {LocalStorageService} from './local-storage.service';
import {CustomBookingMessageTemplate} from '../db-models/widget-conf-dao';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private localStorageService: LocalStorageService,
  ) {

  }

  // Apply current LS holder object to local storage
  updateLocalStorage(key: string, value: any): void {
    this.localStorageService.removeItem(key, () => {
      this.localStorageService.setItem(key, value, () => {
      });
    });
  }

  // Helper to turn date object to formated string
  dateToStr(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
  }

  /**
   * Function to sort multidimensional array
   *
   * <a href="/param">@param</a> {array} [arr] Source array
   * <a href="/param">@param</a> {array} [columns] List of columns to sort
   * <a href="/param">@param</a> {array} [order_by] List of directions (ASC, DESC)
   * @returns {array}
   */
  multiSort(arr: any, columns: any, order_by: any) {

    if (typeof columns === 'undefined') {
      columns = [];
      for (let x = 0; x < arr[0].length; x++) {
        columns.push(x);
      }
    }

    if (typeof order_by === 'undefined') {
      order_by = [];
      for (let x = 0; x < arr[0].length; x++) {
        order_by.push('ASC');
      }
    }

    function multisort_recursive(a: any, b: any, columns: any, order_by: any, index: any) {
      const direction = order_by[index] === 'DESC' ? 1 : 0;

      const is_numeric = !isNaN(+a[columns[index]] - +b[columns[index]]);


      const x = is_numeric ? +a[columns[index]] : a[columns[index]].toLowerCase();
      const y = is_numeric ? +b[columns[index]] : b[columns[index]].toLowerCase();


      if (x < y) {
        return direction === 0 ? -1 : 1;
      }

      if (x === y) {
        return columns.length - 1 > index ? multisort_recursive(a, b, columns, order_by, index + 1) : 0;
      }

      return direction === 0 ? 1 : -1;
    }

    return arr.sort(function (a, b) {
      return multisort_recursive(a, b, columns, order_by, 0);
    });
  }

  getBookingInfoMessage(bookingInfoMessage: CustomBookingMessageTemplate, lang: string): string {
    if (bookingInfoMessage?._translations?.[lang]) {
      return bookingInfoMessage._translations[lang]['template_content'];
    } else if (bookingInfoMessage?.template_content) {
      return bookingInfoMessage.template_content;
    } else {
      return '';
    }
  }
}
