import { NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { APPOINTMENT_CONSTANT } from '../../../../constants/appointment-constants';
import { TRANSLATION_TEMPLATES } from '../../../../constants/translation-templates-constants';
import { WIDGET_CONSTANTS } from '../../../../constants/widget-constants';
import { AppointmentServiceCategoryDao, AppointmentServiceDao } from '../../../../db-models/appointment-service-dao';
import { StoresDao } from '../../../../db-models/stores-dao';
import { CustomBookingMessageTemplate } from '../../../../db-models/widget-conf-dao';
import { Partner } from '../../../../models/global';
import { AppointmentState, ConferenceState } from '../../../../models/state.model';
import { AppointmentService } from '../../../../services/appointment.service';
import { HelperService } from '../../../../services/helper.service';
import { WidgetUtilService } from '../../../../services/widget-util.service';
import { LoaderComponent } from '../../../../shared/components/loader/loader.component';
import { CalioCurrencyPipe } from '../../../../shared/pipes/calio-currency.pipe';
import { CategoryFilterPipe } from '../../../../shared/pipes/category-filter.pipe';
import { HourReplacePipe } from '../../../../shared/pipes/hour-replace.pipe';
import { MinuteReplacePipe } from '../../../../shared/pipes/minute-replace.pipe';
import { ReplaceCommaPipe } from '../../../../shared/pipes/replace-comma.pipe';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';
import { CwCheckboxRadioButtonComponent } from '../../../common/theme/cw-checkbox-radio-button/cw-checkbox-radio-button.component';
import { CwMultiLangTitleComponent } from '../../../common/theme/cw-multi-lang-title/cw-multi-lang-title.component';
import { CwQuestionMarkComponent } from '../../../common/theme/cw-question-mark/cw-question-mark.component';
import { CwTitleComponent } from '../../../common/theme/cw-title/cw-title.component';

@Component({
  selector: 'app-appointment-services',
  templateUrl: './appointment-services.component.html',
  styleUrls: ['./appointment-services.component.scss'],
  standalone: true,
  imports: [LoaderComponent, CwTitleComponent, FormsModule, NgStyle, CwMultiLangTitleComponent, FontAwesomeModule, CwCheckboxRadioButtonComponent, CwQuestionMarkComponent, HourReplacePipe, MinuteReplacePipe, ReplaceCommaPipe, TranslateModule, TranslationPipe, CalioCurrencyPipe, CategoryFilterPipe, NgbAccordionModule],
})
export class AppointmentServicesComponent implements OnInit, OnDestroy {

  @Input() widgetTemplates: CustomBookingMessageTemplate[] = [];
  @Input() appoinrmentServicesByCat: {
    category: string,
    _translations: any,
    is_multi_language: number,
    appointmentServiceCategory: AppointmentServiceCategoryDao,
    aServicesOfCat: AppointmentServiceDao[],
    position: number
  }[];
  @Input() stores: StoresDao[] = [];
  @Input() lang: string;
  @Input() appointmentState: AppointmentState;
  @Input() partner: Partner;
  @Input() conferenceState: ConferenceState;
  @Input() noAppointments: boolean;

  @Output() serviceChangeEvent = new EventEmitter<any>();
  @Output() disabledServiceTabEvent = new EventEmitter<any>();
  @Output() navigateToEvent = new EventEmitter<any>();
  @Output() shortcutNavigateToEvent = new EventEmitter<any>();

  readonly templateContent = TRANSLATION_TEMPLATES;

  cartSupported: boolean;
  isMobile: boolean;
  alive: boolean;
  activePanels = ['static-0'];
  finalRawServices: AppointmentServiceDao[] = [];
  appointmentConstant = APPOINTMENT_CONSTANT;
  searchServiceQuery: string;
  serviceLabelTemplate: CustomBookingMessageTemplate;
  showShortcutButton = false;

  constructor(
    private translate: TranslateService,
    private widgetUtilService: WidgetUtilService,
    private deviceDetectorService: DeviceDetectorService,
    private appointmentService: AppointmentService,
    private helperService: HelperService
  ) {
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
  }

  ngOnInit(): void {
    this.appointmentService.resetAppointmentCategoryWiseListEvent.subscribe(() => {
      this.setRawServices();
      this.resetAppointmentCategoryWiseListEvent();
    });

    if (this.appointmentState?.categories?.length) {
      this.appoinrmentServicesByCat = this.appoinrmentServicesByCat.filter(category =>
        this.appointmentState.categories.includes(category.appointmentServiceCategory.id));
    }

    this.cartSupported = this.widgetUtilService.getWidgetConf().cartSupported;
    this.isMobile = this.deviceDetectorService.isMobile();

    this.checkIfOnlyOneAppoinmentServiceIsAvailable();

    if (this.appointmentState.services && this.appointmentState.services.length > 0) {
      let index = 0;
      const activeIds: string[] = [];
      for (const category of this.appoinrmentServicesByCat) {
        const foundedService = category.aServicesOfCat.find(item => this.appointmentState.services.indexOf(item.id) > -1);
        if (foundedService) {
          activeIds.push(`static-${index}`);
        }
        index++;
      }
      this.activePanels = activeIds;
    }

    // If setting is enabled and service is selected than show shortcut button
    this.partner.is_widget_service_shortcut_button_enabled === 1 && (this.showShortcutButton = true);

    this.expandCollapseCategoriesBasedOnSetting();
  }

  resetAppointmentCategoryWiseListEvent(): void {
    if (this.appointmentService.mappedServiceIds?.length) {
      let fianlServicesCategoryWiseList = [];
      this.appointmentService.mappedServiceIds.forEach(service => {
        const foundService = this.finalRawServices.find(finalRawService => finalRawService.id === service);
        if (foundService) {
          if (fianlServicesCategoryWiseList[foundService.appointment_service_category.id]) {
            fianlServicesCategoryWiseList[foundService.appointment_service_category.id].aServicesOfCat.push(foundService)
          } else {
            fianlServicesCategoryWiseList[foundService.appointment_service_category.id] = {
              category: foundService.appointment_service_category.name,
              _translations: foundService.appointment_service_category._translations,
              is_multi_language: foundService.appointment_service_category.is_multi_language,
              appointmentServiceCategory: foundService.appointment_service_category,
              aServicesOfCat: [foundService],
              position: foundService.appointment_service_category.position
            };
          }
        }
      });

      // resetting an index of fianlServicesCategoryWiseList
      fianlServicesCategoryWiseList = fianlServicesCategoryWiseList.filter(Boolean);

      // Sort categories
      fianlServicesCategoryWiseList = this.helperService.sortByNumber(
        fianlServicesCategoryWiseList,
        'position'
      );

      // replacing actual list of array with answered qualification bases intersected services
      this.appoinrmentServicesByCat = fianlServicesCategoryWiseList;
      this.expandCollapseCategoriesBasedOnSetting();
      this.appointmentState.categories = [];
    }
  }

  expandCollapseCategoriesBasedOnSetting(): void {
    if (Number(this.partner.widget_appointment_service_category_status) === 2) {
      const activeIds: string[] = [];
      for (let i = 0; i < this.appoinrmentServicesByCat.length; i++) {
        activeIds.push(`static-${i}`);
      }
      this.activePanels = activeIds;
    } else if (Number(this.partner.widget_appointment_service_category_status) === 1) {
      const activeIds: string[] = [];
      activeIds.push(`static-0`);
      this.activePanels = activeIds;
    } else if (Number(this.partner.widget_appointment_service_category_status) === 0) {
      this.activePanels = [];
    }
  }

  checkIfOnlyOneAppoinmentServiceIsAvailable(): void {
    if (
      this.stores?.length === 1 ||
      this.conferenceState?.uuid ||
      (Number(this.partner.widget_jumps_to_resources_step_when_single_service) === 1)
    ) {
      if (this.appoinrmentServicesByCat?.length === 1) {
        if (this.appoinrmentServicesByCat[0].aServicesOfCat && this.appoinrmentServicesByCat[0].aServicesOfCat.length === 1) {
          if (this.appointmentState.services
            && this.appointmentState.services.indexOf(this.appoinrmentServicesByCat[0].aServicesOfCat[0].id) === -1) {

            const event: any = {
              target: {
                checked: true
              }
            };
            this.onServiceChange(event, this.appoinrmentServicesByCat[0].aServicesOfCat[0].id, this.appoinrmentServicesByCat[0].aServicesOfCat[0]);
          }

          of(1).pipe(
            delay(500)
          ).subscribe(() => {
            this.disabledServiceTabEvent.emit(true);
            this.navigateTo(this.appointmentConstant.WORKER, true);
          });
        }
      } else {
        this.disabledServiceTabEvent.emit(false);
      }
    } else {
      this.disabledServiceTabEvent.emit(false);
    }
  }

  onServiceChange(event: any, serviceId: number, aService: AppointmentServiceDao): void {
    this.serviceChangeEvent.emit({ event, serviceId, relatedServiceId: aService.related_appointment_service_id });
  }

  setRawServices(): void {
    const servicesList = this.appoinrmentServicesByCat.map(ac => ac.aServicesOfCat);
    let finalRawServices: AppointmentServiceDao[] = [];
    for (const services of servicesList) {
      finalRawServices = finalRawServices.concat(services);
    }
    this.finalRawServices = finalRawServices;
  }

  navigateTo(page: string, isFirstTime?: boolean): void {
    this.navigateToEvent.next({ page: page, isFirstTime: isFirstTime });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['widgetTemplates'] && changes['widgetTemplates']['currentValue']) {
      if (this.widgetTemplates && this.widgetTemplates.length > 0) {
        this.setupTemplates();
      }
    }
  }

  setupTemplates(): void {
    this.serviceLabelTemplate = this.widgetTemplates.find((template: CustomBookingMessageTemplate) => {
      return (template).identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_APPOINTMENT_SERVICE_LABEL;
    });
    if (this.serviceLabelTemplate) {
      this.serviceLabelTemplate.is_multi_language = 1;
    }
  }

  onSearchServiceQueryChanged(): void {
    let values = Object.assign([], this.appoinrmentServicesByCat);
    if (this.searchServiceQuery && values?.length) {
      values = values.filter(value => {
        const services = value.aServicesOfCat.filter((appointment: AppointmentServiceDao) => {
          const name = appointment.name ? appointment.name.toLowerCase() : undefined;
          const description = appointment.description ? appointment.description.toLowerCase() : undefined;

          if (name && description) {
            return (name.indexOf(this.searchServiceQuery.toLowerCase()) > -1 || description.indexOf(this.searchServiceQuery.toLowerCase()) > -1);
          } else if (name || description) {
            if (name) {
              return name.indexOf(this.searchServiceQuery.toLowerCase()) > -1;
            }

            if (description) {
              return description.indexOf(this.searchServiceQuery.toLowerCase()) > -1;
            }
          } else {
            return false;
          }
        });
        if (services?.length) {
          value.aServicesOfCat = services;
          return true;
        } else {
          return false;
        }
      });
    }

    let activeIds: string[] = [];
    values.forEach((_value, index) => {
      activeIds.push(`static-${index}`);
    });

    activeIds = [...new Set(activeIds)];
    this.activePanels = activeIds;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
