import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EVENT_CONSTANT} from '../../../constants/event-constants';
import {APPOINTMENT_CONSTANT} from '../../../constants/appointment-constants';
import { TranslateModule } from '@ngx-translate/core';
@Component({
    selector: 'app-reset-button',
    templateUrl: './reset-button.component.html',
    styleUrls: ['./reset-button.component.scss'],
    standalone: true,
    imports: [TranslateModule]
})
export class ResetButtonComponent {

  @Input() type: string;
  @Output('resetFormEvent') resetFormEvent: EventEmitter<any> = new EventEmitter();

  eventConstant = EVENT_CONSTANT;
  appointmentConstant = APPOINTMENT_CONSTANT;

  resetForm() {
    this.resetFormEvent.emit();
  }
}
