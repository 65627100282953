import {Injectable} from '@angular/core';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {LoggerService} from './logger.service';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  disableLocalStorage = false;

  constructor(
    protected storage: LocalStorage,
  ) {
    if (this.disableLocalStorage) {
      LoggerService.warn('LocalStorage disabled by configuration manually');
    }
  }

  setItemSynchronously(key: string, value: any) {
    if (this.disableLocalStorage) {
      return of(true);
    }
    return this.storage.setItem(key, value);
  }

  getItemSynchronously(key: string) {
    if (this.disableLocalStorage) {
      return of(null);
    }
    return this.storage.getItem(key);
  }

  getItem(key: string, callback: any): any {
    if (this.disableLocalStorage) {
      callback(null);
      return;
    }

    this.storage.getItem(key)
      .subscribe({
        next: (value: any) => {
          callback(value);
        },
        error: (error: Error) => {
          LoggerService.error(error);
        }
      });
  }

  removeItem(key: string, callback: any): void {
    this.storage.removeItem(key).subscribe({
      next: () => callback()
    });
  }

  setItem(key: string, value: any, callback: any): void {
    if (this.disableLocalStorage) {
      callback();
      return;
    }
    this.storage.setItem(key, value).subscribe({
      next: () => callback()
    });
  }

  clear(callback: any): void {
    this.storage.clear().subscribe({
      next: () => callback()
    });
  }

  setItemLocally(key: string, value: any): void {
    localStorage.setItem(key,value);
  }

  getItemLocally(key: string): any {
    return localStorage.getItem(key);
  }
}
