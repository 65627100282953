<div class="card {{ cardClass }}" (click)="onCardClick()">
  @if (showHeader) {
    <div class="card-header">
      <ng-content select="[header]"></ng-content>
    </div>
  }
  @if (showBody) {
    <div class="card-body {{ cardBodyClass }}">
      <ng-content select="[body]"></ng-content>
    </div>
  }
  @if (showFooter) {
    <div class="card-footer">
      <ng-content select="[footer]"></ng-content>
    </div>
  }
</div>
