import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Params } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalendarCommonModule, CalendarMonthModule, CalendarMonthViewDay } from 'angular-calendar';
import * as moment from 'moment';
import { Subject, of } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { APPOINTMENT_CONSTANT } from '../../../constants/appointment-constants';
import { UserDao } from '../../../db-models/user-data-dao';
import { Partner } from '../../../models/global';
import { ConferenceState } from '../../../models/state.model';
import { WidgetColorConf } from '../../../models/widget-color.model';
import { CustomEventService } from '../../../services/custom-event.service';
import { TrustHtmlPipe } from '../../../shared/pipes/trust-html.pipe';
import { TranslationPipe } from '../../../shared/pipes/translation.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { NgClass, NgStyle, AsyncPipe, SlicePipe, DatePipe } from '@angular/common';

@Component({
  selector: 'app-calendar-picker',
  templateUrl: './calendar-picker.component.html',
  styleUrls: ['./calendar-picker.component.scss'],
  standalone: true,
  imports: [LoaderComponent, NgClass, FontAwesomeModule, CalendarCommonModule, NgStyle, CalendarMonthModule, AsyncPipe, SlicePipe, DatePipe, TranslateModule, TranslationPipe, TrustHtmlPipe]
})
export class CalendarPickerComponent implements OnInit, AfterViewInit {

  @Input() noFreeDates: boolean;
  @Input() disablePrev: boolean;
  @Input() viewDate: Date;
  @Input() calendarLoaded = false;
  @Input() days: CalendarMonthViewDay[];
  @Input() widgetColorConf?: WidgetColorConf;
  @Input() worker: UserDao;
  @Input() futureBookingThreshold: number;
  @Input() lang: string;
  @Input() calendarSlotsCountList?: {
    date: Date,
    slotsCount: number
  }[] = [];
  @Input() partner: Partner;
  @Input() calendarPreselectedDate: string;
  @Input() conferenceState: ConferenceState;

  @Output('viewDateChangedEvent') viewDateChangedEvent = new EventEmitter<Params>();
  @Output('dayClickedEvent') dayClickedEvent = new EventEmitter<any>();
  @Output('markExceptionDaysEvent') markExceptionDaysEvent = new EventEmitter<any>();

  readonly appointmentConstant = APPOINTMENT_CONSTANT;

  workerAvatarUrl = environment.workerAvatarUrl;
  baseUrl = environment.baseUrl;
  disableNext = false;
  preselectedEventTriggered = false;
  refreshCalendar = new Subject<void>();

  constructor(
    private cdref: ChangeDetectorRef,
    private translate: TranslateService,
    private customEventService: CustomEventService,
  ) {
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
  }

  ngOnInit() {
    this.customEventService.disableCalendarNextButtonEvent.subscribe(disableNext => {
      setTimeout(() => this.disableNext = disableNext, 500);
    });

    this.customEventService.refreshCalendarEvent.subscribe(() => this.refreshCalendar.next());

    this.validateMaxDate(this.viewDate);
  }

  viewDateChanged(viewDate: Date, isNext: boolean): void {
    this.validateMaxDate(viewDate);
    this.viewDate = viewDate;
    this.viewDateChangedEvent.emit({ viewDate: viewDate, isNext: isNext });
  }

  validateMaxDate(viewDate: Date): void {
    if (this.futureBookingThreshold) {
      const maxDate = moment().add(this.futureBookingThreshold, 'days').toDate();
      this.disableNext = (viewDate.getMonth() == maxDate.getMonth() && viewDate.getFullYear() == maxDate.getFullYear())
    }
  }

  dayClicked(day: any): void {
    day?.day?.inMonth && (this.dayClickedEvent.emit(day));
  }

  markExceptionDays(body: any): void {
    this.markExceptionDaysEvent.emit(body);
  }

  getColorOfDay(day: any): any {
    let calendarPreselectedDate: Date;
    let hasAlreadySelected = false;

    if (this.calendarPreselectedDate) {
      calendarPreselectedDate = new Date(`${this.calendarPreselectedDate} 00:00:00`);
    }

    for (const calendarSlotsCount of this.calendarSlotsCountList) {
      if (day.date.toString() === calendarSlotsCount.date.toString()) {
        if (calendarSlotsCount.slotsCount && calendarSlotsCount.slotsCount > 0) {
          hasAlreadySelected = true;
          day.cssClass = 'cal-selected';
          day.badgeTotal = calendarSlotsCount.slotsCount;
        }
      }
    }

    if (this.calendarLoaded && hasAlreadySelected === false && calendarPreselectedDate) {
      if (day.date.getTime() === calendarPreselectedDate.getTime()) {
        if (
          day.isWeekend === false &&
          day.isPast === false &&
          !this.preselectedEventTriggered
        ) {
          this.preselectedEventTriggered = true;
          day.cssClass = 'cal-selected';
          this.dayClicked({ day });
        }
      }
    }

    if (day.cssClass === 'cal-selected') {
      return of({
        'color': 'white',
        'background-color': this.widgetColorConf.widget_header_active_color
      }).pipe(delay(500), switchMap((value: any) => of(value)));
    } else {
      if (day.isWeekend) {
        return of({ 'color': '' }).pipe(delay(500), switchMap((value: any) => of(value)));
      } else {
        return of({ 'color': this.widgetColorConf.widget_text_color }).pipe(delay(500), switchMap((value: any) => of(value)));
      }
    }
  }

  ngAfterViewInit(): void {
    this.cdref.detectChanges();
  }
}
