import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {CategoryDbModel} from '../db-models/categories-db.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    public http: HttpClient
  ) {
  }

  getMappedEventCategories(store_id: number[] = null): Observable<CategoryDbModel[]> {
    const body: {store_ids: number[]} = { store_ids: store_id,};
    return this.http.post<CategoryDbModel[]>(environment.apiUrl + 'categories/get_event_categories', body);
  }
}
