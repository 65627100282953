import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';


@Injectable({
  providedIn: 'root'
})
export class FileService {

  public saveFile(url: string, filename: string) {
    saveAs(url, filename);
  }

  public fetchFilenameFromPath(path: string): string {
    return path.split('/').pop();
  }
}
