import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {
  InitializeSaferpayPaymentRequest, InitializeSaferpayPaymentResponse,
  AssertPaymentPageSaferpayRequest, AssertPaymentPageSaferpayResponse
} from '../models/saferpay.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  public initiateSaferpaySubject = new Subject<boolean>();
  public handleSaferpayBackgroundTriggerManually = new Subject<boolean>();

  constructor(
    public http: HttpClient,
  ) { }

  public InitializeSaferpayPaymentRequest(
    payload: InitializeSaferpayPaymentRequest
  ): Observable<InitializeSaferpayPaymentResponse> {
    return this.http.post<InitializeSaferpayPaymentResponse>(
      environment.apiUrl + 'saferpay/initialize_payment',
      payload
    );
  }

  public AssertPaymentPageSaferpayRequest(
    payload: AssertPaymentPageSaferpayRequest
  ): Observable<AssertPaymentPageSaferpayResponse> {
    return this.http.post<AssertPaymentPageSaferpayResponse>(
      environment.apiUrl + 'saferpay/verify_transaction_status',
      payload
    );
  }
}
