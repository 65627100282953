import { Pipe, PipeTransform } from '@angular/core';
import { LoggerService } from '../../services/logger.service';
import { HelperService } from '../../services/helper.service';

@Pipe({
  name: 'translation',
  pure: false,
  standalone: true
})
export class TranslationPipe implements PipeTransform {

  constructor(
    private helperService: HelperService,
  ) { }

  transform(
    value: any,
    targetLabel: string,
    language: string,
    replacePlaceHolders?: {
      key: string,
      value: string,
    }[]
  ): any {
    try {

      if (value?.is_multi_language === 0) {
        if (value[targetLabel]) {
          const temp = value[targetLabel].trim();
          return this.replacePlaceHolders(temp, replacePlaceHolders);
        } else if (
          !value[targetLabel] &&
          value._translations &&
          value._translations[language] &&
          value._translations[language][targetLabel]
        ) {
          const temp = value._translations[language][targetLabel].trim();
          return this.replacePlaceHolders(temp, replacePlaceHolders);
        }
      } else if (
        value?.template &&
        value?.template !== null &&
        value?.is_multi_language === 1 &&
        value?.template.length !== 0
      ) {
        let temp: string;
        if (value.template._translations[language].template_content) {
          temp = value.template._translations[language].template_content.trim();
        } else {
          temp = value.template._translations[language].template_content;
        }

        return this.replacePlaceHolders(temp, replacePlaceHolders);
      } else if (
        value?._translations &&
        value?._translations !== 0 &&
        value?.is_multi_language === 1 &&
        value?._translations[language] &&
        value?._translations[language][targetLabel]
      ) {
        const temp = value._translations[language][targetLabel].trim();
        return this.replacePlaceHolders(temp, replacePlaceHolders);
      } else {
        let temp: string;
        if (value && value[targetLabel]) {
          temp = value[targetLabel].trim();
        } else {
          temp = value && value[targetLabel] ? value[targetLabel] : '';
        }
        return this.replacePlaceHolders(temp, replacePlaceHolders);
      }
    } catch (error) {
      LoggerService.error(error);
      return undefined;
    }
  }

  replacePlaceHolders(textLabel: string, replacePlaceHolders?: {
    key: string,
    value: string,
  }[]) {
    if (replacePlaceHolders?.length && textLabel) {
      replacePlaceHolders.forEach(replacePlaceholder => {
        textLabel = this.helperService.replaceAll(textLabel, replacePlaceholder.key, replacePlaceholder.value);
      });
    }

    return textLabel;
  }
}
