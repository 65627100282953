import { AppointmentServiceDao } from "./appointment-service-dao";
import { WorkerDao } from "./widget-conf-dao";

export class ConferenceDbModel {
  appointment_services: AppointmentServiceDao[];
  created: string;
  customer_id: number;
  deleted: string;
  description: string;
  expiry: string;
  id: number;
  is_expired: boolean;
  is_booked: boolean;
  is_permanent: boolean;
  partner_id: number;
  title: string;
  updated: string;
  uuid: string;
  worker_id: number;
  worker: WorkerDao;
  booking_links_workers: Attendees[] = [];
  is_multi_language: number;
  _translations: {
    [key: string]: any;
  };

  // client side properties
  errors?: any;
  appointment_service_ids?: number[];
}

export interface Attendees {
  is_optional: number;
  worker_id: number;
  worker: WorkerDao;
}

export const CONFERENCE_ERROR_STATE = {
  BOOKED: 'booked',
  NOT_BELONGING: 'not_belonging',
  INVALID: 'invalid',
  EXPIRED: 'expired',
}

export const CONFERENCE_ERROR_CODES = {
  BOOKED: 400,
  NOT_BELONGING: 401,
  INVALID: 404,
  EXPIRED: 410
};
