import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { TRANSLATION_TEMPLATES } from '../../../constants/translation-templates-constants';
import { CustomBookingMessageTemplate } from '../../../db-models/widget-conf-dao';
import { EventCartItem, GuestModel } from '../../../models/cart.model';
import { Partner } from '../../../models/global';
import { EventState } from '../../../models/state.model';
import { CustomEventService } from '../../../services/custom-event.service';
import { HelperService } from '../../../services/helper.service';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { MatIconComponent } from '../../../shared/components/mat-icon/mat-icon.component';
import { CalioAddRequiredPipe } from '../../../shared/pipes/calio-add-required.pipe';
import { CalioMeetingTemplatePipe } from '../../../shared/pipes/calio-meeting-template.pipe';
import { TranslationPipe } from '../../../shared/pipes/translation.pipe';
import { CwIntlTelInputComponent } from '../theme/cw-intl-tel-input/cw-intl-tel-input.component';

const Components = [MatIconComponent, ButtonComponent, CwIntlTelInputComponent]
const pipes = [CalioAddRequiredPipe, CalioMeetingTemplatePipe, TranslationPipe]

@Component({
  selector: 'app-additional-guest',
  templateUrl: './additional-guest.component.html',
  styleUrls: ['./additional-guest.component.scss'],
  standalone: true,
  imports: [FormsModule, FontAwesomeModule, TranslateModule, NgClass, NgTemplateOutlet, ...Components, ...pipes]
})
export class AdditionalGuestComponent {

  @Input() eventState: EventState;
  @Input() cart?: EventCartItem[] = [];
  @Input() additionalGuests: GuestModel[] = [];
  @Input() numberOfAllowedGuest = 0;
  @Input() partner: Partner;
  @Input() widgetTemplates: CustomBookingMessageTemplate[] = [];
  @Input() lang: string;

  @Output() updateAdditionalGuestsEvent = new EventEmitter<GuestModel[]>();

  private helperSevice = inject(HelperService);
  private customEventService = inject(CustomEventService);

  protected readonly svgIcons = [
    { name: 'close', path: 'assets/images/material/close.svg' },
    { name: 'edit', path: 'assets/images/material/edit.svg' },
    { name: 'person_add', path: 'assets/images/material/person_add.svg' }
  ]
  protected readonly templateContent = TRANSLATION_TEMPLATES;
  protected showDeleteButton = true;
  protected showAddAdditionalGuestButton = true;
  protected disableAdditionalParticipantsEditing = false;
  protected isGuestEditModeEnable = false;
  protected tempAdditionalGuests = new GuestModel();
  protected tempAdditionalGuestIndex: number;

  @ViewChild('additionalGuestForm') additionalGuestForm: NgForm;

  constructor() {
    this.customEventService.paypalOrderSuccessfullEvent.subscribe(() => {
      this.showAddAdditionalGuestButton = false;
      this.showDeleteButton = false;
      this.disableAdditionalParticipantsEditing = true;
    });
    this.helperSevice.registerSvgIconsList(this.svgIcons);
  }


  private addAdditionalGuest(): void {
    if (this.cart.length === 1) {
      if (
        this.additionalGuests.length < (this.cart[0].available_regular_capacity - 1) &&
        this.additionalGuests.length < this.eventState.number_allowed_additional_guests
      ) {
        this.additionalGuests.push(this.tempAdditionalGuests);
        this.showAddAdditionalGuestButton = !(this.additionalGuests.length === this.cart[0].available_regular_capacity - 1);

        if (this.additionalGuests.length === this.eventState.number_allowed_additional_guests) {
          this.showAddAdditionalGuestButton = false;
        }
      } else if (this.cart[0].is_waitinglist_enabled) {
        let waitingListCapcaity = 0;
        if (this.cart[0].regular_booking_count === this.cart[0].regular_capacity) {
          // case when regular booking is already full and user is trying to book all the waiting list slots
          waitingListCapcaity = (this.cart[0].available_waitinglist_capacity - this.additionalGuests?.length - 1);
        } else {
          // keep: Will be needed when we allows to book regular seats + waiting list a time

          // case when regular booking and waiting list both has capacity to fill so will count total available capacity and minus
          // added guest and also minus 1 for the perosn who is booking an appointment
          // waitingListCapcaity = ((this.cart[0].waitinglist_available_capacity + this.cart[0].available_regular_capacity) - this.additionalGuests?.length - 1);
        }

        if (waitingListCapcaity) {
          this.additionalGuests.push(this.tempAdditionalGuests);
        }
        this.showAddAdditionalGuestButton = waitingListCapcaity > 1;
      }
    } else {
      // get minimum number of available regular capacity
      let regularCapacityArray = this.cart.map(cartItem => cartItem.available_regular_capacity).sort();
      let minRegularCapacity = regularCapacityArray[0] || 0;

      if (this.additionalGuests.length < (minRegularCapacity - 1)) {
        this.additionalGuests.push(this.tempAdditionalGuests);
        this.showAddAdditionalGuestButton = !(this.additionalGuests.length >= (minRegularCapacity - 1));
      } else {
        // count minimum number of available waiting list capacity
        const minimumWaitingListCapacityArray = this.cart.map(cartItem =>
          cartItem.is_waitinglist_enabled
            ? (cartItem.available_waitinglist_capacity - this.additionalGuests?.length - 1)
            : 0
        ).sort();
        let waitingListCapcaity = minimumWaitingListCapacityArray[0] || 0;

        if (waitingListCapcaity) {
          this.additionalGuests.push(this.tempAdditionalGuests);
          this.showAddAdditionalGuestButton = waitingListCapcaity > 1;
        }
      }
    }
  }

  protected addNewAdditionalGuest(): void {
    this.isGuestEditModeEnable = true;
  }

  protected editAdditionalGuests(guest: GuestModel, index: number): void {
    this.tempAdditionalGuests = guest;
    this.tempAdditionalGuestIndex = index;
    this.isGuestEditModeEnable = true;
  }

  protected cancel(): void {
    this.resetAdditionalGuestForm();
  }

  protected save(): void {
    this.additionalGuestForm.control.markAllAsTouched();
    if (this.additionalGuestForm.invalid) return;

    if (this.tempAdditionalGuestIndex === 0 || this.tempAdditionalGuestIndex) {
      this.additionalGuests[this.tempAdditionalGuestIndex] = this.tempAdditionalGuests;
    } else {
      this.addAdditionalGuest();
    }
    this.resetAdditionalGuestForm();
  }

  private resetAdditionalGuestForm(): void {
    this.isGuestEditModeEnable = false;
    this.tempAdditionalGuests = new GuestModel();
    this.tempAdditionalGuestIndex = undefined;
    this.updateAdditionalGuests();
  }

  protected removeAdditionalGuest(index: number): void {
    this.additionalGuests.splice(index, 1);
    this.showAddAdditionalGuestButton = !(this.additionalGuests.length >= this.eventState.number_allowed_additional_guests);
    this.updateAdditionalGuests();
  }

  private updateAdditionalGuests(): void {
    if (this.disableAdditionalParticipantsEditing) {
      return;
    }

    const totalGuests = this.additionalGuests.filter(guests => (guests?.prename.trim() !== '' && guests?.lastname.trim() !== ''));

    if (totalGuests?.length) {
      this.updateAdditionalGuestsEvent.emit(totalGuests);
    }
  }
}
