import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, Input, OnDestroy, OnInit, Output, inject} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { interval, takeWhile } from 'rxjs';
import { WIDGET_CONSTANTS } from '../../../constants/widget-constants';
import { CartItem, EventCartItem } from '../../../models/cart.model';
import { Partner } from '../../../models/global';
import {
  AssertPaymentPageSaferpayRequest, AssertPaymentPageSaferpayResponse,
  InitializeSaferpayPaymentRequest, InitializeSaferpayPaymentResponse,
  SaferpayPaymentDetailsDbModel
} from '../../../models/saferpay.model';
import { AppointmentService } from '../../../services/appointment.service';
import { CustomEventService } from '../../../services/custom-event.service';
import { LoggerService } from '../../../services/logger.service';
import { PaymentService } from '../../../services/payment.service';
import { SAFERPAY_AUTHORIZED_STATUS, SAFERPAY_ERROR_STATUS, SAFERPAY_ERROR_STATUS_MESSAGES } from '../../../constants/saferpay-status.constants';

@Component({
  selector: 'app-cw-saferpay',
  template: '',
  providers: [
    { provide: Window, useValue: window }
  ],
  standalone: true
})
export class CwSaferpayComponent implements OnInit, OnDestroy {

  @Input() price: number;
  @Input() discount: number;
  @Input() partner: Partner;
  @Input() widgetType: string;
  @Input() appoinrmentServicesAvailable: any;
  @Input() cart: CartItem[];
  @Input() eventCart: EventCartItem[];

  @Output() saferpaySuccessfulEvent = new EventEmitter<SaferpayPaymentDetailsDbModel>();
  @Output() resetSaferpayEvent = new EventEmitter<string | void>();

  private destroyRef = inject(DestroyRef);
  private paymentService = inject(PaymentService);
  private appointmentService = inject(AppointmentService);
  private customEventService = inject(CustomEventService);

  stopVerifyTransactionInterval = false;

  ngOnInit(): void {
    this.paymentService.initiateSaferpaySubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(result => {
      if (result) {
        if (this.stopVerifyTransactionInterval) {
          LoggerService.log(`[saferpay] unsubscribe previous payment background trigger`);
          this.stopVerifyTransactionInterval = false;
        }
        this.payUsingSaferpay();
      }
    });

    this.paymentService.handleSaferpayBackgroundTriggerManually.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(result => {
      LoggerService.log(`[saferpay] manually stop saferpay background call on tap of close button from overlay`);
      this.stopVerifyTransactionInterval = result;
    });

    this.customEventService.cartItemsChangeEvent.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(result => {
      LoggerService.log('[saferpay] Inside cartItemsChangeEvent result ', result);
      this.discount = undefined;
      if (result.type === WIDGET_CONSTANTS.APPOINTMENT) {
        this.cart = result.cart;
      } else {
        this.eventCart = result.eventCart;
      }
    });

    this.customEventService.additionalGuestChangeEvent.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(result => {
      LoggerService.log('[saferpay] Inside this.customEventService.additionalGuestChangeEvent result ', result);
      if (this.discount === result.discount && this.price === result.finalPrice) {
        LoggerService.log('[saferpay] Inside if all values are same. No change.');
      } else {
        LoggerService.log('[saferpay] Inside else some changes in the values.');
        this.discount = result.discount;
        this.price = result.finalPrice;
      }
    });
  }

  ngOnDestroy(): void {
    this.stopVerifyTransactionInterval = true;
  }

  getServicesByIds({ serviceIds, returnKey = 'services'}: { serviceIds: number[]; returnKey?: string }) {
    return this.appointmentService.getServicesByIds({serviceIds, returnKey}, this.appoinrmentServicesAvailable);
  }

  getAppointmentNames(): string {
    let servicesList: string[];

    this.cart?.forEach(cartItem => {
      const services = this.getServicesByIds({ serviceIds: cartItem.servicesIds });
      if (services?.length) {
        servicesList = services.map(service => service.name);
      }
    });

    return servicesList.join(', ');
  }

  getEvenNames(): string {
    return this.eventCart?.map(cartItem => cartItem.currentEvent.title).join(', ');
  }

  payUsingSaferpay(): void {
    let description: string;
    if (this.widgetType === WIDGET_CONSTANTS.EVENT || this.widgetType === WIDGET_CONSTANTS.EVENTS) {
      description = this.getEvenNames();
    } else {
      description = this.getAppointmentNames();
    }

    const saferPayRequstBody: InitializeSaferpayPaymentRequest = {
      amount: this.price.toString(),
      currency: this.partner.currency,
      description: description,
      order_uuid: crypto.randomUUID()
    };

    const windowReference = window.open();

    this.paymentService.InitializeSaferpayPaymentRequest(saferPayRequstBody)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: InitializeSaferpayPaymentResponse) => {
          if (response.redirect_url) {
            windowReference.location = response.redirect_url;

            interval(10 * 1000)
              .pipe(
                takeWhile(() => !this.stopVerifyTransactionInterval),
                takeUntilDestroyed(this.destroyRef)
              )
              .subscribe(() => this.checkPaymentStatus(response.token));
          } else {
            LoggerService.log(`[saferpay] RedirectURL is missing in response of InitializeSaferpayPaymentRequest`);
            windowReference.close();
            this.resetSaferpayEvent.emit('Payment redirection URL is missing, please contact support for more details.');
          }
        },
        error: (error: HttpErrorResponse) => {
          windowReference.close();
          this.resetSaferpayEvent.emit('Internal serer error while generating payment URL, please contact support for more details.');
          LoggerService.log(`[saferpay] Getting error while generate redirect url(InitializeSaferpayPaymentRequest)`);
          LoggerService.error(error);
        }
      });
  }

  checkPaymentStatus(token: string): void {
    LoggerService.log(`[saferpay] Checking for payment status`);
    const paymentStatusBody: AssertPaymentPageSaferpayRequest = { token };
    this.paymentService.AssertPaymentPageSaferpayRequest(paymentStatusBody)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: AssertPaymentPageSaferpayResponse) => {
          if (response.status) {
            this.stopVerifyTransactionInterval = true;
            const finalStatus = response.status.toLowerCase();
            if (finalStatus === SAFERPAY_AUTHORIZED_STATUS) {
              LoggerService.log(`[saferpay] response`, response);
              LoggerService.log(`[saferpay] stop polling`);
              LoggerService.log(`[saferpay] payment success and sending details to cw-payment-method.ts`);
              this.saferpaySuccessfulEvent.emit({
                transaction_id: response.transaction_id,
                payment_method: response.payment_method
              });
            } else if (SAFERPAY_ERROR_STATUS.includes(finalStatus)) {
              LoggerService.log(`[saferpay] response`, response);
              LoggerService.log(`[saferpay] stop polling`);
              let errorMessage = SAFERPAY_ERROR_STATUS_MESSAGES[finalStatus];
              this.resetSaferpayEvent.emit(errorMessage ? `Error received from SaferPay: ${errorMessage}` : 'Payment has been failed!');
            } else {
              this.resetSaferpayEvent.emit(`Error received from SaferPay: ${response.status}`);
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          this.stopVerifyTransactionInterval = true;
          LoggerService.log(`[saferpay] Getting error while checking payment status (AssertPaymentPageSaferpayRequest)`);
          LoggerService.error(error);
        }
      });
  }
}
