<ng-select
  [id]="id"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [searchable]="searchable"
  [formControl]="selectInputControl"
  [clearable]="clearable"
  [placeholder]="placeholder"
  [multiple]="multiple"
  [closeOnSelect]="closeOnSelect"
  [required]="required"
  (change)="onSelectChange($event)"
  >
  @for (option of (countries | sortTranslationList: 'name': currentLang); track option) {
    <ng-option [value]="option">
      <div>
        <span>
          {{ option | translation: 'name': currentLang }}
        </span>
      </div>
    </ng-option>
  }
</ng-select>
