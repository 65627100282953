import {Injectable} from '@angular/core';
import {catchError, Observable, of, switchMap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CountryDbModel} from '../models/country.model';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RefDataService {

  constructor(
    private http: HttpClient
  ) { }

  public getCountries(): Observable<CountryDbModel[]> {

    return this.http
      .get<CountryDbModel[]>(`${environment.apiUrl}countries`)
      .pipe(
        catchError(() => of([])),
        switchMap((countries: CountryDbModel[]) => {
          if (countries['errors'] !== undefined) {
            of([]);
          } else {
            return of(countries);
          }
        })
      );
  }
}
