import { NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { APPOINTMENT_CONSTANT } from '../../../constants/appointment-constants';
import { TRANSLATION_TEMPLATES } from '../../../constants/translation-templates-constants';
import { StoresDao } from '../../../db-models/stores-dao';
import { CustomBookingMessageTemplate, WidgetBookingInfoDao } from '../../../db-models/widget-conf-dao';
import { Partner } from '../../../models/global';
import { CustomEventService } from '../../../services/custom-event.service';
import { FileService } from '../../../services/file.service';
import { FormsService } from '../../../services/forms.service';
import { LoggerService } from '../../../services/logger.service';
import { ReplaceTemplatePlaceholderPipe } from '../../pipes/replace-template-placeholder.pipe';
import { TranslationPipe } from '../../pipes/translation.pipe';
import { TrustHtmlPipe } from '../../pipes/trust-html.pipe';
import { AlertComponent } from '../alert/alert.component';

@Component({
  selector: 'app-finalpage',
  templateUrl: './finalpage.component.html',
  styleUrls: ['./finalpage.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [AlertComponent, NgTemplateOutlet, FontAwesomeModule, TranslateModule, TranslationPipe, TrustHtmlPipe, ReplaceTemplatePlaceholderPipe]
})
export class FinalpageComponent implements OnInit, OnDestroy {

  @Input() lang: string;
  @Input() partner: Partner;
  @Input() success: boolean;
  @Input() bookingLimitReached = false;
  @Input() successTitle: string;
  @Input() successMsg: string;
  @Input() errTitle: string;
  @Input() errMsg: string;
  @Input() courtesyForm: string;
  @Input() leadTime: number;
  @Input() bookingErrorMessage: string | string[] = undefined;
  @Input() pageType: string;
  @Input() widgetBookingInfo: WidgetBookingInfoDao;
  @Input() isWaitingListBooking = false;
  @Input() widgetSuccessPageEventBookingLimitReachedTemplate: CustomBookingMessageTemplate;
  @Input() manageAppointmentNowTemplate: CustomBookingMessageTemplate;
  @Input() downloadBookingDetailTemplate: CustomBookingMessageTemplate;
  @Input() successFullBookings: {
    landingPageLink: string,
    bookingUUID: string,
    selectedStore: StoresDao
  }[] = [];

  readonly templateContent = TRANSLATION_TEMPLATES;

  successfulBooking: boolean;
  successTitleOut: string;
  successMsgOut: string;
  errTitleOut: string;
  errMsgOut: string;

  private $finalSuccessMessageChangedSubscriber: Subscription;

  constructor(
    private customEventService: CustomEventService,
    private formsService: FormsService,
    private fileService: FileService,
    private translateService: TranslateService,
  ) {
    this.$finalSuccessMessageChangedSubscriber = this.customEventService.finalSuccessMessageChangedEvent.subscribe(data => {
      data && (this.successMsgOut = data);
    });

    this.translateService.onLangChange.subscribe(language => {
      this.lang = language.lang;
    });
  }

  ngOnInit(): void {
    if (this.success) {
      this.successfulBooking = true;
      if (this.widgetBookingInfo && this.widgetBookingInfo._translations && this.widgetBookingInfo._translations[this.lang] && !this.isWaitingListBooking) {
        this.successMsgOut = this.widgetBookingInfo._translations[this.lang].template_content;
      } else {
        this.successMsgOut = this.successMsg;
      }
    } else {
      this.successfulBooking = false;
      this.errTitleOut = this.errTitle;
      this.errMsgOut = this.errMsg;
    }

  }

  downloadBookingDetail(uuid: string): void {
    (this.pageType === APPOINTMENT_CONSTANT.APPOINTMENT) ? this.downloadAppointment(uuid) : this.downloadEventByUUID(uuid);
  }

  downloadAppointment(uuid: string): void {
    this.formsService.downloadAppointmentByUUID(uuid).subscribe({
      next: (result: { file: string, type: string }) => {
        this.fileService.saveFile(`${environment.baseUrl}${result.file}`, this.fileService.fetchFilenameFromPath(result.file));
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  downloadEventByUUID(uuid: string) {
    this.formsService.downloadEventByUUID(uuid).subscribe({
      next: (result: { file: string, type: string }) => {
        this.fileService.saveFile(`${environment.baseUrl}${result.file}`, this.fileService.fetchFilenameFromPath(result.file));
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  ngOnDestroy(): void {
    this.$finalSuccessMessageChangedSubscriber?.unsubscribe();
  }
}
