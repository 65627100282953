export class PartnerSettingDBModel {
  id: number;
  partner_id: number;
  setting_id: number;
  value: string;
  setting: SettingDBModel;
}

export class VerifyPaymentStatusModel {
  bexioEnabled: boolean;
  isBexioAppointmentServicePaymentEnabled: number;
  isBexioEventPaymentEnabled: number;
  isEventOnlinePaymentEnabled: number;
  isOnlinePaymentMandatory: number;
  isServiceOnlinePaymentEnabled: number;
  lexOfficeEnabled: boolean;
  onlinePaymentShowOnSitePaymentType: number;
  paypalAccessToken: string;
  paypalClientId: string;
  paypalEnabled: boolean;
  saferpayEnabled: boolean;
  stripeEnabled: boolean;
}

export class PayPalOrderDetailModel {
  paypal_order_id: string;
  paypal_payer_id: string;
  paypal_raw_data: string;
  paypal_status: string;
  paypal_capture_id: string;
}

export class PayPalSettingsDbModel {
  client_id: string;
  access_token: string;
}

export class SettingDBModel {
  id: number;
  key: string;
  default_value: string;
  category: string;
  type: string;
}

export enum OnlinePaymentSettingEnum {
  APPOINTMENT_CREDIT_CARD_ENABLED = 19,
  EVENT_CREDIT_CARD_ENABLED = 20,
  ONLINE_PAYMENT_MANDATORY = 18,
  BEXIO_APPOINTMENT_ONLINE_PAYMENT_MANDATORY = 49,
  BEXIO_EVENT_ONLINE_PAYMENT_MANDATORY = 50,
  ON_SITE_PAYMENT_TYPE_ENABLED = 56,
}
