import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'price',
    standalone: true
})
export class PricePipe implements PipeTransform {
  transform(price: number, toFixed?: number): string {
    if (toFixed === 0) {
      toFixed = 0;
    } else {
      if (!toFixed) {
        toFixed = 2;
      }
    }

    let priceDisplay = '';
    if (price > 0) {
      let decimal = price - Math.floor(price);
      if (decimal > 0) {
        priceDisplay = price.toFixed(2);
      } else {
        priceDisplay = price.toFixed(toFixed);
      }
    }

    const freeDisplay = price === 0 ? 'free' : '';
    const noPriceDisplay = price < 0 ? '' : '';

    return priceDisplay + freeDisplay + noPriceDisplay;
  }
}
