<div class="custom-control custom-radio d-flex">
  <input
    class="custom-control-input"
    type="radio"
    [disabled]="isDisabled"
    [name]="name"
    [id]="id"
    [(ngModel)]="fieldNgModel"
    [required]="isRequired"
    [checked]="checked"
    [value]="value"
    (change)="onChange($event, id)"
    #buttonField="ngModel"
    attr.role="radio"
    attr.aria-labelledby="{{ 'label-cf-' + id }}"
    attr.aria-checked="{{ fieldNgModel }}"
    attr.aria-invalid="{{ buttonField.touched && !buttonField.valid }}"
    attr.aria-describedby="error_{{ id }}"
  />
  <label
    id="label_{{ id }}"
    for="{{ id }}"
    class="custom-control-label d-flex w-100 {{customLabelClass}}"
    [ngClass]="{
      'calio-red': buttonField.touched && !buttonField.valid
    }"
  >
    <ng-content />
  </label>
</div>

@if (buttonField.touched && buttonField.invalid && last) {
  <div id="error_{{ id }}" role="alert" class="alert alert-danger summary-warning">
    @if (buttonField.errors?.required) {
      {{ 'appointments.step4.customFields.error.required' | translate }}
    }
  </div>
}
