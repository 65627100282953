@if (field.is_hidden === 0) {
<div class="cw-custom-field {{ 'cw-'+ field.type + '-field' + ' cw-'+ field.type + '-field-' + field.id }}">
  <div class="row cb-custom-fields-layout">
    @switch (field.type) {
      <!-- INPUT case -->
      @case ('text') {
        <div class="col-sm-12 custom-field form-group summary-padding">
          @if (field | translation: 'title': lang) {
            <div class="input-label custom-field-title">
              {{ field | translation: 'title': lang }}
              @if (field.required === 1) { <span>*</span> }
            </div>
          }

          <label
            id="{{ 'label-cf-' + field.id }}"
            for="{{ 'cf-' + field.id }}"
            class="input-label"
            [ngClass]="{ 'input-label-1': (field | translation: 'title': lang) }"
            [innerHtml]="field | translation: 'label': lang | trustHtml"
          ></label>

          @if (field.required === 1 && !(field | translation: 'title': lang)) {
            <span class="required-star"> *</span>
          }

          <input
            [required]="field.required === 1 && field.is_read_only === 0"
            [(ngModel)]="customFieldValues[field.id]"
            placeholder="{{ field | translation: 'placeholder': lang }}"
            #customFieldText="ngModel"
            id="{{ 'cf-' + field.id }}"
            name="{{ 'cf-' + field.id }}"
            type="text"
            class="form-control cbw-input-field"
            [disabled]="field.is_read_only === 1"
            [pattern]="field.regex"
            [minlength]="field.min_characters > 0 ? field.min_characters : null"
            [maxlength]="field.max_characters > 0 ? field.max_characters : null"
            (blur)="updateCustomFieldValuesInLocalStorage()"
            (ngModelChange)="updateCustomFieldValuesInLocalStorage()"
            attr.autocomplete="on"
            attr.aria-describedby="{{ customFieldText.touched && customFieldText.invalid ? 'error-cf-' + field.id : undefined }}"
            attr.aria-labelledby="{{ 'label-cf-' + field.id }}"
            attr.aria-invalid="{{ customFieldText.touched && customFieldText.invalid }}"
            attr.tabIndex="{{ tabIndex }}"
          />

          @if (customFieldText.touched && customFieldText.invalid) {
            <div id="{{ 'error-cf-' + field.id }}" role="alert" class="alert alert-danger summary-warning">
              @if (customFieldText?.errors?.required) {
                {{ 'appointments.step4.customFields.error.required' | translate }}
              }
              @if (customFieldText?.errors?.pattern) {
                @if (field?.validation_message) {
                  {{ field?.validation_message }}
                }
                @if (!field?.validation_message) {
                  {{ 'appointments.step4.customFields.error.required' | translate }}
                }
              }
              @if (customFieldText?.errors?.maxlength) {
                {{ 'appointments.step4.customFields.error.maxChars' | translate: customFieldText?.errors?.maxlength }}
              }
              @if (customFieldText?.errors?.minlength) {
                {{ 'appointments.step4.customFields.error.minChars' | translate: customFieldText?.errors?.minlength }}
              }
            </div>
          }
        </div>
      }

      <!-- TEXTAREA case -->
      @case ('textarea') {
        <div class="col-sm-12 custom-field form-group summary-padding">

          @if (field | translation: 'title': lang) {
            <div class="input-label custom-field-title">
              {{ field | translation: 'title': lang }}
              @if (field.required === 1) { <span>*</span> }
            </div>
          }

          <label
            id="{{ 'label-cf-' + field.id }}"
            for="{{ 'cf-' + field.id }}"
            class="input-label"
            [ngClass]="{ 'input-label-1': (field | translation: 'title': lang) }"
            [innerHtml]="field | translation: 'label': lang | trustHtml"
          ></label>

          @if (field.required === 1 && !(field | translation: 'title': lang)) {
            <span class="required-star"> *</span>
          }

          <textarea
            [required]="field.required === 1 && field.is_read_only === 0"
            [(ngModel)]="customFieldValues[field.id]"
            placeholder="{{ field | translation: 'placeholder': lang }}"
            name="{{ field.id}}"
            #customFieldTextarea="ngModel"
            id="{{ 'cf-' + field.id }}"
            name="{{ 'cf-' + field.id }}"
            cols="30"
            rows="5"
            class="form-control h-auto-important cbw-input-field"
            [disabled]="field.is_read_only === 1"
            [minlength]="field.min_characters > 0 ? field.min_characters : null"
            [maxlength]="field.max_characters > 0 ? field.max_characters : null"
            (blur)="updateCustomFieldValuesInLocalStorage()"
            (ngModelChange)="updateCustomFieldValuesInLocalStorage()"
            attr.aria-invalid="{{ customFieldTextarea.touched && customFieldTextarea.invalid }}"
            attr.autocomplete="on"
            attr.aria-describedby="{{ customFieldTextarea.touched && customFieldTextarea.invalid ? 'error-cf-' + field.id : undefined }}"
            attr.aria-labelledby="{{ 'label-cf-' + field.id }}"
            attr.tabIndex="{{ tabIndex }}"
          ></textarea>

          @if (customFieldTextarea.touched && customFieldTextarea.invalid) {
            <div id="{{ 'error-cf-' + field.id }}" role="alert" class="alert alert-danger summary-warning">
              @if (customFieldTextarea?.errors?.required) {
                <div>
                  {{ 'appointments.step4.customFields.error.required' | translate }}
                </div>
              }
              @if (customFieldTextarea?.errors?.maxlength) {
                <div>
                  {{ 'appointments.step4.customFields.error.maxChars' | translate: customFieldTextarea.errors.maxlength }}
                </div>
              }
              @if (customFieldTextarea?.errors?.minlength) {
                <div>
                  {{ 'appointments.step4.customFields.error.minChars' | translate: customFieldTextarea.errors.minlength }}
                </div>
              }
            </div>
          }
        </div>
      }

      <!-- SELECT case -->
      @case ('select') {
        <div class="col-sm-12 custom-field form-group summary-padding">
          @if (field | translation: 'title': lang) {
            <div class="input-label custom-field-title">
              {{ field | translation: 'title': lang }}
              @if (field.required === 1) { <span>*</span> }
            </div>
          }
          <label
            id="{{ 'label-cf-' + field.id }}"
            for="{{ 'cf-' + field.id }}"
            class="input-label"
            [ngClass]="{ 'input-label-1': (field | translation: 'title': lang) }"
            [innerHtml]="field | translation: 'label': lang | trustHtml"
          ></label>

          @if (field.required === 1 && !(field | translation: 'title': lang)) {
            <span class="required-star"> *</span>
          }

          <ng-select
            id="{{ 'cf-' + field.id }}"
            name="{{ 'cf-' + field.id }}"
            [required]="field.required === 1  && field.is_read_only === 0"
            [(ngModel)]="customFieldValues[field.id].value"
            #customFieldSelect="ngModel"
            [searchable]="true"
            placeholder="{{ field | translation: 'placeholder': lang }}"
            [multiple]="(field.is_multiple_select === 1 || field.is_multiple_select === '1')"
            (change)="onChange($event, field)"
            [searchFn]="customSearchFn1"
            (open)="bindYoutubeInfoUrlClickHandler()"
            (close)="unbindYoutubeInfoUrlClickHandler()"
            attr.aria-describedby="{{ customFieldSelect.touched && customFieldSelect.invalid ? 'error-cf-' + field.id : undefined }}"
            attr.aria-labelledby="{{ 'label-cf-' + field.id }}"
            attr.aria-invalid="{{ customFieldSelect.touched && customFieldSelect.invalid }}"
            attr.tabIndex="{{ tabIndex }}"
            >
            @for (option of field.custom_field_options; track option) {
              <ng-option [value]="option.id" [disabled]="field.is_read_only !== 0">
                <!-- Option icon -->
                @if (option?.image | isEmptyString) {
                  <img class="option-icon me-2" [src]="option?.image" height="25" width="25" />
                }
                <!-- Label -->
                <span>
                  {{ ((getCustomFieldOptionById(option.id, field.custom_field_options)) | translation: 'label': lang)?.trim() }}
                </span>
                <!-- Youtube -->
                @if ((option?.youtube_url | isEmptyString)) {
                  <span class="info-urls float-right">
                    <a
                      class="select-option-youtube-element"
                      #selectOptionYoutubeElement
                      [routerLink]=""
                      [attr.data-option]="(option | json)"
                      >
                      <img class="youtube-icon" src="/assets/images/youtube_social_icon_red.png" />
                    </a>
                  </span>
                }
                <!-- Info -->
                @if ((option?.info_url | isEmptyString)) {
                  <span
                    class="info-urls float-right"
                          [ngClass]="{
                            'me-2': (option?.youtube_url | isEmptyString)
                          }"
                    >
                    <a
                      target="_blank"
                      [href]="option.info_url"
                      >
                      <img src="assets/images/info-filled-icon.svg" />
                    </a>
                  </span>
                }
              </ng-option>
            }
          </ng-select>

          @if (customFieldSelect.touched && customFieldSelect.invalid) {
            <div id="{{ 'error-cf-' + field.id }}" role="alert" class="alert alert-danger summary-warning">
              @if (customFieldSelect?.errors?.required) {
                {{ 'appointments.step4.customFields.error.required' | translate }}
              }
            </div>
          }
        </div>
      }

      <!-- CHECKBOX case -->
      @case ('checkbox') {
        <div class="col-sm-12 form-group cf-checkbox cbw-checkbox">
          @if (field.title && field.title.trim() != '') {
            <div class="input-label custom-field-title mb-2">
              {{ field | translation: 'title': lang }}
              @if (field.required === 1) { <span>*</span> }
            </div>
          }
          <div class="d-flex checkbox-container">
            <app-cw-checkbox
              id="{{ 'cf-' + field.id }}"
              name="{{ 'cf-' + field.id }}"
              customLabelClass="ps-2"
              [(fieldNgModel)]="customFieldValues[field.id]"
              [isRequired]="field.required === 1  && field.is_read_only === 0"
              [isDisabled]="field.is_read_only === 1"
              (selectionChange)="customFieldValues[field.id] = $event.event.target.checked; onSelectCheckbox($event.event, field);"
            >
              <div class="custom-field-checkbox-text ps-1">
                <span
                  class="input-label"
                  [ngClass]="{ 'input-label-1': (field.title && field.title.trim() != '') }"
                  [innerHtml]="field | translation: 'label': lang | trustHtml"
                ></span>
                @if (field.required === 1 && !field.title) {
                  <span class="required-star"> *</span>
                }
              </div>
            </app-cw-checkbox>
          </div>
        </div>
      }

      <!-- INPUT case -->
      @case ('file') {
        <div class="col-sm-12 custom-field form-group summary-padding">
          @if (field | translation: 'title': lang) {
            <div class="input-label custom-field-title">
              {{ field | translation: 'title': lang }}
              @if (field.required === 1) { <span>*</span> }
            </div>
          }

          <label
            id="{{ 'label-cf-' + field.id }}"
            for="{{ 'cf-' + field.id }}"
            class="input-label"
            [ngClass]="{ 'input-label-1': (field | translation: 'title': lang) }"
            [innerHtml]="field | translation: 'label': lang | trustHtml">
          </label>

          @if (field.required === 1 && !(field | translation: 'title': lang)) {
            <span class="required-star"> *</span>
          }
          <div class="input-group">
            <div
              role="button"
              attr.aria-describedby="{{ field | translation: 'placeholder': lang }}"
              attr.aria-labelledby="{{ 'label-cf-' + field.id }}"
              attr.tabIndex="{{ tabIndex }}"
              class="w-100"
            >
              <input
                id="{{ 'cf-' + field.id }}"
                name="{{ 'cf-' + field.id }}"
                [required]="field.required === 1 && field.is_read_only === 0"
                minlength="1"
                [(ngModel)]="customFieldValues[field.id].value"
                placeholder="{{ field | translation: 'placeholder': lang }}"
                #customFieldText="ngModel"
                type="file"
                class="form-control cbw-input-field input-file custom-input-file"
                [disabled]="field.is_read_only === 1 || ( field?.is_multiple_file === 0 && field?.uploadedFiles?.length === 1 ) || ( field?.is_multiple_file === 1 && field?.uploadedFiles?.length >= 5 )"
                (change)="onFileChanged($event, field)"
                [multiple]="(field.is_multiple_file === 1)"
                accept="{{ field.customSupportedMimeType }}"
                attr.aria-invalid="{{ customFieldText.touched && customFieldText.invalid }}"
                attr.aria-describedby="{{ customFieldText.touched && customFieldText.invalid ? 'error-cf-' + field.id : undefined }}"
              />

              <label
                attr.aria-labelledby="{{ 'label-cf-' + field.id }}"
                class="custom-file-label choose-file-text"
                for="{{ 'cf-' + field.id }}"
                [ngClass]="{ 'disabled-choose-file-text': (field.is_read_only === 1 || ( field?.is_multiple_file === 0 && field?.uploadedFiles?.length === 1 ) || ( field?.is_multiple_file === 1 && field?.uploadedFiles?.length >= 5 )) }"
              >
                {{ 'summary_page_translations.choose_file' | translate }}
              </label>

              @if (field.is_multiple_file === 1) {
                <small role="alert" attr.aria-live="polite" class="calio-text-muted">
                  {{ 'summary_page_translations.multiple_file_upload_helper_text' | translate }}
                </small>
              }
              @if (field.is_multiple_file === 0) {
                <small role="alert" attr.aria-live="polite" class="calio-text-muted">
                  {{ 'summary_page_translations.single_file_upload_helper_text' | translate }}
                </small>
              }
              @if (waitForFileUpload) {
                <div role="alert" attr.aria-live="polite" class="alert alert-danger summary-warning">
                  <div class="spinner-border" role="status"></div>
                  {{ 'summary_page_translations.file_uploading' | translate }}
                </div>
              }
              @if (customFieldText.touched && customFieldText.invalid && customFieldText.errors) {
                <div id="{{ 'error-cf-' + field.id }}" role="alert" class="alert alert-danger summary-warning">
                  @if (customFieldText.errors?.required && !field.errorMessage) {
                    {{ 'appointments.step4.customFields.error.required' | translate }}
                  }
                  @if (field.errorMessage) {
                    {{ field.errorMessage | translate: {  fileLength: 5 } }}
                  }
                </div>
              }
            </div>
          </div>

          @if (field?.uploadedFiles?.length > 0) {
            <div role="list" class="uploaded-files my-2">
              @for (fileData of field?.uploadedFiles; track fileData; let i = $index) {
                <div role="listitem" class="d-flex align-items-center mb-1">
                  <div class="uploaded-file-label mt-0">
                    {{ 'summary_page_translations.file_was_uploaded' | translate: { name: fileData?.origin_filename } }}
                  </div>
                  <div class="ps-2">
                    <span role="button" class="file-trash-icon d-flex" (click)="deleteFile(field, fileData, i)">
                      <fa-icon class="icon" icon="times" />
                    </span>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      }

      <!-- Radio case -->
      @case ('radio') {
        <div class="col-sm-12 custom-field form-group summary-padding">
          @if (field | translation: 'title': lang) {
            <div class="input-label custom-field-title">
              {{ field | translation: 'title': lang }}
              @if (field.required === 1) { <span>*</span> }
            </div>
          }

          <label
            id="{{ 'label-cf-' + field.id }}"
            for="{{ 'cf-' + field.id }}"
            class="input-label"
            [ngClass]="{ 'input-label-1': (field | translation: 'title': lang) }"
            [innerHtml]="field | translation: 'label': lang | trustHtml">
          </label>

          @if (field.required === 1 && !(field | translation: 'title': lang)) {
            <span class="required-star"> *</span>
          }

          @for (custom_field_option of field.custom_field_options; track custom_field_option; let last = $last) {
            @if (custom_field_option.id != null) {
              <div class="d-flex checkbox-container">
                <app-cw-radio-button
                  customLabelClass="ps-2"
                  id="{{ 'cf-' + custom_field_option.id }}"
                  name="{{ 'cf-' + field.id }}"
                  [value]="custom_field_option.id"
                  [checked]="customFieldValues[field.id] === custom_field_option.id ? true: false"
                  [(fieldNgModel)]="customFieldValues[field.id]"
                  [isRequired]="field.required === 1  && field.is_read_only === 0"
                  [isDisabled]="field.is_read_only === 1"
                  [last]="last"
                  [widgetType]="widgetType"
                  (selectionChange)="onRadioValueChanged(custom_field_option, true)"
                >
                  <div class="custom-field-checkbox-text ps-1">
                    <span
                      id="{{ 'label-cf-' + custom_field_option.id }}"
                      class="input-label"
                      [innerHtml]="custom_field_option | translation: 'label': lang | trustHtml"
                    ></span>
                  </div>
                </app-cw-radio-button>
              </div>
            }
          }
        </div>
      }

      <!-- Date case -->
      @case ('date') {
        <div class="col-sm-12 custom-field form-group summary-padding">
          @if (field | translation: 'title': lang) {
            <div class="input-label custom-field-title">
              {{ field | translation: 'title': lang }}
              @if (field.required === 1) { <span>*</span> }
            </div>
          }

          <label
            id="{{ 'label-cf-' + field.id }}"
            for="{{ 'cf-' + field.id }}"
            class="input-label"
            [ngClass]="{ 'input-label-1': (field | translation: 'title': lang) }"
            [innerHtml]="field | translation: 'label': lang | trustHtml">
          </label>

          @if (field.required === 1 && !(field | translation: 'title': lang)) {
            <span class="required-star"> *</span>
          }

          <mat-form-field>
            <input
              id="{{ 'cf-' + field.id }}"
              name="{{ 'cf-' + field.id }}"
              matInput
              [matDatepicker]="picker"
              [(ngModel)]="selectedDate"
              #dateModel="ngModel"
              placeholder="{{ field | translation: 'placeholder': lang }}"
              [required]="field.required === 1  && field.is_read_only === 0"
              [disabled]="field.is_read_only === 1"
              (dateChange)="onChangeDate($event)"
              attr.autocomplete="off"
              attr.aria-invalid="{{ dateModel.touched && dateModel.invalid }}"
              attr.aria-describedby="{{ dateModel.touched && dateModel.invalid ? 'error-cf-' + field.id : undefined }}"
              attr.aria-labelledby="{{ 'label-cf-' + field.id }}"
              attr.tabIndex="{{ tabIndex }}"
            />
            <mat-datepicker-toggle matSuffix [for]="picker" />
            <mat-datepicker #picker />
          </mat-form-field>

          @if (dateModel.touched && dateModel.invalid) {
            <div id="{{ 'error-cf-' + field.id }}" role="alert" class="text-danger">
              @if (dateModel.errors?.required) {
                <div>
                  {{ 'appointments.step4.customFields.error.required' | translate }}
                </div>
              }
            </div>
          }
        </div>
      }

      <!-- Information -->
      @case ('information') {
        <div class="col-sm-12 custom-field form-group mt-3 summary-padding">
          <div role="alert" class="alert mb-0 cbw-cstm-field-information"
            [ngClass]="{
              'alert-danger': field.color === 'red',
              'alert-primary': field.color === 'blue',
              'alert-warning': field.color === 'yellow',
              'alert-light': field.color === 'neutral'
            }"
          >
            @if (field.is_multi_language === 1) {
              @if (field | translation: 'title': lang) {
                <div class="input-label custom-field-title mt-0">
                  {{ field | translation: 'title': lang }}
                </div>
              }
              <label
                id="{{ 'label-cf-' + field.id }}"
                for="{{ 'cf-' + field.id }}"
                class="input-label mt-0"
                [ngClass]="{ 'input-label-1': (field | translation: 'title': lang) }"
                [innerHtml]="field | translation: 'label': lang | trustHtml">
              </label>
            }
            @if (field.is_multi_language === 0) {
              @if (field.title && field.title.trim() != '') {
                <div class="input-label custom-field-title mt-0">
                  {{ field.title }}
                </div>
              }
              <label
                id="{{ 'label-cf-' + field.id }}"
                for="{{ 'cf-' + field.id }}"
                class="input-label mt-0"
                [ngClass]="{ 'input-label-1': (field.title && field.title.trim() != '') }"
                [innerHtml]="field.label | trustHtml">
              </label>
            }
          </div>
        </div>
      }

      <!-- CARD SELECT case -->
      @case ('card-select') {
        <div class="col-sm-12 custom-field form-group csw-cf-margin-top summary-padding">
          @if (field | translation: 'title': lang) {
            <div class="input-label custom-field-title">
              {{ field | translation: 'title': lang }}
              @if (field.required === 1) { <span>*</span> }
            </div>
          }
          <label
            id="{{ 'label-cf-' + field.id }}"
            for="{{ 'cf-' + field.id }}"
            class="input-label"
            [ngClass]="{ 'input-label-1': (field | translation: 'title': lang) }"
            [innerHtml]="field | translation: 'label': lang | trustHtml"
          ></label>

          @if (field.required === 1 && !(field | translation: 'title': lang)) {
            <span class="required-star"> *</span>
          }
        </div>

        @for (option of field.custom_field_options; track option; let index = $index) {
          <div
            attr.role="{{ field.is_multiple_select === 0 ? 'radio' : 'checkbox' }}"
            attr.aria-describedby="{{ customFieldSelect.touched && customFieldSelect.invalid ? 'error-cf-' + field.id : undefined }}"
            attr.aria-labelledby="label_option_{{ option.id }}"
            attr.aria-checked="{{ (customFieldValues[field.id]?.value | typeof) === 'number' ? (customFieldValues[field.id]?.value === option?.id) : (customFieldValues[field.id]?.value?.indexOf(option?.id) > -1) }}"
            attr.tabIndex="{{ tabIndex + index }}"
            class="custom-field mt-2 mt-md-none summary-padding"
            [ngClass]="{
              'col-md-3': widgetType !== widgetConstants.COMPACT,
              'col-md-4': widgetType === widgetConstants.COMPACT
            }"
          >
            <div
              class="cbw-select-card d-flex align-items-center"
              (click)="onSelectCard(option, index);"
              [ngClass]="{
                'selected-card': (customFieldValues[field.id]?.value | typeof) === 'number' ? (customFieldValues[field.id]?.value === option?.id) : (customFieldValues[field.id]?.value?.indexOf(option?.id) > -1)
              }"
            >
              <div class="cbw-select-card-body">
                <div class="d-flex flex-column">
                  @if ((option | translation: 'title': lang)) {
                    <div class="cbw-title">
                      {{ option | translation: 'title': lang }}
                    </div>
                  }
                  <div
                    id="label_option_{{ option.id }}"
                    class="mt-0"
                    [ngClass]="{
                      'cbw-function': (option | translation: 'title': lang),
                      'cbw-title': !(option | translation: 'title': lang)
                    }"
                  >
                    {{ option | translation: 'label': lang }}
                  </div>
                  @if ((option?.info_url | isEmptyString)) {
                    <span class="card-info-url">
                      <a target="_blank" href="{{ option?.info_url }}" (click)="$event.stopImmediatePropagation()">
                        <img src="assets/images/info-filled-icon.svg" />
                      </a>
                    </span>
                  }
                  @if ((option?.youtube_url | isEmptyString)) {
                    <span class="select-card-youtube-url">
                      <a href="javascript:void(0)" (click)="openYoutubeVideo(option); $event.stopImmediatePropagation();">
                        <img class="youtube-icon" src="/assets/images/youtube_social_icon_red.png">
                      </a>
                    </span>
                  }
                </div>
              </div>
            </div>
          </div>
        }

        <div class="col-sm-12 summary-padding">
          <input
            hidden
            [required]="field.required === 1 && field.is_read_only === 0"
            [(ngModel)]="customFieldValues[field.id].value"
            name="{{ field.id}}"
            #customFieldSelect="ngModel"
            id="{{'cf-' + field.id}}"
            class="form-control cbw-input-field"
          >
          @if (customFieldSelect.touched && customFieldSelect.invalid) {
            <div id="{{ 'error-cf-' + field.id }}" role="alert" class="alert alert-danger summary-warning mt-1">
              @if (customFieldSelect?.errors?.required) {
                <div>
                  {{ 'appointments.step4.customFields.error.required' | translate }}
                </div>
              }
            </div>
          }
        </div>
      }

      <!-- IMAGE CARD case -->
      @case ('image-select') {
        <div class="col-sm-12 custom-field form-group csw-cf-margin-top summary-padding">
          @if (field | translation: 'title': lang) {
            <div class="input-label custom-field-title">
              {{ field | translation: 'title': lang }}
              @if (field.required === 1) { <span>*</span> }
            </div>
          }

          <label
            id="{{ 'label-cf-' + field.id }}"
            for="{{ 'cf-' + field.id }}"
            class="input-label"
            [ngClass]="{ 'input-label-1': (field | translation: 'title': lang) }"
            [innerHtml]="field | translation: 'label': lang | trustHtml"
          ></label>

          @if (field.required === 1 && !(field | translation: 'title': lang)) {
            <span class="required-star"> *</span>
          }
        </div>

        @for (option of field.custom_field_options; track option; let index = $index) {
          <div
            attr.role="{{ field.is_multiple_select === 0 ? 'radio' : 'checkbox' }}"
            attr.aria-labelledby="label_option_{{ option.id }}"
            attr.aria-checked="{{ customFieldValues[field.id]?.value?.indexOf(option.id) > -1 }}"
            attr.aria-describedby="{{ customFieldSelect.touched && customFieldSelect.invalid ? 'error-cf-' + field.id : undefined }}"
            attr.tabIndex="{{ tabIndex + index }}"
            class="col-md-4 custom-field mt-2 mt-md-none summary-padding"
          >
            <div
              class="cbw-select-card d-flex flex-column"
              (click)="onSelectCard(option, index);"
              [ngClass]="{
                'selected-card': customFieldValues[field.id]?.value?.indexOf(option.id) > -1
              }"
            >
              <img
                #imageSelectionOption
                [src]="option.image" alt="Card image cap"
                [ngClass]="{
                  'card-img-top': option.is_fullscreen_image === 1,
                  'icon-img': option.is_fullscreen_image !== 1
                }"
              />

              <div id="label_option_{{ option.id }}" class="cbw-select-card-body">
                <div class="d-flex image-title-block justify-content-between">
                  @if ((option | translation: 'title': lang)) {
                    <div class="cbw-title">
                      {{ option | translation: 'title': lang }}
                    </div>
                  }
                  <div class="d-flex flex-column mt-0"
                    [ngClass]="{
                      'cbw-function': (option | translation: 'title': lang),
                      'cbw-title': !(option | translation: 'title': lang)
                    }"
                  >
                    {{ option | translation: 'label': lang }}
                  </div>

                  @if (option.is_fullscreen_image === 1) {
                    <div class="d-flex justify-content-end">
                      <span [ngClass]="{ 'me-2': option?.info_url ? true : false }">
                        <a href="javascript:void(0);" (click)="openImagePopup(option, imageSelectionOption); $event.stopImmediatePropagation();">
                          <img class="view-image-icon" [src]="deployUrl + '/assets/images/full-screen.svg'" />
                        </a>
                      </span>
                      @if ((option?.info_url | isEmptyString)) {
                        <span [ngClass]="{ 'me-2': option?.youtube_url ? true : false }">
                          <a target="_blank" href="{{ option?.info_url }}" (click)="$event.stopImmediatePropagation()">
                            <img src="assets/images/info-filled-icon.svg" />
                          </a>
                        </span>
                      }
                      @if ((option?.youtube_url | isEmptyString)) {
                        <span>
                          <a href="javascript:void(0)" (click)="openYoutubeVideo(option); $event.stopImmediatePropagation();">
                            <img class="youtube-icon" src="/assets/images/youtube_social_icon_red.png">
                          </a>
                        </span>
                      }
                    </div>
                  }
                </div>
                @if (option.is_fullscreen_image !== 1) {
                  @if ((option?.info_url | isEmptyString)) {
                    <span class="card-info-url">
                      <a target="_blank" href="{{ option?.info_url }}" (click)="$event.stopImmediatePropagation()">
                        <img src="assets/images/info-filled-icon.svg" />
                      </a>
                    </span>
                  }
                  @if ((option?.youtube_url | isEmptyString)) {
                    <span class="select-card-youtube-url">
                      <a href="javascript:void(0)" (click)="openYoutubeVideo(option); $event.stopImmediatePropagation();">
                        <img class="youtube-icon" src="/assets/images/youtube_social_icon_red.png">
                      </a>
                    </span>
                  }
                }
              </div>
            </div>
          </div>
        }

        <div class="col-sm-12 summary-padding">
          <input
            hidden
            [required]="field.required === 1 && field.is_read_only === 0"
            [(ngModel)]="customFieldValues[field.id].value"
            name="{{ field.id}}"
            #customFieldSelect="ngModel"
            id="{{'cf-' + field.id}}"
            class="form-control cbw-input-field"
          >
          @if (customFieldSelect.touched && customFieldSelect.invalid) {
            <div id="{{ 'error-cf-' + field.id }}" role="alert" class="alert alert-danger summary-warning mt-1">
              @if (customFieldSelect?.errors?.required) {
                {{ 'appointments.step4.customFields.error.required' | translate }}
              }
            </div>
          }
        </div>
      }

      <!-- Number Card case -->
      @case ('number-select') {
        <div
          role="group"
          attr.aria-labelledby="{{ 'label-cf-' + field.id }}"
          attr.aria-describedby="{{ customFieldSelect.touched && customFieldSelect.invalid ? 'error-cf-' + field.id : undefined }}"
          class="col-sm-12 custom-field form-group csw-cf-margin-top summary-padding"
        >
          @if (field | translation: 'title': lang) {
            <div class="input-label custom-field-title">
              {{ field | translation: 'title': lang }}
              @if (field.required === 1) { <span>*</span> }
            </div>
          }

          <label
            id="{{ 'label-cf-' + field.id }}"
            for="{{ 'cf-' + field.id }}"
            class="input-label"
            [ngClass]="{ 'input-label-1': (field | translation: 'title': lang) }"
            [innerHtml]="field | translation: 'label': lang | trustHtml"
          ></label>

          @if (field.required === 1 && !(field | translation: 'title': lang)) {
            <span class="required-star"> *</span>
          }
        </div>

        @for (option of field.custom_field_options; track option; let index = $index) {
          <div
            class="col-md-6 custom-field mt-2 mt-md-none summary-padding"
            [ngClass]="{ 'col-lg-4': (widgetType !== widgetConstants.COMPACT) }"
          >
            <div class="cbw-select-card d-flex align-items-center cbw-number-select-card">
              <div class="cbw-select-card-body w-100">
                <div class="d-flex justify-content-between align-items-center">
                  <div id="option_label_{{ option.id }}" class="d-flex flex-column justify-content-center">
                    @if ((option | translation: 'title': lang)) {
                      <div class="cbw-title">
                        {{ option | translation: 'title': lang }}
                      </div>
                    }
                    <div
                      [ngClass]="{
                        'cbw-function': (option | translation: 'title': lang),
                        'cbw-title': !(option | translation: 'title': lang)
                      }"
                    >
                      {{ option | translation: 'label': lang }}
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <button
                      attr.aria-labelledby="option_label_{{ option.id }}"
                      attr.aria-label="{{ 'appointments.step4.numberSelect.decrease' | translate }}"
                      type="button"
                      [disabled]="(option.number_min === option?.option_value || option?.option_value < option.number_min)"
                      class="btn btn-link d-flex align-items-center justify-content-center rounded-icon"
                      (click)="reduceNumberCardValue(option)"
                    >
                      <fa-icon class="icon" icon="minus" attr.alt="{{ 'appointments.step4.numberSelect.decrease' | translate }}" />
                    </button>
                    <span attr.aria-labelledby="option_label_{{ option.id }}" class="px-4">{{ option?.option_value }}</span>
                    <button
                      attr.aria-labelledby="option_label_{{ option.id }}"
                      attr.aria-label="{{ 'appointments.step4.numberSelect.increase' | translate }}"
                      type="button"
                      [disabled]="(option.number_max === option?.option_value || option?.option_value > option.number_max)"
                      class="btn btn-link d-flex align-items-center justify-content-center rounded-icon"
                      (click)="addNumberCardValue(option)"
                    >
                      <fa-icon class="icon" icon="plus" attr.alt="{{ 'appointments.step4.numberSelect.decrease' | translate }}" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        <div class="col-sm-12 summary-padding">
          <input
            hidden
            [required]="field.required === 1 && field.is_read_only === 0"
            [(ngModel)]="customFieldValues[field.id].value"
            name="{{ field.id}}"
            #customFieldSelect="ngModel"
            id="{{'cf-' + field.id}}"
            class="form-control cbw-input-field"
          >
          @if (customFieldSelect.touched && !customFieldSelect.valid) {
            <div id="{{ 'error-cf-' + field.id }}" role="alert" class="alert alert-danger summary-warning mt-1">
              @if (customFieldSelect?.errors?.required) {
                <div>
                  {{ 'appointments.step4.customFields.error.required' | translate }}
                </div>
              }
            </div>
          }
        </div>
      }
    }
  </div>
</div>
}

@if (field.is_hidden === 1) {
  <input
    type="hidden"
    id="{{ 'cf-' + field.id }}"
    name="{{ 'cf-' + field.id }}"
    [(ngModel)]="customFieldValues[field.id]"
    #customFieldText="ngModel"
  />
}
