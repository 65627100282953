import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addRequired',
  standalone: true
})
export class CalioAddRequiredPipe implements PipeTransform {

  transform(value: string, options?: {
    isRequired: boolean
  }): any {

    if (!value) { return null; }
    if (options) {
      return options?.isRequired ? value.trim() + ' *' : value.trim();
    }

    return value.trim() + ' *';
  }
}
