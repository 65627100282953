import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CouponDbModel } from '../../../db-models/coupon-db.model';
import { Partner } from '../../../models/global';
import { CustomEventService } from '../../../services/custom-event.service';
import { FormsModule } from '@angular/forms';
import { FormsService } from '../../../services/forms.service';
import { LoggerService } from '../../../services/logger.service';

@Component({
    selector: 'app-coupon-form',
    templateUrl: './coupon-form.component.html',
    styleUrls: ['./coupon-form.component.scss'],
    standalone: true,
    imports: [FormsModule, TranslateModule]
})
export class CouponFormComponent  {

  @Input() partner: Partner;
  coupon: string;
  showError: boolean;
  showSuccessMsg: boolean;

  @Output() couponSuccessEvent: EventEmitter<CouponDbModel> = new EventEmitter();
  @Output() couponFailedEvent: EventEmitter<{
    disableBookingButton: boolean,
    invalidCoupon: boolean,
  }> = new EventEmitter();

  constructor(
    private formsService: FormsService,
    private customEventService: CustomEventService,
  ) {
  }

  onApplyCoupon() {
    this.showError = false;
    this.showSuccessMsg = false;
    this.couponFailedEvent.emit({disableBookingButton: false, invalidCoupon: true});
    if (!this.coupon) {
      this.customEventService.resetPayPalOrderDetailEvent.emit();
      return;
    }
    this.formsService.applyCoupon(this.coupon).subscribe({
      next: (result: CouponDbModel) => {
        if (result.errors && result.errors.length === 1 && Number(result.errors[0].code) === 404) {
          this.showError = true;
          this.couponFailedEvent.emit({
            disableBookingButton: true,
            invalidCoupon: true
          });
          this.customEventService.resetPayPalOrderDetailEvent.emit();
        } else if (result.errors && result.errors.length === 1 && Number(result.errors[0].code) === 400) {
          this.showError = true;
          this.couponFailedEvent.emit({
            disableBookingButton: true, invalidCoupon: true
          });
          this.customEventService.resetPayPalOrderDetailEvent.emit();
        } else if (result.errors && result.errors.length === 1 && Number(result.errors[0].code) === 401) {
          this.showError = true;
          this.couponFailedEvent.emit({disableBookingButton: true, invalidCoupon: true});
          this.customEventService.resetPayPalOrderDetailEvent.emit();
        } else {
          this.showSuccessMsg = true;
          this.couponSuccessEvent.emit(result);
        }
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
        this.showError = true;
        this.couponFailedEvent.emit({disableBookingButton: false, invalidCoupon: true});
      }
    });
  }
}
