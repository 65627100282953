import { Injectable, EventEmitter } from '@angular/core';
import { CustomFieldChildEvent } from '../db-models/appointment-custom-fields-dao';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldService {

  public toggleCustomFieldChildEvent:
    EventEmitter<{
      checked: boolean,
      childEvent: CustomFieldChildEvent,
      label: string,
    }> = new EventEmitter();

  constructor(
  ) {
  }


}
