<mat-icon
  svgIcon="calio-{{iconType}}-icon"
  class="d-flex {{customClass}}"
  [ngStyle]="{
    'height': height,
    'width': width,
    'fill': fill
  }"
  (click)="clickEvent.emit()"
  ngbTooltip="{{ tooltip }}"
  placement="{{ placement }}"
  openDelay="1000"
/>
