import {Pipe, PipeTransform} from '@angular/core';
import { LoggerService } from '../../services/logger.service';

@Pipe({
    name: 'sortTranslationList',
    standalone: true
})
export class CalioTranslationSortPipe implements PipeTransform {

  transform(
    list: Array<any>,
    sortKey: string,
    language: string,
  ): any {
    LoggerService.log('list is ', list);
    LoggerService.log('sortKey is ', sortKey);
    LoggerService.log('language is ', language);

    try {
      return this.sortBy(
        list,
        sortKey,
        language
      );
    } catch (error) {
      LoggerService.error(error);
      return list;
    }
  }

  sortBy(
    collection: Array<any>,
    property: string,
    lang: string,
  ): Array<any> {
    collection.sort((a: any, b: any) => {
      if ((a?._translations?.[lang]?.[property] ? a?._translations?.[lang]?.[property]?.toLowerCase() : a?.[property]) < (b?._translations?.[lang]?.[property] ? b?._translations?.[lang]?.[property]?.toLowerCase() : b?.[property])) {
        return -1;
      } else if (a?._translations?.[lang]?.[property] > b?._translations?.[lang]?.[property]) {
        return 1;
      } else {
        return 0;
      }
    });
    return collection;
  }
}
