import { Injectable } from '@angular/core';
import { AnalyticsDao } from '../db-models/widget-conf-dao';
import { LoggerService } from './logger.service';

declare var dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {

  partnerAnalyticsObj: AnalyticsDao;

  public emitBookingEvent(eventName: string, params: any = null) {
    const type = 'TRACKER_TRIGGER_ACTION';

    // Google Tag Manager
    try {
      if (this.partnerAnalyticsObj?.GTM && this.partnerAnalyticsObj?.GTM.trim() !== '') {
        dataLayer.push({
          event: eventName,
          ...params
        });
        LoggerService.log(`[GOOGLE_TAG]:[${eventName}] event was fired.`, params);
      }
    } catch (error) {
      LoggerService.warn('[GOOGLE_TAG]: Datalayer not defined.');
      LoggerService.error(error);
    }

    // Adobe launch tracking
    try {
      if (this.partnerAnalyticsObj?.AdobeLaunchTracking && this.partnerAnalyticsObj?.AdobeLaunchTracking.trim() !== '') {
        const someClick = new CustomEvent(type, {
          detail: {
            name: eventName,
            params
          }
        });
        window.dispatchEvent(someClick);
        LoggerService.log(`[ADOBE_LAUNCH_TRACKING]:[${eventName}] event was fired.`, params);
      }
    } catch (error) {
      LoggerService.warn('[ADOBE_LAUNCH_TRACKING]: Adobe event error handler.');
      LoggerService.error(error);
    }

    // Send event to parent window
    this.emitEventToParent(eventName, params);
  }

  public emitEventToParent(eventName: string, params: any = null) {
    try {
      window.parent.postMessage(
        {
          eventName: eventName,
          bookingData: params,
        },
        '*'
      );
      LoggerService.log(`[EMIT_TO_PARNT_WINDOW]:[${eventName}] event was fired.`, params);
    } catch (error) {
      LoggerService.warn('[EMIT_TO_PARNT_WINDOW]: Adobe event error handler.');
      LoggerService.error(error);
    }
  }
}
