export const MeetingTypeImages = [
  { id: 1, src: 'store.svg', alt: 'Store' },
  { id: 2, src: 'zoom.svg', alt: 'Zoom' },
  { id: 3, src: 'calenso.svg', alt: 'Calenso' },
  { id: 4, src: 'phone.svg', alt: 'Phone' },
  { id: 5, src: 'customer.svg', alt: 'Customer' },
  { id: 6, src: 'unblu.svg', alt: 'Unblu' },
  { id: 7, src: 'teams.svg', alt: 'Teams' },
  { id: 8, src: 'meet.svg', alt: 'Meet' },
  { id: 9, src: 'gotomeeting.png', alt: 'Gotomeeting' },
  { id: 10, src: 'webex.png', alt: 'Webex' },
  { id: 11, src: 'phone.svg', alt: 'Phone' },
  { id: 13, src: 'metaverse.svg', alt: 'Metaverse' },
];
