import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, filter, map } from 'rxjs';
import { AuthTokenService } from '../services/auth-token.service';
import { LoggerService } from '../services/logger.service';
import { PartnerService } from '../services/partner.service';
import { IS_CALENSO_AUTH_REQUIRED } from '../constants/http-context';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private partnerService: PartnerService,
    private authTokenService: AuthTokenService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers: {
      [name: string]: string | string[];
    } = {};

    if (
      request.method === 'POST' &&
      this.partnerService.partnerVerificationCode &&
      request.body?.appendpartnerVerificationCode !== undefined
    ) {
      delete request.body.appendpartnerVerificationCode;
      request.body.token = this.partnerService.partnerVerificationCode;
    }

    request = request.clone({
      setHeaders: headers
    });

    return next.handle(request).pipe(
      filter((event): event is HttpResponse<any> => event instanceof HttpResponse),
      map(event => {
        if(request.context.has(IS_CALENSO_AUTH_REQUIRED)) {
          const authToken = event?.body?.token;
          authToken && this.authTokenService.setAuthToken(authToken);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        LoggerService.log('error is ', error);
        throw error;
      })
    )
  }
}
