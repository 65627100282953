<div class="question-icon">
    <span placement="left"
        [ngbTooltip]="description"
        trigger="hover focus"
        [autoClose]="false"
        id="{{id}}"
    >
        <fa-icon class="icon" icon="question-circle"></fa-icon>
    </span>
</div>
