import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toMinute',
    standalone: true
})
export class MinuteReplacePipe implements PipeTransform {
    transform(value: number): string {
       value = value * 60;

       const m = Math.round(value % 60 % 60);

       const mDisplay = m;

       return ' ' + mDisplay + '';
    }
}
