import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AdsenseModule } from 'ng2-adsense';
import { environment } from '../../../../../environments/environment';
import { APPOINTMENT_CONSTANT } from '../../../../constants/appointment-constants';
import { TRANSLATION_TEMPLATES } from '../../../../constants/translation-templates-constants';
import { WIDGET_CONSTANTS } from '../../../../constants/widget-constants';
import { UserDao } from '../../../../db-models/user-data-dao';
import { CustomBookingMessageTemplate } from '../../../../db-models/widget-conf-dao';
import { CartItem } from '../../../../models/cart.model';
import { GlobalObjects, Partner } from '../../../../models/global';
import { AppointmentState } from '../../../../models/state.model';
import { WidgetColorConf } from '../../../../models/widget-color.model';
import { AppointmentService } from '../../../../services/appointment.service';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';

@Component({
  selector: 'app-appointment-navigation-bar',
  templateUrl: './appointment-navigation-bar.component.html',
  styleUrls: ['./appointment-navigation-bar.component.scss'],
  standalone: true,
  imports: [AdsenseModule, NgClass, FontAwesomeModule, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, TranslateModule, TranslationPipe]
})
export class AppointmentNavigationBarComponent implements OnChanges {

  @Input() supportedWidgetLanguages: string[];
  @Input() appointmentState: AppointmentState;
  @Input() viewMode: string;
  @Input() workersAvailable: UserDao[];
  @Input() cart: CartItem[];
  @Input() showAds: boolean;
  @Input() isServicesTabDisabled: boolean;
  @Input() widgetColorConf: WidgetColorConf;
  @Input() hideResourceStep: boolean;
  @Input() partner: Partner;
  @Input() lang: string;
  @Input() globals: GlobalObjects;
  @Input() widgetTemplates: CustomBookingMessageTemplate[] = [];
  @Input() langSwitcher = true;

  @Output('navigateToEvent') navigateToEvent = new EventEmitter<any>();

  @ViewChildren('tab') tabs !: QueryList<any>;

  readonly appointmentConstant = APPOINTMENT_CONSTANT;
  readonly templateContent = TRANSLATION_TEMPLATES;

  subscribe: any;
  firstButtonDisabled: boolean = false;
  secondButtonDisabled: boolean = false;
  thirdButtonDisabled: boolean = false;
  forthButtonDisabled: boolean = false;
  widgetServiceLabelTemplate: CustomBookingMessageTemplate;
  widgetResourceLabelTemplate: CustomBookingMessageTemplate;
  widgetDateLabelTemplate: CustomBookingMessageTemplate;
  widgetPersonalLabelTemplate: CustomBookingMessageTemplate;
  widgetConstants = WIDGET_CONSTANTS;
  environment: any;

  constructor(
    private appointmentService: AppointmentService,
    private translate: TranslateService,
  ) {

    this.environment = environment;

    this.appointmentService.disableFirstTwoButtonsEvent.subscribe(() => {
      this.firstButtonDisabled = true;
      this.secondButtonDisabled = true;
    });

    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['widgetTemplates'] && changes['widgetTemplates']['currentValue']) {
      if (this.widgetTemplates && this.widgetTemplates.length > 0) {
        this.setupTemplates();
      }
    }
  }

  setupTemplates() {
    this.widgetServiceLabelTemplate = this.widgetTemplates.find((template: CustomBookingMessageTemplate) => {
      return (template).identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NAV_SERVICE_LABEL;
    });
    if (this.widgetServiceLabelTemplate) {
      this.widgetServiceLabelTemplate.is_multi_language = 1;
    }
    this.widgetResourceLabelTemplate = this.widgetTemplates.find((template: CustomBookingMessageTemplate) => {
      return (template).identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NAV_RESOURCE_LABEL;
    });
    if (this.widgetResourceLabelTemplate) {
      this.widgetResourceLabelTemplate.is_multi_language = 1;
    }

    this.widgetDateLabelTemplate = this.widgetTemplates.find((template: CustomBookingMessageTemplate) => {
      return (template).identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NAV_DATE_LABEL;
    });
    if (this.widgetDateLabelTemplate) {
      this.widgetDateLabelTemplate.is_multi_language = 1;
    }

    this.widgetPersonalLabelTemplate = this.widgetTemplates.find((template: CustomBookingMessageTemplate) => {
      return (template).identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NAV_SUMMARY_LABEL;
    });
    if (this.widgetPersonalLabelTemplate) {
      this.widgetPersonalLabelTemplate.is_multi_language = 1;
    }
  }

  ignore() {
  }

  setColorProperties(viewMode: string) {
    return {};
  }

  switchLanguage(language: string) {
    this.lang = language;
    this.translate.use(language);
    this.globals.user_selected_language = language;
  }
}
