import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {

  #token: string | null = null;

  setAuthToken(token: string): void {
    this.#token = token;
  }

  getAuthToken(): string | null {
    return this.#token;
  }
}
