export const customerValues: { key: string, values: { title?: string, value: string }[] } = {
  key: 'Customer',
  values: [
    {value: '[CUSTOMER_SALUTATION]'},
    {value: '[CUSTOMER_PRENAME]'},
    {value: '[CUSTOMER_LASTNAME]'},
    {value: '[CUSTOMER_EMAIL]'},
    {value: '[CUSTOMER_MOBILE]'},
    {value: '[CUSTOMER_ADDRESS]'},
    {value: '[CUSTOMER_STREET]'},
    {value: '[CUSTOMER_ZIP]'},
    {value: '[CUSTOMER_CITY]'},
    {value: '[CUSTOMER_LANGUAGE]'},
  ]
};

export const partnerValues: { key: string, values: { title?: string, value: string }[] } = {
  key: 'Partner',
  values: [
    {value: '[PARTNER_NAME]'},
    {value: '[PARTNER_PHONE]'},
  ]
};

export const storeValues = {
  STORE_NAME: '[STORE_NAME]',
  STORE_STREET: '[STORE_STREET]',
  STORE_ZIP: '[STORE_ZIP]',
  STORE_CITY: '[STORE_CITY]',
  STORE_LATITUDE: '[STORE_LATITUDE]',
  STORE_LONGITUDE: '[STORE_LONGITUDE]',
  STORE_EMAIL: '[STORE_EMAIL]',
  STORE_PHONE: '[STORE_PHONE]',
  STORE_PLACE: '[STORE_PLACE]',
};

export const appointmentValues: { key: string, values: { title?: string, value: string }[] } = {
  key: 'Appointment',
  values: [
    {value: '[SERVICES]'},
    {value: '[APPOINTMENT_HOURS]'},
    {value: '[APPOINTMENT_UUID]'},
    {value: '[APPOINTMENT_CANCEL_URL]'},
    {value: '[CONFERENCE_URL]'},
    {value: '[APPOINTMENT_REBOOK_REASON]'},
    {value: '[APPOINTMENT_CANCELLATION_REASON]'},
    {value: '[APPOINTMENT_CHARGED_PRICE]'},
    {value: '[APPOINTMENT_PRICE]'},
    {value: '[APPOINTMENT_APPLIED_COUPON]'},
    {value: '[APPOINTMENT_COUPON_REDUCTION_AMOUNT]'},
    {value: '[APPOINTMENT_PAYMENT_TYPE]'},
    {value: '[APPOINTMENT_PAYMENT_STATUS]'},
    {value: '[APPOINTMENT_SERVICE_ABBREVIATION]'},
    {value: '[DATE]'}, // start: d.m.y
    {value: '[START]'}, // start: H:i
    {value: '[FROM]'}, // start: H:i
    {value: '[TO]'}, // end: d.m.y H:i
    {value: '[END]'}, // end: H:i
  ]
};

export const eventValues: { key: string, values: { title?: string, value: string }[] } = {
  key: 'Event',
  values: [
    {value: '[EVENT_HOURS]'},
    {value: '[EVENT_TITLE]'},
    {value: '[EVENT_LOCATION]'},
    {value: '[EVENT_UUID]'},
    {value: '[EVENT_CANCEL_URL]'},
    {value: '[EVENT_REBOOK_REASON]'},
    {value: '[EVENT_CANCELLATION_REASON]'},
  ]
};

export const resourceValues: { key: string, values: { title?: string, value: string }[] } = {
  key: 'Resource',
  values: [
    {value: '[RESOURCE_NAME]'},
    {value: '[RESOURCE_USERNAME]'},
    {value: '[RESOURCE_BOOKING_LABEL]'},
    {value: '[RESOURCE_PRENAME]'},
    {value: '[RESOURCE_LASTNAME]'},
    {value: '[RESOURCE_MOBILE]'},
  ]
};
