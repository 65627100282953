import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TrustHtmlPipe } from '../../pipes/trust-html.pipe';
@Component({
  selector: 'app-youtube-modal',
  templateUrl: './youtube-modal.component.html',
  styleUrls: ['./youtube-modal.component.scss'],
  standalone: true,
  imports: [TrustHtmlPipe]
})
export class YoutubeModalComponent {

  youtubeUrl: string;

  constructor(
    public dialogRef: MatDialogRef<YoutubeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { youtubeUrl: string }
  ) {
    this.youtubeUrl = data.youtubeUrl;
  }

  close(): void {
    this.dialogRef.close();
  }

}
