import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { CaptchaDao } from '../db-models/captcha-db.model';


@Injectable({
  providedIn: 'root'
})
export class CaptchaService {

  constructor(
    private http: HttpClient
  ) { }

  generateCaptcha(): Observable<CaptchaDao> {
    const body = { store_id: 2, start: '2024-02-01 08:00:00', end: '2024-02-01 08:15:00' };
    return this.http.post<CaptchaDao>(`${environment.apiUrl}captcha/generate`, body);
  }

  validateCaptcha(uuid: string, solution: string): Observable<boolean> {
    const body = { uuid, solution };
    return this.http.post<{ success: boolean }>(`${environment.apiUrl}captcha/validate`, body).pipe(
      map(result => typeof result?.success === 'boolean' ? result.success : false)
    );
  }
}
