import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'eventToHour',
    standalone: true
})
export class EventReplaceHourPipe implements PipeTransform {
  transform(value: number, hourT?: any, minuteT?: any): string {
    const h = Math.floor(value / 60);
    const hDisplay = h;
    return hDisplay + '';
  }
}
