<div class="custom-control d-flex custom-checkbox">
  <input
    name="{{ name }}"
    id="{{ id }}"
    class="custom-control-input"
    type="checkbox"
    [disabled]="isDisabled"
    [(ngModel)]="fieldNgModel"
    [required]="isRequired"
    (change)="onChange($event, id)"
    #buttonField="ngModel"
    attr.role="checkbox"
    attr.aria-checked="{{ fieldNgModel }}"
    attr.aria-invalid="{{ buttonField.touched && buttonField.invalid }}"
    attr.aria-describedby="{{ buttonField.touched && buttonField.invalid ? 'error-cf-' + id : undefined }}"
    attr.aria-labelledby="{{ 'label_' + id }}"
  />

  <label
    id="label_{{ id }}"
    for="{{ id }}"
    class="custom-control-label d-flex w-100 {{ customLabelClass }}"
    [ngClass]="{ 'calio-red': buttonField.touched && buttonField.invalid }"
  >
    <ng-content />
  </label>
</div>

@if (buttonField.touched && buttonField.invalid) {
  <div  id="{{ 'error-cf-' + id }}" role="alert" class="alert alert-danger summary-warning">
    @if (buttonField.errors.required) {
      {{ 'appointments.step4.customFields.error.required' | translate }}
    }
  </div>
}
