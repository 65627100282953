import { AsyncPipe, DatePipe, NgClass, NgStyle, SlicePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarCommonModule, CalendarMonthModule, CalendarMonthViewDay } from 'angular-calendar';
import { delay, of, switchMap } from 'rxjs';
import { APPOINTMENT_CONSTANT } from '../../../constants/appointment-constants';
import { UserDao } from '../../../db-models/user-data-dao';
import { Partner } from '../../../models/global';
import { WidgetColorConf } from '../../../models/widget-color.model';
import { CustomEventService } from '../../../services/custom-event.service';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';

@Component({
    selector: 'app-new-calendar-picker',
    templateUrl: './new-calendar-picker.component.html',
    styleUrls: ['./new-calendar-picker.component.scss'],
    standalone: true,
    imports: [NgClass, CalendarCommonModule, FontAwesomeModule, CalendarMonthModule, LoaderComponent, NgStyle, AsyncPipe, SlicePipe, DatePipe, TranslateModule]
})
export class NewCalendarPickerComponent implements OnInit {

  @Input() noFreeDates: boolean;
  @Input() disablePrev: boolean;
  @Input() viewDate: Date;
  @Input() excludeWeekDays: number[];
  @Input() calendarLoaded = false;
  @Input() days: CalendarMonthViewDay[];
  @Input() widgetColorConf?: WidgetColorConf;
  @Input() worker: UserDao;
  @Input() maxCalendarAvailabilityDate: Date;
  @Input() lang: string;
  @Input() calendarSlotsCountList?: {
    date: Date,
    slotsCount: number
  }[] = [];
  @Input() partner: Partner;
  @Input() calendarPreselectedDate: string;

  @Output() viewDateChangedEvent: EventEmitter<any> = new EventEmitter();
  @Output() onDateSelectEvent: EventEmitter<any> = new EventEmitter();
  @Output() loadCalendarWithAvailabilitiesEvent: EventEmitter<any> = new EventEmitter();

  readonly appointmentConstant = APPOINTMENT_CONSTANT;
  disableNext = false;
  preselectedEventTriggered = false;
  weekStartsOn = 1;

  constructor(
    public cdref: ChangeDetectorRef,
    private customEventService: CustomEventService
  ) { }

  ngOnInit(): void {
    this.customEventService.disableCalendarNextButtonEvent.subscribe({
      next: (isDisableNextButton: boolean) => {
        setTimeout(() => {
          this.disableNext = isDisableNextButton;
        }, 500);
      }
    });

    this.validateMaxDate(this.viewDate);
  }

  validateMaxDate(viewDate: Date) {
    this.disableNext = viewDate.getMonth() == this.maxCalendarAvailabilityDate.getMonth() && viewDate.getFullYear() == this.maxCalendarAvailabilityDate.getFullYear();
  }

  viewDateChanged(viewDate: Date, isNext: boolean) {
    this.validateMaxDate(viewDate);
    this.viewDate = viewDate;
    this.viewDateChangedEvent.emit({ viewDate: viewDate, isNext: isNext });
  }

  onDateSelect(day: any) {
    if (day.day.inMonth) {
      this.onDateSelectEvent.emit(day);
    }
  }

  loadCalendarWithAvailabilities(body: any) {
    this.loadCalendarWithAvailabilitiesEvent.emit(body);
  }

  getColorOfDay(day: any): any {
    let calendarPreselectedDate: Date;
    let hasAlreadySelected = false;

    if (this.calendarPreselectedDate) {
      calendarPreselectedDate = new Date(`${this.calendarPreselectedDate} 00:00:00`);
    }

    for (const calendarSlotsCount of this.calendarSlotsCountList) {
      if (day.date.toString() === calendarSlotsCount.date.toString()) {
        if (calendarSlotsCount.slotsCount && calendarSlotsCount.slotsCount > 0) {
          hasAlreadySelected = true;
          day.cssClass = 'cal-selected';
          day.badgeTotal = calendarSlotsCount.slotsCount;
        }
      }
    }

    if (this.calendarLoaded && hasAlreadySelected === false && calendarPreselectedDate) {
      if (day.date.getTime() === calendarPreselectedDate.getTime()) {
        if (
          day.isWeekend === false &&
          day.isPast === false &&
          !this.preselectedEventTriggered
        ) {
          this.preselectedEventTriggered = true;
          day.cssClass = 'cal-selected';
          this.onDateSelect({ day });
        }
      }
    }

    if (day.cssClass === 'cal-selected') {
      return of({
        'color': 'white',
        'background-color': this.widgetColorConf.widget_header_active_color
      }).pipe(delay(500), switchMap((value: any) => of(value)));
    } else {
      if (day.isWeekend) {
        return of({
          'color': ''
        }).pipe(delay(500), switchMap((value: any) => of(value)));
      } else {
        return of({
          'color': this.widgetColorConf.widget_text_color
        }).pipe(delay(500), switchMap((value: any) => of(value)));
      }
    }
  }

  ngAfterViewInit(): void {
    this.cdref.detectChanges();
  }
}
