import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { APPOINTMENT_CONSTANT } from '../../../../constants/appointment-constants';
import { TRANSLATION_TEMPLATES } from '../../../../constants/translation-templates-constants';
import {
  PARENT_TO_CHILD_IFRAME_EVENTS, PARENT_TO_CHILD_IFRAME_EVENT_ACTIONS, PARENT_TO_CHILD_IFRAME_VALIDATION_CONTROL_NAME
} from '../../../../constants/widget-constants';
import { CustomBookingMessageTemplate } from '../../../../db-models/widget-conf-dao';
import { CustomerProfile, Partner } from '../../../../models/global';
import { AppointmentState } from '../../../../models/state.model';
import { WidgetColorConf } from '../../../../models/widget-color.model';
import { CustomEventService } from '../../../../services/custom-event.service';
import { WidgetUtilService } from '../../../../services/widget-util.service';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';
import { CwCardComponent } from '../../../common/theme/cw-card/cw-card.component';

@Component({
  selector: 'app-appointment-customer-profiles',
  templateUrl: './appointment-customer-profiles.component.html',
  styleUrls: ['./appointment-customer-profiles.component.scss'],
  standalone: true,
  imports: [NgClass, CwCardComponent, FontAwesomeModule, ButtonComponent, TranslationPipe]
})
export class AppointmentCustomerProfilesComponent implements OnInit {

  @Input() widgetColorConf: WidgetColorConf;
  @Input() appointmentState: AppointmentState;
  @Input() partner: Partner;
  @Input() lang: string;
  @Input() nextButtonTemplate: CustomBookingMessageTemplate;

  @Output() navigateToEvent = new EventEmitter<any>();

  readonly appointmentConstant = APPOINTMENT_CONSTANT;
  readonly parentToChildIframeEventActions = PARENT_TO_CHILD_IFRAME_EVENT_ACTIONS;
  readonly parentToChildIframeEvents = PARENT_TO_CHILD_IFRAME_EVENTS;
  readonly parentToChildIframeValidationControlName = PARENT_TO_CHILD_IFRAME_VALIDATION_CONTROL_NAME;
  readonly templateContent = TRANSLATION_TEMPLATES;

  constructor(
    private widgetUtilService: WidgetUtilService,
    private customEventService: CustomEventService
  ) { }

  ngOnInit(): void {
    this.customEventService.iFrameNavigationFromParent.subscribe({
      next: (action: string) => {
        if (
          action === this.parentToChildIframeEventActions.NEXT &&
          this.appointmentState.customerProfileId
        ) {
          this.navigateToEvent.next({ page: this.appointmentConstant.APPOINTMENT });
        } else if (!this.appointmentState.customerProfileId) {
          this.widgetUtilService.sendMessageToParent({
            eventName: this.parentToChildIframeEvents.VALIDATION,
            controls: [
              this.parentToChildIframeValidationControlName.CUSTOMER_PROFILE
            ]
          });
        }
      }
    });
  }

  onSelectCustomerProfile(customerProfile: CustomerProfile) {
    this.appointmentState.customerProfileId = customerProfile.id;
    this.widgetUtilService.customerProfile = customerProfile;
  }

  navigateTo(page: string) {
    this.navigateToEvent.next({ page: page });
  }
}
