@if (isWordBreakEnabled) {
  <div class="multilang-title {{ titleClass }}">
    @if (item?.is_multi_language === 1) {
      {{ item | translation: 'name': lang }}
    }
    @if (item?.is_multi_language === 0) {
      {{ item?.name }}
    }
    @if (showBadge) {
      <span class="badge badge-primary ms-1">
        {{ badgeLabel }}
      </span>
    }
  </div>
}

@if (!isWordBreakEnabled) {
  <div class="multilang-title {{ titleClass }}">
    @if (item?.is_multi_language === 1) {
      {{ item | translation: 'name': lang }}
    }
    @if (item?.is_multi_language === 0) {
      {{ item?.name }}
    }
    @if (showBadge) {
      <span class="badge badge-primary ms-1">
        {{ badgeLabel }}
      </span>
    }
  </div>
}
