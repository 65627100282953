<app-cw-new-card [showHeader]="true" [showBody]="true">
  <div header>
    <app-cw-card-header
      [title]="templateTitle | translation: templateContent.TEMPLATE_CONTENT: lang"
      [subtitle]="templateSubTitle | translation: templateContent.TEMPLATE_CONTENT: lang"
    />
  </div>

  <div body class="my-4 appointment-body">
    @if (cart.length <= 0) {
      <div>
        <div class="no-data">{{ 'appointments.step4.cart.emptyCartWarning' | translate }}</div>
      </div>
    }

    @if (cart.length > 0) {
      <div>
      @for (cartItem of cart; track cartItem; let i = $index; let last = $last) {
        <div class="row cw-cart-row">
        @if (getServicesByIds({serviceIds: cartItem.servicesIds}); as cartServices;) {
          <div class="col-lg-12">
            <!-- Service Name -->
            <div class="d-flex align-items-center padding-left-15px">
              <div class="service">
                <ngx-clamp row="3">
                  <div class="name" #content>
                  @for (service of cartServices; track service; let index = $index) {
                    <span class="cart">
                      <span>
                        @if (service?.is_multi_language === 1) {
                          {{ service | translation: 'name': lang }}
                        }
                        @if (service?.is_multi_language === 0) {
                          {{ service?.name }}
                        }
                      </span>
                      @if (index + 1 < cartServices.length) {
                        <span>, </span>
                      }
                    </span>
                  }
                  </div>
                </ngx-clamp>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <!-- Service Name -->
            <div class="appointment mt-1 padding-left-15px" [ngClass]="{ 'appointment-padding': cart.length !== 1 }">
              <span class="cbw-summary-date-time">
                {{ cartItem.date | date: 'dd.MM.y' }},
                {{ cartItem.startTime | dateutc: "HH:mm" }} - {{ cartItem.endTime | dateutc: "HH:mm" }}
                @if (cartItem?.location) {
                  <span>&nbsp;({{ cartItem?.location }})</span>
                }
              </span>
              @if (
                  userState.meetingType &&
                  (widgetTemplates | calioMeetingTemplate: {
                    id: userState.meetingType.id,
                    type: 'meeting_template'
                  }); as widgetTemplate) {

                <div
                  class="cbw-summary-meeting-type"
                  [innerHTML]="(widgetTemplate.titleTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml"
                ></div>
                @if (userState.meetingType.id === 1 && cartItem) {
                  <div class="cbw-summary-worker-store-location">
                    <span class="cbw-summary-worker-store-location-name">{{ cartItem.store | translation: 'name' : lang }}</span>
                    @if (cartItem.store.is_online === 0) {
                      <span class="cbw-summary-worker-store-location-separator">,&nbsp;</span>
                      <span class="cbw-summary-worker-store-location-address">
                        @if (cartItem.store.street) {
                          <span>{{ cartItem.store.street }}</span>
                        }
                        @if (cartItem.store.zip) {
                          <span>,&nbsp;{{ cartItem.store.zip }}</span>
                        }
                        @if (cartItem.store.city) {
                          <span>&nbsp;{{ cartItem.store.city }}</span>
                        }
                        @if (cartItem.store?.country) {
                          <span>,&nbsp;{{ cartItem.store.country | translation: 'name' : lang }}</span>
                        }
                      </span>
                    }
                  </div>
                }
              } @else {
                @if (userState?.meetingType?.id) {
                <div
                  [innerHTML]="(userState.meetingType | translation: 'name' : lang) | trustHtml"
                ></div>
              }
              }
            </div>

            <div class="padding-left-15px mt-1 d-none d-lg-block">
              <a class="remove-link" href="javascript:void(0)"
                (click)="removeFromCart(cartItem.cartItemId, cartItem.relatedAppointmentCardId)"
              >
                {{ 'buttons.remove' | translate }}
              </a>
            </div>
          </div>

          <div class="col-lg-4">
            @if (partner.hide_mail_resource !== 1 && conferenceState?.uuid === undefined) {
              <div class="worker-name padding-left-15px">
                {{ cartItem.workerName }}
              </div>
            }
            <div class="padding-left-15px mt-1 d-none d-md-block d-lg-none">
              <a class="remove-link" href="javascript:void(0)"
                 (click)="removeFromCart( cartItem.cartItemId, cartItem.relatedAppointmentCardId )">
                {{ 'buttons.remove' | translate }}
              </a>
            </div>
          </div>

          <div class="col-lg-2">
            <div class="no-wrap cw-price text-md-end padding-left-15px">
            @if ((cartItem.total.price / cartServices?.length) > 0) {
              <span>
                @if (cartItem.total.isPriceFromActivated) {
                  <span>
                  {{ 'common.price_from_label' | translate }}
                  </span>
                }
                {{ cartItem.total.price | calioCurrency: partner['currency'] }}
              </span>
            }
              @if ((cartItem.total.price / cartServices?.length) === 0) {
                <span>
                  {{ 'free' | translate }}
                </span>
              }
              @if ((cartItem.total.price / cartServices?.length) === -1) {
                <span> &nbsp; </span>
              }
            </div>
          </div>

          <div class="col-lg-12 d-block d-md-none">
            <div class="padding-left-15px mt-1">
              <a class="remove-link" href="javascript:void(0)"
              (click)="removeFromCart( cartItem.cartItemId,cartItem.relatedAppointmentCardId )">
                {{ 'buttons.remove' | translate }}
              </a>
            </div>
          </div>

          @if ((finalCouponDiscount > 0 || finalTotal !== 0 && cart && cart.length > 0) || (cart && cart.length > 1 && !last)) {
           <hr class="card-divider w-100">
          }
        }
      </div>
    }

      @for (relatedAppointmentService of relatedAppointmentServices; track relatedAppointmentService; let i = $index; let last = $last) {
        @if (!relatedAppointmentService?.isAddedToCart) {
          <div class="row cw-cart-row mt-3">
              <div class="col-lg-6 service">
                <div
                  class="appointment padding-left-15px"
                  [innerHTML]="(
                          (widgetTemplates | calioMeetingTemplate: { id: 212, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang
                            : [{
                              key: '[SERVICE_NAME]',
                              value: (relatedAppointmentService?.originalAppointmentService | translation: 'name': lang)
                            },
                            {
                              key: '[RELATED_SERVICE_NAME]',
                              value: (relatedAppointmentService?.relatedServiceData | translation: 'name': lang)
                            }]
                        ) | trustHtml"
                >
              </div>
              </div>
              <div class="col-lg-6 mt-2 mt-lg-0">
                @for (afterDateFreeAppointment of relatedAppointmentService?.allDates; track afterDateFreeAppointment) {
                  <div class="appointment d-flex align-items-center padding-left-15px mb-1">
                    <span class="me-1">
                      <span>
                      {{ afterDateFreeAppointment.start | date:'dd.MM.y,' }}&nbsp;
                      {{ afterDateFreeAppointment.start | dateutc:timeFormat }} -
                      {{ afterDateFreeAppointment.end | dateutc:timeFormat }}
                      </span>
                      (<span class="">{{ afterDateFreeAppointment?.worker?.resource_name }}</span>)
                    </span>
                    (<a
                      href="javascript:void(0)"
                      class="remove-link"
                      (click)="onSelectSlot(relatedAppointmentService, afterDateFreeAppointment)"
                    >
                    {{ 'add' | translate }}
                    </a>)
                  </div>
                }
              </div>
               <hr class="card-divider w-100">
            </div>
          }
        }

      @if (finalCouponDiscount > 0 || finalTotal !== 0 && cart && cart.length > 0) {
        <div class="row cw-cart-row">
          <div class="col-lg-6 col-md-6"></div>
            <div class="col-lg-6 col-md-6">
              <div class="d-flex justify-content-start justify-content-md-end">
                <div class="row total-row cw-cart-row padding-left-15px">
                  <div class="col-lg-7 col-md-7 bold-field-label">
                    {{ 'summary_page_translations.subtotal' | translate }}
                  </div>
                  <div class="col-lg-5 col-md-5 text-md-end bold-field-label padding-left-15px">
                    <span>
                      @if (totalCartPrice > 0) {
                      {{ totalCartPrice | calioCurrency: partner['currency'] }}
                      }
                      @if (totalCartPrice  === 0) {
                        {{ 'free' | translate }}
                      }
                      @if (totalCartPrice === -1) {
                        &nbsp;
                      }
                    </span>
                 </div>
                </div>
              </div>

          @if (coupon) {
            <div class="d-flex justify-content-start justify-content-md-end mt-2">
              <div class="row total-row cw-cart-row padding-left-15px">
                <div class="col-lg-7 col-md-7 normal-field-label">
                  {{ 'coupon_credit_card_info.coupon' | translate }} ({{ coupon.code }})
                </div>
                <div class="col-lg-5 col-md-5 text-md-end normal-field-label padding-left-15px">
                  <span class="">
                    <!-- - {{ partner['currency'] }} {{ finalCouponDiscount | amountFormat }} -->
                    - {{ finalCouponDiscount | calioCurrency: partner['currency'] }}
                  </span>
                </div>
              </div>
            </div>
          }

          <div class="d-flex justify-content-start justify-content-md-end">
            <div class="row total-row cw-cart-row padding-left-15px">
              <hr class="card-divider w-100">
              <div class="col-lg-7 col-md-7 bold-field-label">
                {{ 'appointments.step4.total' | translate }}
              </div>
              <div class="col-lg-5 col-md-5 text-md-end bold-field-label padding-left-15px">
                @if (!finalCouponDiscount) {
                <span>
                  {{ finalTotal | calioCurrency: partner['currency'] }}
                </span>
}
                @if (finalCouponDiscount && finalCouponDiscount < totalCartPrice) {
                <span   >
                  {{ finalTotal | calioCurrency: partner['currency'] }}
                </span>
}
                @if (finalCouponDiscount && finalCouponDiscount >= totalCartPrice) {
                <span class="">
                  {{ 'free' | translate }}
                </span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    </div>
  }
  </div>
</app-cw-new-card>
