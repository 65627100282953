import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

@Pipe({
    name: 'calioCurrency',
    standalone: true
})
export class CalioCurrencyPipe implements PipeTransform {

  constructor(
    private currencyPipe: CurrencyPipe,
    private deviceDetectorService: DeviceDetectorService,
  ) {

  }

  /**
   *  {{ appointment?.price | currency: appointment?.worker?.partner?.currency:'code': '1.2-2': (appointment?.worker?.partner?.currency | uppercase) === 'EUR' ? 'de' : 'ch' }}
   * @param value
   * @param currencyCode
   */
  transform(value: any, currencyCode: string): any {

    const isMobile: boolean = this.deviceDetectorService.isMobile();

    let digitInfo: string = '1.2-2';
    if (isMobile) {
      digitInfo = '1.2-2';
    }

    const finalValue = this.transformCurrency(
      value,
      currencyCode,
      'code',
      digitInfo,
      this.getCurrencyLocale(currencyCode)
    );

    return finalValue;
  }

  transformCurrency(
    value: any,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): any {
    return this.currencyPipe.transform(
      value,
      currencyCode,
      display,
      digitsInfo,
      locale,
    );
  }

  getCurrencyLocale(currencyCode: string): string {
    switch (currencyCode.toUpperCase()) {
      case 'EUR':
        return 'de';
      case 'CHF':
        return 'ch';
      default:
        return 'ch';
    }
  }

}
