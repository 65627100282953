import { Pipe, PipeTransform } from '@angular/core';
import { AppointmentServiceCategoryDao, AppointmentServiceDao } from '../../db-models/appointment-service-dao';

@Pipe({
    name: 'categoryFilter',
    standalone: true
})
export class CategoryFilterPipe implements PipeTransform {

  transform(values: {
    category: string,
    _translations: any,
    is_multi_language: number,
    appointmentServiceCategory: AppointmentServiceCategoryDao,
    aServicesOfCat: AppointmentServiceDao[],
    position: number
  }[], searchServiceQuery: string): any {
    values = Object.assign([], values);
    if (searchServiceQuery && values?.length) {
      values = values.filter(value => {
        const services = value.aServicesOfCat.filter(appointment => {
          const name = appointment.name ? appointment.name.toLowerCase() : undefined;
          const description = appointment.description ? appointment.description.toLowerCase() : undefined;

          if (name && description) {
            return (name.indexOf(searchServiceQuery.toLowerCase()) > -1 || description.indexOf(searchServiceQuery.toLowerCase()) > -1);
          } else if (name || description) {
            if (name) {
              return name.indexOf(searchServiceQuery.toLowerCase()) > -1;
            }

            if (description) {
              return description.indexOf(searchServiceQuery.toLowerCase()) > -1;
            }
          } else {
            return false;
          }
        });

        if (services?.length) {
          value.aServicesOfCat = services;
          return true;
        } else {
          return false;
        }
      });

      return values;
    } else {
      return values;
    }
  }
}
