import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../../services/helper.service';
import { environment } from '../../../../environments/environment';
import { NgClass } from '@angular/common';
@Component({
  selector: 'app-new-alert',
  templateUrl: './new-alert.component.html',
  styleUrls: ['./new-alert.component.scss'],
  standalone: true,
  imports: [NgClass]
})
export class NewAlertComponent implements OnInit {
  @Input() type: string;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() date: Date;
  @Input() customClass: string;

  environment = environment;
  dateAgo: string;

  constructor(
    public translate: TranslateService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    if (this.date) {
      this.dateAgo = this.helperService.dateAgoUtility(this.date?.toDateString());
    }
  }
}
