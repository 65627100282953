import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { TIME_FORMAT } from '../../../../constants/date.constants';
import { TRANSLATION_TEMPLATES } from '../../../../constants/translation-templates-constants';
import { AppointmentsSlotsDao } from '../../../../db-models/free-appointment-dao';
import { WidgetAppointmentGroupModel } from '../../../../db-models/widget-appointment-group.dao';
import { CustomBookingMessageTemplate } from '../../../../db-models/widget-conf-dao';
import { CartItem } from '../../../../models/cart.model';
import { AppointmentState } from '../../../../models/state.model';
import { AppointmentService } from '../../../../services/appointment.service';
import { CalioMeetingTemplatePipe } from '../../../../shared/pipes/calio-meeting-template.pipe';
import { DateUtcPipe } from '../../../../shared/pipes/date_utc.pipe';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';

@Component({
  selector: 'app-compact-slots',
  templateUrl: './compact-slots.component.html',
  styleUrls: ['./compact-slots.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule, DatePipe, DateUtcPipe, TranslateModule, TranslationPipe, CalioMeetingTemplatePipe]
})
export class CompactSlotsComponent {

  @Input() appointments: {
    dayPeriod: string;
    appointments: AppointmentsSlotsDao[];
    showDayPeriodLabel?: boolean;
    widgetGroup: WidgetAppointmentGroupModel;
  };
  @Input() lang: string;
  @Input() noAppointments = false;
  @Input() showAppointmentSlotGhostElement = false;
  @Input() cart: CartItem[];
  @Input() widgetTemplates: CustomBookingMessageTemplate[] = [];
  @Input() appointmentState: AppointmentState = {
    customerProfileId: null,
    store: null,
    categories: [],
    services: [],
    relatedServices: [],
    worker: null,
    date: null,
    termins: null,
    day: null,
    default_worker_id: null,
    leadGeneratorPostcode: null,
    default_worker: null
  };

  @Output() goBackEvent = new EventEmitter<any>();
  @Output() updateCartEvent = new EventEmitter<any>();

  readonly timeFormat = TIME_FORMAT;
  readonly templateContent = TRANSLATION_TEMPLATES;

  constructor(
    private appointmentService: AppointmentService
  ) {
  }

  isTerminInCart(cartItemId: string): boolean {
    if (this.cart != null) {
      const exist = this.cart.find(appointment => appointment.cartItemId === cartItemId);
      return exist ? true : false;
    }
    return false;
  }

  createCartItemId(workerId: number, selectedDay: Date, shortStart: string): string {
    return this.appointmentService.createCartItemId(workerId, selectedDay, Number(shortStart));
  }

  updateCart(appointment: any): void {
    this.updateCartEvent.emit({ appointment });
  }

  goBack(): void {
    this.goBackEvent.emit();
  }
}
