import { Component, OnInit, Input } from '@angular/core';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';


@Component({
    selector: 'app-cw-multi-lang-title',
    templateUrl: './cw-multi-lang-title.component.html',
    styleUrls: ['./cw-multi-lang-title.component.scss'],
    standalone: true,
    imports: [TranslationPipe],
})
export class CwMultiLangTitleComponent implements OnInit {

  @Input() item: any;

  @Input() lang: string;

  @Input() wordBreakLength: number = Number(50);

  @Input() titleClass?: string = '';

  @Input() isWordBreakEnabled?: boolean = true;

  @Input() showBadge: boolean = false;

  @Input() badgeLabel: string;

  constructor() { }

  ngOnInit() {
  }

}
