export class CountryDbModel {
  id: number;
  name: string;
  supported: number;
  identifier: string;
  is_multi_language: number;
  identifier_short: string;
  [key: string]: any;
  _translations?: {
    [key: string]: CountryTranslation
  };
}

export class CountryTranslation {
  name: string;
  locale: string;
}
