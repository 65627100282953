import { NgClass, NgTemplateOutlet, SlicePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CustomBookingMessageTemplate } from '../../../../../app/db-models/widget-conf-dao';
import { environment } from '../../../../../environments/environment';
import { DATE_ISO_FORMAT, DATE_PIPE_WITH_COMMA, DATE_TIME_FORMAT, TIME_FORMAT } from '../../../../constants/date.constants';
import { EVENT_CONSTANT } from '../../../../constants/event-constants';
import { TRANSLATION_TEMPLATES } from '../../../../constants/translation-templates-constants';
import { EventDateDao } from '../../../../db-models/event-date-dao';
import { EventCartItem } from '../../../../models/cart.model';
import { EventState } from '../../../../models/state.model';
import { WidgetUtilService } from '../../../../services/widget-util.service';
import { AlertComponent } from '../../../../shared/components/alert/alert.component';
import { LoaderComponent } from '../../../../shared/components/loader/loader.component';
import { CalioMeetingTemplatePipe } from '../../../../shared/pipes/calio-meeting-template.pipe';
import { DateUtcPipe } from '../../../../shared/pipes/date_utc.pipe';
import { IsEmptyStringPipe } from '../../../../shared/pipes/is-empty-string.pipe';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';
import { TrustHtmlPipe } from '../../../../shared/pipes/trust-html.pipe';
import { CwCheckboxRadioButtonComponent } from '../../../common/theme/cw-checkbox-radio-button/cw-checkbox-radio-button.component';

@Component({
  selector: 'app-event-slot',
  templateUrl: './event-slot.component.html',
  styleUrls: ['./event-slot.component.scss'],
  standalone: true,
  imports: [LoaderComponent, AlertComponent, NgClass, CwCheckboxRadioButtonComponent, FontAwesomeModule, SlicePipe, DateUtcPipe, TranslateModule, TranslationPipe, TrustHtmlPipe, CalioMeetingTemplatePipe, IsEmptyStringPipe, NgTemplateOutlet]
})
export class EventSlotComponent {

  @Input() eventState: EventState;
  @Input() terminLoaded: Number;
  @Input() eventOnDate: EventDateDao[];
  @Input() bookedItems: EventCartItem[];
  @Input() cart: EventCartItem[];
  @Input() noEvents: Boolean;
  @Input() widgetTemplates: CustomBookingMessageTemplate[] = [];
  @Input() lang: string;

  @Output() updateCartEvent = new EventEmitter<any>();
  @Output() gotoNextPageEvent = new EventEmitter<any>();

  private translate = inject(TranslateService);
  private widgetUtilService = inject(WidgetUtilService);

  readonly eventConstants = EVENT_CONSTANT;
  readonly dateTimeFormat = DATE_TIME_FORMAT;
  readonly datePipeWithComma = DATE_PIPE_WITH_COMMA;
  readonly timeFormat = TIME_FORMAT;
  readonly dateIsoFormat = DATE_ISO_FORMAT;
  readonly environment = environment;
  readonly templateContent = TRANSLATION_TEMPLATES;

  hideEventCapacity = this.widgetUtilService.getWidgetConf().hide_event_capacity;
  showWidgetSlotLocation = this.widgetUtilService.getWidgetConf().show_widget_slot_location;
  loadMoreSlots = false;

  constructor() {
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
  }

  displayRegularData(event: EventDateDao): boolean {
    return (event.regular_booking_count < event.regular_capacity);
  }

  displayWaitingData(event: EventDateDao): boolean {
    return (event.waitinglist_booking_count < event.waitinglist_capacity);
  }

  isSlotBooked(slotId: number): boolean {
    for (const i in this.bookedItems) {
      if (this.bookedItems[i]['slotId'] === slotId) {
        return true;
      }
    }
    return false;
  }

  isTerminInCart(slotId: number): boolean {
    if (this.cart != null) {
      for (let i = this.cart.length - 1; i >= 0; i--) {
        if (this.cart[i].slotId === slotId) {
          return true;
        }
      }
    }
    return false;
  }

  updateCart(event: any, eventInfo: EventDateDao) {
    if (!this.displayRegularData(eventInfo)) {
      if (this.displayWaitingData(eventInfo)) {
        eventInfo.isWaitingList = true;
      }
    }

    this.updateCartEvent.emit({ event, eventInfo });
  }

  isWaitingAlertEnabled(): boolean {
    if (this.terminLoaded) {
      if (this.eventOnDate && this.eventOnDate.length > 0) {
        for (const eventSlot of this.eventOnDate) {
          if (eventSlot.regular_booking_count >= eventSlot.regular_capacity) {
            if (eventSlot.waitinglist_booking_count < eventSlot.waitinglist_capacity) {
              return true;
            }
          }
        }
        return false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  gotoNextPage(): void {
    this.gotoNextPageEvent.emit();
  }

  checkIfStartEndDatesAreEqual(startDate: Date, endDate: Date): boolean {
    return moment(moment(startDate).format(this.dateIsoFormat)).isSame(moment(moment(endDate).format(this.dateIsoFormat)));
  }

  loadMore(): void {
    this.loadMoreSlots = true;
  }
}
