export const sv_SE = {
  customer_type: 'Kundtyp',
  manage_appointment_now: 'Hantera möte nu',
  maintenance_page_title: 'Calenso förbättras just för dig',
  maintenance_page_subtitle: 'Calenso uppdateras till senaste versionen. Var god ha tålamod och kontrollera igen om några minuter.',
  maintenance_page_button: 'Till statussidan',
  load_more: 'Ladda mer',
  show_more: 'Visa mer',
  internal_label: 'Intern',
  check_availability_for_anyone: 'Kolla tillgängligheten för någon',
  store_not_found: 'Ingen butik hittades',
  appointment: 'Möten',
  event: 'Gruppmöten',
  resource: 'Resurser',
  morgen: 'morgon',
  vormittag: 'förmiddag',
  mittag: 'middag',
  nachmittag: 'eftermiddag',
  abend: 'kväll',
  nacht: 'natt',
  free: 'ledig',
  hoursShort: '',
  minutesShort: 'm',
  mondayLong: 'Måndag',
  day1: 'MÅ',
  tuesdayLong: 'Tisdag',
  day2: 'TI',
  wednesdayLong: 'Onsdag',
  day3: 'ON',
  thursdayLong: 'Torsdag',
  day4: 'TO',
  fridayLong: 'Fredag',
  day5: 'FR',
  saturdayLong: 'Lördag',
  day6: 'LÖ',
  sundayLong: 'Söndag',
  day0: 'SÖ',
  Januar: 'Januari',
  Februar: 'Februari',
  März: 'Mars',
  April: 'April',
  Mai: 'Maj',
  Juni: 'Juni',
  Juli: 'Juli',
  August: 'Augusti',
  September: 'September',
  Oktober: 'Oktober',
  November: 'November',
  Dezember: 'December',
  "shortMonths": {
    "Januar": "Jan",
    "Februar": "Feb",
    "März": "Mar",
    "April": "Apr",
    "Mai": "Maj",
    "Juni": "Jun",
    "Juli": "Jul",
    "August": "Aug",
    "September": "Sep",
    "Oktober": "Okt",
    "November": "Nov",
    "Dezember": "Dec"
  },
  du: 'du',
  sie: 'Ni',
  havingFormDu: 'har',
  havingFormSie: 'har',
  confirm: {
    title: 'Bekräfta beställning',
    confirmationText: 'Du har {{ cartLength }} möten i din varukorg. Vill du verkligen boka alla nu?',
    confirmationTextSie: 'Du har {{ cartLength }} möten i din varukorg. Vill du verkligen boka alla nu?',
    ok: 'Boka',
    cancel: 'Avbryt'
  },
  internal: {
    search_customer: 'Sök befintlig kund och fyll i formulär',
    selectPlaceholder: 'Sök kund',
    customerDetails: 'Kunddetaljer',
    noCustomer: 'Det finns inga konfigurerade kunder',
    create_customer: 'Skapa Kund'
  },
  common: {
    report_bug: 'Rapportera fel',
    subscription_error_title: 'Ingen prenumeration!',
    subscription_error_desc: 'Tyvärr kunde ingen prenumeration hittas. Kontakta Calenso Support.',
    next: 'Nästa',
    previous: 'Föregående',
    reset: 'Återställ applikation',
    resetSuccess: 'Bokningsprogramvaran har återställts framgångsrikt. Data som lagrades tillfälligt är nu borta.',
    loading: 'Laddar ...',
    durationHour: 't',
    durationMinute: 'm',
    clock: 't',
    disclaimer: 'Denna tjänst tillhandahålls av <a href=\'https://www.calenso.com\' class=\'cal-link\' target=\'_blank\'>Calenso</a>.',
    book: 'Boka',
    choseBookType: 'Vad skulle du vilja boka?',
    company: 'Företag',
    prename: 'Förnamn',
    lastname: 'Efternamn',
    email: 'E-post',
    phone: 'Telefon',
    address: 'Adress',
    createAccount: 'Skapa kundkonto (användarkonto med alla registreringar)',
    noDateError: 'Den önskade tiden bokades av någon annan, gå tillbaka och välj en ny tid.',
    devNotice: 'Bokningswidgeten är ansluten till DEV-baksystemet. Var försiktig!',
    store_access_warning: 'Åtkomst till webbläsarens minne är begränsad på grund av dina integritetsinställningar. Inte alla funktioner i bokningswidgeten kanske är tillgängliga.',
    save: "Spara",
    cancel: "Avbryt",
    additionalParticipantsUpTo: "Du kan lägga till upp till {{ number }} gäster",
    additionalGuest: 'Ytterligare deltagare',
    addAdditionalGuest: 'Lägg till deltagare',
    additionalGuestCartLabel: '{{ number }} ytterligare gäst',
    additionalGuestsCartLabel: '{{ number }} ytterligare gäster',
    widget_disabled: 'Bokningswidgeten är för närvarande inaktiverad',
    widget_disabled_description: 'Bokningswidgeten är tillfälligt inaktiverad. Kontakta Calenso support (Felkod: PMT-01).',
    no_workers_widget_disabled_description: 'Inga möten kan för närvarande bokas eftersom ingen resurs är aktiverad.',
    workers_not_available: 'Ingen bokbar tjänst hittades',
    workers_not_available_description: 'Ingen bokbar tjänst registrerades. Se till att minst en tjänst är tillgänglig och tilldelad en bokbar resurs (förmåga). Att ange en tjänstkategori räcker inte för att vara bokbar.',
    price_from_label: 'från',
    close: 'Stäng',
    configuration_error: 'Konfigurationsfel',
    resource_is_not_bookable: 'Den valda resursen är inte bokbar.',
    verification_token_missing: 'Bokningswidgeten används i internt läge och kräver därför en verifieringstoken. Kontakta Calenso support.',
    invalid_verification_code: 'Åtkomst till widgeten i internt läge kräver en giltig token, se till att token är giltig och försök igen.',
    internal_disabled: 'Den interna bokningswidgeten är inaktiverad.',
    evnet_disabled_error: "Det begärda evenemanget är inaktiverat av partnern. Kontakta din administratör.",
    conference_invalid_link: "Det verkar som att den angivna konferenslänken är ogiltig. Kontrollera länken och försök igen.",
    conference_expired_link: "Vi är ledsna, men den här konferenslänken har gått ut. Kontakta arrangören för ytterligare hjälp.",
    conference_not_belonging: "Hoppsan, det verkar som att den här konferensen är kopplad till en annan partner.",
    conference_booked: "Hoppsan, det verkar som att den här konferenslänken redan har använts och bokats. Om du har några frågor eller behöver hjälp, tveka inte att kontakta arrangörerna.",
    conference_invalid_configurations: "Konferensen är inte tillgänglig på grund av ogiltiga interna konfigurationer. För ytterligare hjälp, vänligen kontakta organisatören.",
    location: "Plats"
  },
  appointments: {
    step1: {
      distance_from_store: '{{ distance }} km bort',
      search_store_postcode_helper_text: 'Ange postnummer för att söka efter närmaste butik',
      search_services_helper_text: 'Ange en sökterm för att söka efter tjänster',
      title: 'Tjänster',
      chooseStore: 'Välj butik',
      chooseService: 'Välj tjänst',
      nextButton: 'Nästa',
      search_store_by_postcode_placeholder: 'Sätt in postnummer för att söka efter rätt butik',
      create_lead: 'Möjlighet MH',
      expert_cap_button_label: 'Tilldela till en expert CAP',
      appointment_booking_not_available_for_zipcode: 'Tyvärr är bokning av möten inte tillgänglig för detta postnummer. Skapa en ny kund så att en CAP kan kontakta dem per telefon.'
    },
    step2: {
      title: 'Resurs',
      chooseEmployee: 'Välj en ledig resurs',
      name: 'Namn',
      function: 'Funktion',
      noSpecificWorker: 'Vem som helst',
      noSpecificFunction: 'Alla funktioner',
      nextButton: 'Välj datum/tid'
    },
    step3: {
      title: 'Datum',
      chooseDate: 'Välj föredragna datum',
      morning: 'Morgon',
      forenoon: 'Förmiddag',
      noon: 'Mitt på dagen',
      afternoon: 'Eftermiddag',
      evening: 'Kväll',
      night: 'Natt',
      infoMsg: 'Observera att det är möjligt att välja flera möten. Välj bara de möten du gillar via kryssruta. Mötena läggs sedan till i kundvagnen.',
      appointment: 'Möte',
      employee: 'Resurs',
      proposals: 'Fria möten',
      noFreeAppointments: 'Tyvärr finns det inga lediga möten den dagen!',
      nextButton: 'Nästa',
      cartNotSupported: 'Denna leverantör stöder bara en slotregistrering per bokning.',
      cartSupported: 'Denna leverantör stöder flera registreringar per bokning.',
      workerSelectLable: "Välj Personlig Rådgivare:",
      workerSelectLinkPrefix: "Hittade du ingen tid med din rådgivare?",
      workerSelectShowAllLinkPostfix: "Visa alla rådgivare",
      workerSelectHideAllLinkPostfix: "Dölj alla rådgivare"
    },
    step4: {
      comment_helper_text: 'Maximalt 2000 tecken',
      title: 'Avsluta',
      summary: 'Sammanfattning',
      company: 'Företag',
      store: 'Butik',
      employee: 'Resurs',
      bookedServices: 'Tjänst',
      duration: 'Varaktighet',
      price: 'Pris',
      finalPrice: 'Kostnader',
      total: 'Totalt',
      yourData: 'Dina data',
      disclaimer: 'Ange dina personliga kontaktuppgifter. Alla fält som är markerade med * är obligatoriska.',
      prename: {
        label: 'Förnamn',
        placeholder: 'Förnamn',
        error: {
          minChars: 'Förnamnet måste minst innehålla {{ requiredLength }} tecken.',
          required: 'Ange ett förnamn.'
        }
      },
      lastname: {
        label: 'Efternamn',
        placeholder: 'Efternamn',
        error: {
          minChars: 'Efternamnet måste minst innehålla {{ requiredLength }} tecken.',
          required: 'Ange ett efternamn.'
        }
      },
      phone: {
        label: 'Mobiltelefon',
        placeholder: '+4179xxxxxxx',
        error: {
          pattern: 'Telefonnumret får bara innehålla siffror.',
          minChars: 'Telefonnumret måste minst innehålla {{ requiredLength }} siffror.',
          required: 'Ange ett telefonnummer.'
        }
      },
      eMail: {
        label: 'E-post',
        error: {
          pattern: 'Ange en giltig e-postadress.',
          minChars: 'E-postadressen måste minst innehålla {{ requiredLength }} tecken.',
          required: 'Ange en e-postadress.'
        }
      },
      message: {
        label: 'Meddelande',
        placeholder: ''
      },
      internal_comment: {
        label: 'Intern kommentar',
        placeholder: 'Lägg till kommentarer för internt bruk',
        comment_helper_text: 'Maximalt 5000 tecken.'
      },
      company_name: {
        label: 'Företagsnamn',
        error: {
          minChars: 'Det här fältet kräver minst {{ requiredLength }} tecken.'
        }
      },
      customFields: {
        defaultOption: 'Vänligen välj ett alternativ',
        error: {
          maxChars: 'Det här fältet tillåter maximalt {{ requiredLength }} tecken.',
          minChars: 'Det här fältet kräver minst {{ requiredLength }} tecken.',
          required: 'Det här fältet är obligatoriskt'
        }
      },
      cart: {
        emptyCartWarning: 'Det finns för närvarande inga möten i din kundvagn.',
        removeIcon: 'Ta bort mötet från kundvagnen.',
        waiting_list: 'Väntelista'
      },
      numberSelect: {
        increase: 'Öka värde',
        decrease: 'Minska värde'
      }
    },
    finalpage: {
      successTitle: 'Du har bokat mötet/mötena framgångsrikt',
      successMessageDu: 'Mötesbokningen har framgångsrikt överförts. En bekräftelse har skickats antingen via e-post eller via SMS. <br> <br> Om du av någon anledning inte kan delta i evenemanget ska du avboka ditt deltagande via avbokningslänken i e-postmeddelandet eller sms:et.',
      successMessageSie: 'Mötesbokningen har framgångsrikt överförts. En bekräftelse har skickats antingen via e-post eller via SMS. <br> <br> Om du av någon anledning inte kan delta i evenemanget ska du avboka ditt deltagande via avbokningslänken i e-postmeddelandet eller sms:et.',
      errorTitle: 'Ett fel har inträffat.',
      errorMessage: 'Datumet kunde inte bokas. Vänligen försök igen. Om felet kvarstår, kontakta Calenso support.',
      errorMessageDu: 'Tyvärr, mötet kunde inte bokas. Vänligen försök igen.',
      errorMessageSie: 'Tyvärr, mötet kunde inte bokas. Vänligen försök igen.',
      goBack: 'Tillbaka till tjänster',
      finalDetails: 'Dina bokade möten'
    },
    error: {
      noworkers: 'Tyvärr har denna leverantör ännu inte definierat några bokningsbara resurser.'
    }
  },
  events: {
    list: "Lista",
    grid: "Rutnät",
    noEvents: "Denna leverantör har inte specificerat några händelser än.",
    hidden_events_message: "Händelserna för denna partner är dolda. Använd den direkta länken till händelsen som du har fått från partnern för att registrera dig.",
    filter_result_null: "Det finns inga resultat som matchar dina kriterier.",
    step1: {
      title: "Händelser",
      moreInformation: "Mer information",
      noFreeSlots: "Inga fler lediga platser",
      noEvents: "Det finns inga händelser än.",
      showDescription: "Visa hela beskrivningen",
      hideDescription: "Dölj beskrivningen",
      book: "Boka",
      noPlaceDefined: "Ingen plats definierad",
      store_filter: "Butiksfilter",
      category_filter: "Kategorifilter",
      all_categories: "Alla"
    },
    step2: {
      title: 'Datum',
      slots: 'Platser',
      chooseDate: 'Välj önskat datum',
      booked: 'Bokad',
      capacity: 'Tillgängliga',
      noFreeDates: 'Det finns inga fria händelser för denna partner',
      bookedOut: 'Denna plats är redan bokad',
      cartNotSupported: 'Denna leverantör stöder endast en bokning per gång.',
      cartSupported: 'Denna leverantör stöder flera bokningar per gång.',
      alreadyBookedByUser: 'Du har redan bokat denna plats. Om det uppstår problem, återställ applikationen.',
      waitinglist: 'Väntelista: {{ capacity }} lediga',
      fully_booked: "Fullbokat",
      regular_count_singular: '<b>1</b> ledig plats',
      regular_count_plural: '<b>{{ number }}</b> lediga platser',
      waiting_count_singular: "1 ledig plats på väntelistan",
      waiting_count_plural: "{{ number }} lediga platser på väntelistan",
      free_seats: 'Lediga platser',
      free_waiting_seats: 'Lediga platser på väntelistan',
      multiday_group_appointment: "Detta är en flerdagars gruppbokning"
    },
    step3: {
      title: 'Avsluta',
      summary: 'Sammanfattning',
      company: 'Företag',
      store: 'Butik',
      employee: 'Resurs',
      bookedEvent: 'Händelse',
      date: 'Datum',
      time: 'Tid',
      price: 'Pris',
      finalPrice: 'Kostnad',
      total: 'Totalt',
      yourData: 'Dina uppgifter',
      disclaimer: 'Ange dina kontaktuppgifter. Alla fält markerade med * är obligatoriska.',
      cart: {
        emptyCartWarning: 'Det finns för närvarande inga händelser i varukorgen.',
        waiting_list: 'Väntelista'
      }
    },
    finalpage: {
      successTitle: 'Registreringen skickades framgångsrikt.',
      successMessageDu: 'Registreringen av händelsen skickades framgångsrikt. En bekräftelse har skickats antingen via e-post eller SMS. <br> <br> Om du av någon anledning inte kan delta i evenemanget ska du avboka ditt deltagande via avbokningslänken i e-postmeddelandet eller sms:et.',
      successMessageSie: 'Registreringen av händelsen skickades framgångsrikt. En bekräftelse har skickats antingen via e-post eller SMS. <br> <br> Om du av någon anledning inte kan delta i evenemanget ska du avboka ditt deltagande via avbokningslänken i e-postmeddelandet eller sms:et.',
      errorTitle: 'Ett fel har inträffat.',
      errorMessage: 'Ett fel uppstod under registreringen. Försök igen.',
      bookNextEvent: 'Boka ytterligare registrering',
      bookEventAgain: 'Gå tillbaka'
    }
  },
  resources: {
    noResources: 'Denna leverantör har inte specificerat några resurser än.',
    step1: {
      title: 'Resurser',
      chooseResource: 'Välj resurser'
    },
    step2: {
      title: 'Datum',
      startDate: 'Startdatum: ',
      endDate: 'Slutdatum: ',
      startTime: 'Välj starttid',
      endTime: 'Välj sluttid',
      resetCalendar: 'Återställ kalendern',
      noFreeResources: 'Inga bokningsbara resurser hittades för valt datumintervall',
      selectDate: 'Välj datum eller datumintervall'
    },
    step3: {
      title: 'Avsluta',
      summary: 'Sammanfattning',
      company: 'Företag',
      store: 'Butik',
      employee: 'Resurs',
      bookedEvent: 'Händelse',
      date: 'Datum',
      time: 'Tid',
      price: 'Pris',
      finalPrice: 'Kostnad',
      total: 'Totalt',
      yourData: 'Dina uppgifter',
      disclaimer: 'Ange dina kontaktuppgifter. Alla fält markerade med * är obligatoriska.',
      prename: {
        label: 'Förnamn',
        placeholder: 'Förnamn',
        error: {
          minChars: 'Förnamnet måste vara minst {{ requiredLength }} tecken långt.',
          required: 'Ange förnamn.'
        }
      },
      lastname: {
        label: 'Efternamn',
        placeholder: 'Efternamn',
        error: {
          minChars: 'Efternamnet måste vara minst {{ requiredLength }} tecken långt.',
          required: 'Ange efternamn.'
        }
      },
      phone: {
        label: 'Mobiltelefon',
        placeholder: '+4179xxxxxxx',
        error: {
          pattern: 'Telefonnumret får endast innehålla siffror.',
          minChars: 'Telefonnumret måste vara minst {{ requiredLength }} tecken långt.',
          required: 'Ange ett telefonnummer.'
        }
      },
      eMail: {
        label: 'E-post',
        error: {
          pattern: 'Ange en giltig e-postadress.',
          minChars: 'E-postadressen måste vara minst {{ requiredLength }} tecken lång.',
          required: 'Ange en e-postadress.'
        }
      },
      message: {
        label: 'Meddelande',
        placeholder: ''
      },
      customFields: {
        defaultOption: 'Välj valfritt alternativ',
        error: {
          minChars: 'Detta fält kräver {{ requiredLength }} tecken',
          required: 'Detta fält är obligatoriskt'
        }
      },
      cart: {
        emptyCartWarning: 'Det finns för närvarande inga resurser i varukorgen.'
      },
      From: 'Från',
      To: 'Till'
    },
    finalpage: {
      successTitle: 'Du har framgångsrikt bokat resurs(er)',
      successMessageDU: 'Om du ändrar dig, vänligen avboka din resursbokning minst {{ leadTime }} timmar innan du bokade dem.',
      successMessageSIE: 'Om du ändrar dig, vänligen avboka din resursbokning minst {{ leadTime }} timmar innan du bokade dem.',
      errorTitle: 'Ett fel har inträffat.',
      errorMessage: 'Resursen kunde inte bokas. Försök igen.',
      bookNextEvent: 'Boka ytterligare resurser',
      bookEventAgain: 'Gå tillbaka'
    }
  },
  customer: {
    prename: {
      label: 'Förnamn',
      placeholder: 'Förnamn',
      error: {
        minChars: 'Förnamnet måste vara minst {{ requiredLength }} tecken långt.',
        required: 'Ange ditt förnamn.'
      }
    },
    lastname: {
      label: 'Efternamn',
      placeholder: 'Efternamn',
      error: {
        minChars: 'Efternamnet måste vara minst {{ requiredLength }} tecken långt.',
        required: 'Ange ditt efternamn.'
      }
    },
    phone: {
      label: 'Telefonnummer',
      placeholder: '+4179.......',
      error: {
        pattern: 'Telefonnumret får endast innehålla siffror.',
        minChars: 'Telefonnumret måste innehålla minst {{ requiredLength }} siffror.',
        required: 'Ange ditt telefonnummer.',
        invalid: 'Ange ett giltigt mobilnummer.'
      }
    },
    eMail: {
      label: 'E-post',
      error: {
        pattern: 'Ange en giltig e-postadress.',
        minChars: 'E-postadressen måste vara minst {{ requiredLength }} tecken lång.',
        required: 'En e-postadress krävs.'
      }
    },
    street: {
      label: 'Gata / Nummer',
      placeholder: 'Gatunamn',
      error: {
        minChars: 'Gatan måste innehålla minst {{ requiredLength }} tecken.',
        required: 'Ange ditt gatunamn.'
      }
    },
    zip: {
      label: 'Postnummer',
      placeholder: 'Postnummer',
      error: {
        minChars: 'Postnumret måste innehålla minst {{ requiredLength }} tecken.',
        required: 'Ange ditt postnummer.',
        pattern: "Var god ange ett giltigt postnummer.",
      }
    },
    city: {
      label: 'Stad',
      placeholder: 'Stadsnamn',
      error: {
        minChars: 'Staden måste innehålla minst {{ requiredLength }} tecken.',
        required: 'Ange ditt stadsnamn.'
      }
    },
    message: {
      label: 'Meddelande',
      placeholder: ''
    },
    customFields: {
      defaultOption: 'Välj ett alternativ',
      error: {
        minChars: 'Detta fält kräver minst {{ requiredLength }} tecken.',
        required: 'Detta fält är obligatoriskt.'
      }
    },
    captcha: {
      required: 'Detta fält är obligatoriskt.',
      invalid: 'Ange en giltig captcha.',
      valid: 'Captcha matchades framgångsrikt.',
      placeholder: 'Skriv in texten ovan'
    }
  },
  stripe: {
    stripe_title: 'Betala ditt nästa besök online',
    submit_payment: 'Skicka betalning',
    stripe_label: 'Kreditkort eller betalkort',
    online_payment_optional: 'I detta steg har du möjlighet att betala ditt nästa besök direkt nu. Det tillkommer inga extra kostnader för dig. Betalningen görs via en säker leverantör (Stripe). Vi lagrar inte kreditkortsuppgifter på våra servrar.',
    online_payment_mandatory: 'För att slutföra bokningen ber vi dig att betala ditt nästa besök nu. Det tillkommer inga extra kostnader för dig. Betalningen görs via en säker leverantör (Stripe). Vi lagrar inte kreditkortsuppgifter på våra servrar.'
  },
  alert_messages: {
    select_day_in_calendar_msg: "Välj en specifik dag i kalendern för att få förslag på möjliga datum.",
    no_events_in_month: "Tyvärr finns det inga fler lediga tidsluckor under den valda månaden.",
    no_events: "Tyvärr har denna händelse inga bokningsbara fönster i framtiden. Därför visas ingen kalender för att välja ett datum.",
    waitinglist_enabled_message: "Vissa tidsluckor är redan fullbokade. Registrering på väntelistan är dock möjlig. När en deltagare avbokar sin plats flyttas nästa väntelista-plats upp och får en bekräftelse om deltagande.",
    waitinglist_booking_message: "Tack så mycket! Vi har framgångsrikt accepterat din bokning. Eftersom du nu är på väntelistan för minst en händelse får du bara en bekräftelse om deltagande från oss om en annan deltagare inte kan delta i händelsen. I så fall får du en notifiering via e-post. Tyvärr kan du inte delta i händelsen utan en officiell bekräftelse från vår webbplats på grund av platsbrist.",
    window_is_fully_booked: "Denna tidsperiod är fullbokad (inklusive väntelistan).",
    waitinglist_is_available: "Denna tidslucka är fullbokad, men har fortfarande <b>{{ number }}</b> tillgängliga platser på väntelistan.",
    no_bookable_dates_message: "Tyvärr finns det inga fler bokningsbara dagar i månaden {{ month }}. Ska vi söka efter lediga datum i framtida månader?",
    no_slots_dates_message: "Tyvärr finns det inga fler bokningsbara tidsluckor i månaden {{ month }}. Ska vi söka efter lediga tidsluckor i framtida månader?",
    no_slots_dates_reached_message: "Tyvärr finns det inga fler bokningsbara tidsluckor i månaden {{ month }}. Den maximala bokningsperioden har nåtts.",
    no_slots_years_reached_message: "Tyvärr finns det inga fler bokningsbara tider under året {{ year }}. Den maximala bokningsperioden har uppnåtts.",
    no_bookable_dates_reached_message: "Tyvärr finns det inga fler bokningsbara datum i månaden {{ month }}. Den maximala bokningsperioden har nåtts.",
    xss_error_message: "Denna text innehåller otillåtna HTML-taggar."
  },
  coupon_credit_card_info: {
    redeem_coupon_1: 'Lös in kupong',
    apply_coupon: 'Använd kupong',
    coupon: 'Kupong',
    credit_or_debit_card: 'Kreditkort eller betalkort',
    invalid_coupon: 'Ogiltig kupong',
    applied_successfully: 'Applicerad framgångsrikt'
  },
  tax_info: {
    taxes: '(inkl. {{percentage}}% moms)'
  },
  new_alert_component: {
    x_day_ago: 'för x dag sedan',
    x_days_ago: 'för x dagar sedan',
    x_minute_ago: 'för x minut sedan',
    x_minutes_ago: 'för x minuter sedan',
    x_hour_ago: 'för x timme sedan',
    x_hours_ago: 'för x timmar sedan',
    x_second_ago: 'för x sekund sedan',
    x_seconds_ago: 'för x sekunder sedan',
    x_week_ago: 'för x vecka sedan',
    x_weeks_ago: 'för x veckor sedan',
    x_month_ago: 'för x månad sedan',
    x_months_ago: 'för x månader sedan',
    x_year_ago: 'för x år sedan',
    x_years_ago: 'för x år sedan'
  },
  summary_page_translations: {
    paypal_warning_msg: 'Före en bokning är möjlig måste det öppna beloppet betalas med PayPal.',
    paypal: 'PayPal',
    saferpay_payment_overlay: 'Denna skärm kommer att döljas automatiskt när betalningen har slutförts eller avbrutits.<br/>Om betalningsskärmen stängdes av misstag kan du trycka på stängningsknappen för att dölja den och starta om betalningen.',
    or: 'eller',
    browser: 'Bläddra',
    choose_file: 'Välj fil',
    clear: 'Rensa',
    meeting_type: 'Var ska mötet äga rum?',
    email: 'E-post',
    sms: 'SMS',
    both_sms_email: 'E-post och SMS',
    notification_type: 'Aviseringstyp',
    payment_title: 'Betalning',
    payment_subtitle: 'Välj hur du vill betala för dina tjänster',
    payment_subtitle_sie: 'Välj hur du vill betala för dina tjänster',
    summary_title: 'Sammanfattning',
    summary_subtitle: 'Kontrollera din beställning och skicka den',
    summary_subtitle_sie: 'Kontrollera din beställning och skicka den',
    payment_type: 'Betalningstyp',
    creditcard: 'Kreditkort',
    invoice: 'Faktura',
    store: 'På plats',
    apply_coupon: 'Jag vill använda en rabattkod',
    choose_payment_method: 'Välj betalningsmetod',
    tax: 'inkl. {{ rate }}% moms',
    subtotal: 'Delsumma',
    personal_form_title: 'Personuppgifter',
    personal_form_subtitle: 'Berätta något om dig själv',
    personal_form_subtitle_sie: 'Berätta något om dig själv',
    inclusive: 'inklusive',
    paymentMandatory: 'Leverantören har definierat att tjänsten måste betalas online i förväg. Välj därför en betalningsmetod.',
    file_upload: 'Filuppladdning',
    file_upload_helper_text: 'Flera filer kan bifogas',
    file_was_uploaded: '"{{name}}" laddades upp framgångsrikt',
    max_file_size_5mb: 'Den uppladdade filen är för stor (maximalt 5 MB tillåts).',
    malware_found: 'Det uppstod ett problem vid skanningen av filen för virus (Åtkomst nekad). Vänligen kontakta Calenso Support!',
    multiple_file_upload_helper_text: 'Högst 5 filer kan laddas upp. Den uppladdade filen får inte vara större än 5 MB.',
    single_file_upload_helper_text: '1 fil kan laddas upp. Den uppladdade filen får inte vara större än 5 MB.',
    file_uploading: 'Filuppladdningen pågår fortfarande. Vänligen vänta tills åtgärden är klar och försök sedan att boka igen.',
    maximum_x_files: 'Högst {{fileLength}} filer kan laddas upp.',
    document_invalid_file_type: "Ogiltig filtyp. Endast PDF- och Excel-filer är tillåtna.",
    image_invalid_file_type: "Ogiltig filtyp, ladda upp en giltig bildfil.",
    request_sms_code: 'Begär kod',
    mobile_number_must_be_verified: 'Mobilnumret måste verifieras för bokningen.',
    verification_code: 'Verifieringskod',
    sms_code_verification_failed_msg: 'Den angivna koden matchar tyvärr inte koden vi skickade via SMS.',
    check: 'Kontrollera',
    phone_number_verified_successfully: 'Telefonnumret har verifierats framgångsrikt.',
    resend_sms_helper_msg_1: 'Jag har inte fått någon kod.',
    resend_sms_helper_msg_2: 'Begär kod igen.',
    exceeded_sms_verification_limit_msg: 'Detta telefonnummer har överskridit det maximala antalet verifieringskoder. Kontakta Calenso support (<a href="mailto:support@calenso.com">support@calenso.com</a>).',
    salutation: 'Hälsning',
    mr: 'Herr',
    ms: 'Fru',
    other: 'annat',
    select_payment_method: 'Betala det öppna beloppet med PayPal först, innan du kan skicka bokningen.',
    accept_data_policy: 'Jag godkänner och har läst <a href="https://calenso.com/agb/" target="_blank">Calenso\'s användarvillkor</ a > & <a href="https://calenso.com/datenschutz/" target = "_blank" > integritetspolicy < /a> för användning av mina uppgifter i samband med bokningen av tid.',
    saferpay: 'Saferpay',
    saferpay_pay: 'Tryck för att betala med Saferpay',
    payment_success_title: 'Lyckad betalning',
    payment_success_message: 'Det öppna beloppet har betalats framgångsrikt med {{ paymentMethod }}. Tiden kan nu bokas. Om ingen bokning görs kommer ingen summa att debiteras.',
    title: 'Titel',
    country: 'Land',
    title_aria_describedby: "Speciell titel som stöder Dr., Dr. med., Prof.",
    salutation_aria_describedby: "Hälsningsform som stöder Herr, Fru och andra",
    prename_aria_describedby: "Förnamn eller smeknamn",
    lastname_aria_describedby: "Efternamn",
    notification_type_aria_describedby: "För att kunna definiera i bokningsprocessen hur du vill bli meddelad om bokningen.",
    email_describedby: "E-post",
    phoneNumber_aria_describedby: "Telefonnummer",
    smsPhoneCode_aria_describedby: "OTP för att verifiera telefonnummer",
    mobileNumber_aria_describedby: "Mobilnummer",
    smsMobileCode_aria_describedby: "OTP för att verifiera mobilnummer",
    street_aria_describedby: "Adress",
    zip_aria_describedby: "Postnummer",
    city_aria_describedby: "Stad",
    country_aria_describedby: "Land",
    comment_aria_describedby: "Ytterligare kommentarer om du har några",
    internal_comment_aria_describedby: "Intern kommentar (endast för internt bruk)",
    company_aria_describedby: "Företagsnamn",
    captcha_aria_describedby: "Captcha"
  },
  buttons: {
    yes_search: 'Ja, sök.',
    remove: 'Ta bort'
  },
  add: 'Lägg till',
  compact: {
    no_service: 'Inga tider kan för närvarande bokas eftersom ingen matchad tjänst hittas.',
    navigation: {
      date: 'Datum',
      date_slots: 'Datum och tidsluckor',
      personal_info: 'Personuppgifter'
    }
  }
};
