import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
    selector: 'app-cw-card',
    templateUrl: './cw-card.component.html',
    styleUrls: ['./cw-card.component.scss'],
    standalone: true,
    imports: [],
})
export class CwCardComponent implements OnInit {

  showHeader?: boolean = Boolean(false);
  showBody?: boolean = Boolean(true);
  showFooter?: boolean = Boolean(false);

  @Input() cardClass?: string;
  @Input() cardBodyClass?: string;

  @Output() cardClickEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  onCardClick() {
    this.cardClickEvent.emit();
  }

}
