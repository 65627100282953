import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {WIDGET_CONSTANTS} from '../constants/widget-constants';
import {CountryDbModel} from '../models/country.model';
import {HelperService} from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class CountryHelperService {
  appConstants = WIDGET_CONSTANTS;

  constructor(
    public translate: TranslateService,
    private helperService: HelperService,
  ) { }

  public findCountriesByDefaultLanguage(countries: CountryDbModel[], currentValue: string): CountryDbModel[] {
    let currentLanguage: string;
    const foundLang = this.appConstants.LANGUAGES.find(languages => languages.locale === currentValue);
    if (foundLang) {
      currentLanguage = foundLang.locale;
    } else {
      currentLanguage = 'de_CH';
    }
    return this.createNewCountries(countries, currentLanguage);
  }

  public createNewCountries(countries: CountryDbModel[], countryLangKey: string): CountryDbModel[] {
    let newCountries: CountryDbModel[] = [];
    for (const country of countries) {
      const newCountry: CountryDbModel = new CountryDbModel();
      newCountry.id = country.id;
      newCountry.identifier = country.identifier;
      newCountry.name = country.name;
      newCountry.is_multi_language = country.is_multi_language;
      newCountry._translations = country._translations;
      newCountries.push(newCountry);
    }

    newCountries = this.helperService.sortBy(newCountries, 'name');

    return newCountries;
  }
}
