import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'eventToMinute',
    standalone: true
})
export class EventReplaceMinutePipe implements PipeTransform {
    transform(value: number): string {
    const m = Math.floor(value % 60 % 60);
    const mDisplay = m;
    return ' ' + mDisplay;
  }
}
