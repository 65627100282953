import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';

import { FormsModule } from '@angular/forms';

declare var Stripe: any;

@Component({
    selector: 'app-stripe',
    templateUrl: './stripe.component.html',
    styleUrls: ['./stripe.component.scss'],
    standalone: true,
    imports: [FormsModule]
})
export class StripeComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('cardInfo') cardInfo: ElementRef;
  card: any;
  error: string;
  stripe: any;
  elements: any;
  paymentIntentClientSecret: { secret: string };

  @Output('stripeSuccessfulEvent') stripeSuccessfulEvent: EventEmitter<string> = new EventEmitter();
  @Output('resetStripeTokenEvent') resetStripeTokenEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private cd: ChangeDetectorRef,
  ) {
  }

  ngAfterViewInit() {
    const style = {
      base: {
        color: '#495057',
        lineHeight: '24px',
        // fontFamily: 'Open Sans',
        fontSmoothing: 'antialiased',
        fontSize: '13px',
        '::placeholder': {
          color: '#495057'
        }
      }
    };
    this.card = this.elements.create('card', {style, hidePostalCode: true});
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.onChange.bind(this));
    this.card.addEventListener('blur', this.onSubmit.bind(this));
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.onChange.bind(this));
    this.card.removeEventListener('blur', this.onSubmit.bind(this));
    this.card.destroy();
  }

  onChange({error}) {
    this.resetStripeTokenEvent.emit();
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit() {
    const {token, error} = await this.stripe.createToken(this.card);

    if (error) {
      this.resetStripeTokenEvent.emit();
    } else {
      this.stripeSuccessfulEvent.emit(token.id);
    }
  }

  ngOnInit() {
    this.stripe = Stripe(environment.stripeAPIKey);
    this.elements = this.stripe.elements();
  }
}
