import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keepOrderKeyvalue',
  standalone: true
})
export class KeepOrderKeyvaluePipe implements PipeTransform {
  transform(value: { [key: string]: string }): Array<{ key: string, value: string }> {
    return Object.keys(value).map(key => ({ key: key, value: value[key] }));
  }
}
