import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PayPalOrderDetailModel, PayPalSettingsDbModel } from '../../../db-models/partner-setting.model';
import { ICreateOrderRequest, IPayPalConfig, NgxPayPalModule } from 'ngx-paypal';
import { Partner } from '../../../models/global';
import { CustomEventService } from '../../../services/custom-event.service';
import { LoggerService } from '../../../services/logger.service';
import { CartItem, EventCartItem, GuestModel } from '../../../models/cart.model';
import { AppointmentService } from '../../../services/appointment.service';
import { WIDGET_CONSTANTS } from '../../../constants/widget-constants';

@Component({
  selector: 'app-cw-paypal',
  templateUrl: './cw-paypal.component.html',
  standalone: true,
  imports: [NgxPayPalModule]
})
export class CwPaypalComponent implements OnInit {

  @Input() paypalSetting: PayPalSettingsDbModel;
  @Input() price: number;
  @Input() discount: number;
  @Input() partner: Partner;
  @Input() cart: CartItem[];
  @Input() appoinrmentServicesAvailable: any;
  @Input() eventCart: EventCartItem[];
  @Input() widgetType: string;
  @Input() additionalGuests: GuestModel[] = [];

  @Output() paypalSuccessfulEvent = new EventEmitter<PayPalOrderDetailModel>();
  @Output() resetPaypalEvent = new EventEmitter<any>();

  payPalConfig: IPayPalConfig;
  showSuccess = false;
  showCancel = false;
  showError = false;

  constructor(
    private customEventService: CustomEventService,
    private appointmentService: AppointmentService,
  ) {}

  ngOnInit(): void {
    this.customEventService.resetPayPalOrderDetailEvent.subscribe({
      next: () => {
        this.discount = undefined;
        this.payPalConfig = undefined;
        setTimeout(() => {
          this.initConfig();
        }, 300);
      }
    });

    this.customEventService.cartItemsChangeEvent.subscribe({
      next: (result: {
        cart?: CartItem[],
        eventCart?: EventCartItem[],
        type: string,
      }) => {
        this.discount = undefined;
        this.payPalConfig = undefined;
        if (result.type === WIDGET_CONSTANTS.APPOINTMENT) {
          this.cart = result.cart;
        } else {
          this.eventCart = result.eventCart;
        }

        setTimeout(() => {
          this.initConfig();
        }, 500);
      }
    });

    this.customEventService.couponChangeEvent.subscribe({
      next: (couponDiscount: number) => {
        if (this.widgetType === WIDGET_CONSTANTS.APPOINTMENT || this.widgetType === WIDGET_CONSTANTS.COMPACT) {
          this.discount = couponDiscount;
          this.payPalConfig = undefined;
          setTimeout(() => {
            this.initConfig();
          }, 1000);
        }
      }
    });

    this.customEventService.additionalGuestChangeEvent.subscribe({
      next: (result: {
        finalPrice: number,
        discount: number,
        additionalGuests: GuestModel[]
      }) => {
        if (this.discount === result.discount && this.price === result.finalPrice && this.additionalGuests?.length === result.additionalGuests?.length) {
        } else {
          this.payPalConfig = undefined;
          this.discount = result.discount;
          this.price = result.finalPrice;
          this.additionalGuests = result.additionalGuests;
          setTimeout(() => {
            this.initConfig();
          }, 200);
        }
      }
    });

    this.initConfig();
  }

  private initConfig(): void {
    let items: any;
    if (this.widgetType === WIDGET_CONSTANTS.APPOINTMENT || this.widgetType === WIDGET_CONSTANTS.COMPACT) {
      items = this.getAppointmentPaypalItems();
    } else {
      items = this.getEventPaypalItems();
    }

    let totalPrice: number = Number(this.price);
    if (this.discount > 0) {
      totalPrice = totalPrice + this.discount;
    }
    this.payPalConfig = {
      currency: this.partner.currency,
      clientId: this.paypalSetting.client_id,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            currency_code: this.partner.currency,
            value: this.price + '',
            breakdown: {
              item_total: {
                currency_code: this.partner.currency,
                value: totalPrice + '',
              },
              discount: {
                currency_code: this.partner.currency,
                value: (this.discount > 0 ? this.discount + '' : '0')
              }
            }
          },
          items: items
        }]
      },
      advanced: {
        commit: 'true'
      },
      /*style: {
        label: 'paypal',
        layout: 'vertical'
      },*/
      // locale: 'en_US',
      style: {
        color: 'gold',
        shape: 'rect',
        // label: '',
      },
      onApprove: (data, actions) => {
        LoggerService.log('[Paypal debug] onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          LoggerService.log('[Paypal debug] onApprove - you can get full order details inside onApprove: ', details);
        });

      },
      onClientAuthorization: (data: any) => {
        LoggerService.log('[Paypal debug] onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.showSuccess = true;
        const newPayPalOrderDetailModel: PayPalOrderDetailModel = {
          paypal_order_id: data.id,
          paypal_payer_id: data.payer ? data.payer.payer_id : null,
          paypal_status: data.status,
          paypal_raw_data: JSON.stringify(data),
          paypal_capture_id: undefined,
        };

        if (data?.purchase_units && data?.purchase_units[0]) {
          if (data?.purchase_units[0]?.payments
            && data?.purchase_units[0]?.payments?.captures
            && data?.purchase_units[0]?.payments?.captures[0]) {
            newPayPalOrderDetailModel.paypal_capture_id = data?.purchase_units[0]?.payments?.captures[0].id;
          }
        }

        LoggerService.log('[Paypal debug] newPayPalOrderDetailModel ', newPayPalOrderDetailModel);

        this.paypalSuccessfulEvent.emit(
          newPayPalOrderDetailModel
        );
      },
      onCancel: (data, actions) => {
        LoggerService.log('[Paypal debug] OnCancel', data, actions);
        this.showCancel = true;

      },
      onError: err => {
        LoggerService.log('[Paypal debug] OnError', err);
        this.showError = true;
      },
      onClick: (data, actions) => {
        LoggerService.log('[Paypal debug] onClick', data, actions);
        this.resetStatus();
      }
    };
  }

  resetStatus(): void {
    this.showError = false;
    this.showSuccess = false;
    this.showCancel = false;
  }

  getAppointmentPaypalItems(): any[] {
    const items: {
      name: string,
      quantity: number,
      unit_amount: {
        value: string,
        currency_code: string,
      }
    }[] = [];

    for (const cartItem of this.cart) {
      const services: any[] = this.getServicesByIds({ serviceIds: cartItem.servicesIds });
      const servicesList: string[] = [];
      if (services && services.length > 0) {
        for (const service of services) {
          servicesList.push(service?.name);
        }
      }
      if ((cartItem.total.price / services.length) > 0) {
        items.push({
          name: servicesList.join(','),
          quantity: 1,
          unit_amount: {
            value: cartItem.total.price,
            currency_code: this.partner.currency
          }
        });
      } else {
        items.push({
          name: servicesList.join(','),
          quantity: 1,
          unit_amount: {
            value: 0 + '',
            currency_code: this.partner.currency
          }
        });
      }
    }

    LoggerService.log('[Paypal debug] Final paypal items ', items);

    return items;
  }

  getServicesByIds({ serviceIds, returnKey = 'services' }: { serviceIds: number[]; returnKey?: string }) {
    return this.appointmentService.getServicesByIds({ serviceIds, returnKey }, this.appoinrmentServicesAvailable);
  }

  getEventPaypalItems(): any[] {
    const items: {
      name: string,
      quantity: number,
      unit_amount: {
        value: string,
        currency_code: string,
      }
    }[] = [];

    let quantity: number = Number(1);
    if (this.additionalGuests?.length > 0) {
      quantity = quantity * (this.additionalGuests.length + 1);
    }

    for (const cartItem of this.eventCart) {
      if (cartItem.currentEvent?.price > 0) {
        items.push({
          name: cartItem.currentEvent.title,
          quantity: quantity,
          unit_amount: {
            value: cartItem.currentEvent?.price + '',
            currency_code: this.partner.currency
          }
        });
      } else {
        items.push({
          name: cartItem.currentEvent.title,
          quantity: quantity,
          unit_amount: {
            value: 0 + '',
            currency_code: this.partner.currency
          }
        });
      }
    }

    return items;
  }

  onPaypalScriptLoaded(event: any): void {
    LoggerService.log('[Paypal debug] Is paypal script loaded', event);
  }
}
