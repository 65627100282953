import {StoresDao} from './stores-dao';

export class UserDao {
  id: number;
  prename: string;
  lastname: string;
  avatar: string;
  partner_id: number;
  username: string;
  email: string;
  job_title: string;
  bookable: number;
  store_id: number;
  photo_dir: string;
  registration_date: Date;
  updated: Date;
  first_login: number;
  street: string;
  zip: string;
  city: string;
  mobile: string;
  timezone: string;
  show_in_pro_calendar: number;
  status: number;
  uuid: string;
  full_name: string;
  store: StoresDao;
  selectedLanguage: string;
  browserLanguage: string;
  resource_name: string;
  booking_label: string;
  is_resource: number;
  booking_type_id: number;
  description: string;
  is_lead_generator: number;
  is_reseller_admin: number;
  is_multi_language: number;
  is_round_robin_enabled?: number;
  _translations: {
    [key: string]: UserTranslation;
  };
}

export class UserTranslation {
  job_title: string;
  booking_label: string;
  description: string;
  locale: string;
  full_name: string;
  resource_name: string;
}
