import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { ConferenceDbModel } from '../db-models/conference-db.model';

@Injectable({
  providedIn: 'root'
})
export class ConferenceService {

  constructor(
    public http: HttpClient
  ) { }

  getConferenceDetails(conferenceUuid: string): Observable<ConferenceDbModel> {
    return this.http.get<ConferenceDbModel>(environment.apiUrl + `booking_links/${conferenceUuid}`, {}).pipe(
      catchError((error: HttpErrorResponse) => { throw error; }),
      switchMap(result => {
        if (result?.errors) {
          throw new HttpErrorResponse({
            error: new Error(result.errors[0].message),
            statusText: result.errors[0].code,
            status: result.errors[0].code
          });
        } else
        return of(result);
      })
    );
  }
}
