@for (customerProfileOrder of defaultCustomerProfilesOrder; track customerProfileOrder; let last = $last) {
  @switch (customerProfileOrder) {
    @case ('customer') {
      <app-cw-new-card [showHeader]="true" [showBody]="true" customCardClass="mb-4">
        <div header>
          <app-cw-card-header
            title="{{ ((widgetTemplates | calioMeetingTemplate: { id: 120, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang) }}"
            subtitle="{{ ((widgetTemplates | calioMeetingTemplate: { id: 121, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang) }}"
          />
        </div>
        <div body class="data-container mb-4">
          @if (globals?.isInternal) {
            <div class="row cb-personal-data-form-layout mt-4">
              <div class="col-sm-12 d-flex">
                <div class="form-group w-100">
                  <div>
                    <label class="input-label d-flex align-items-center" id="lable_select_customer" for="selectedCustomer">
                      {{ "internal.search_customer" | translate }}
                    </label>

                    <ng-select
                      id="selectedCustomer"
                      name="selectedCustomer"
                      [loading]="isCustomerLoading"
                      [items]="options"
                      [multiple]="false"
                      placeholder="{{ 'internal.selectPlaceholder' | translate }}"
                      bindLabel="text"
                      bindValue="id"
                      [typeahead]="searchCustomers"
                      [(ngModel)]="selectedCustomerId"
                      (change)="onCustomerSelected($event)"
                      attr.aria-describedby="{{ 'internal.selectPlaceholder' | translate }}"
                      attr.aria-labelledby="lable_select_customer"
                      tabindex="1"
                    >
                      <ng-template ng-loadingspinner-tmp>
                        <div class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>

                <div class="d-flex align-items-end ms-2">
                  <button type="button" class="btn btn-danger clear-button" (click)="clearUserData()">
                    {{ 'summary_page_translations.clear' | translate }}
                  </button>
                </div>
              </div>
            </div>
          }

          @if (globals?.isInternal) {
            <div class="row cb-personal-data-form-layout">
              <div class="col-sm-12 summary-padding mt-4 mb-3 or-text">
                <span class=""> --- {{ 'summary_page_translations.or' | translate }} --- </span>
              </div>
            </div>
          }

          <app-customer-form
            [index]="3"
            [customerRequiredFields]="defaultRequiredCustomerFields"
            [customerFormFields]="defaultCustomerFields"
            [userState]="userState"
            [globals]="globals"
            [partner]="partner"
            [userDataLSNameKey]="userDataLSNameKey"
            [widgetTemplates]="widgetTemplates"
            [type]="type"
            [lang]="lang"
            [meetingTypes]="meetingTypes"
            [appointmentCart]="appointmentCart"
            [notificationTypes]="NOTIFICATION_TYPES"
            [showBeforeCustomField]="partnerService?.customFieldsBefore?.length > 0"
            [showAfterCustomField]="partnerService?.customFieldsAfter?.length > 0"
            [appointmentState]="appointmentState"
            [stores]="stores"
          >
            <app-customfields
              beforeCustomField
              [(userState)]="userState"
              [globals]="globals"
              [customFields]="partnerService.customFieldsBefore"
              [partner]="partner"
              [(customFieldValues)]="customFieldValues"
              [customFieldValuesLSNameKey]="customFieldValuesLSNameKey"
              [selectedJsonCustomFields]="selectedJsonCustomFields"
              [type]="appointmentConstant.APPOINTMENT"
              [renderBefore]="true"
              [lang]="lang"
            />

            <app-customfields
              afterCustomField
              [(userState)]="userState"
              [globals]="globals"
              [customFields]="partnerService.customFieldsAfter"
              [partner]="partner"
              [(customFieldValues)]="customFieldValues"
              [type]="appointmentConstant.APPOINTMENT"
              [selectedJsonCustomFields]="selectedJsonCustomFields"
              [customFieldValuesLSNameKey]="customFieldValuesLSNameKey"
              [renderBefore]="false"
              [lang]="lang"
            />
          </app-customer-form>
        </div>
      </app-cw-new-card>

      <!-- Additional guest fields -->
      @if (showAdditionalGuestsBlock) {
        <app-cw-new-card [showHeader]="true" [showBody]="true">
          <div header>
            <app-cw-card-header
              title="{{ 'common.additionalGuest' | translate }}"
              subtitle="{{ 'common.additionalParticipantsUpTo' | translate : { number: additionalGuestsBlockTotalCapacity } }}"
            />
          </div>
          <div body class="data-container mb-4">
            <app-additional-guest
              [eventState]="eventState"
              [cart]="cart"
              [partner]="partner"
              [widgetTemplates]="widgetTemplates"
              [lang]="lang"
              (updateAdditionalGuestsEvent)="updateAdditionalGuests($event)"
            />
          </div>
        </app-cw-new-card>
        }
      }

    @case ('broker') {
      @if (showBrokerCustomerForm) {
        <div class="mb-4">
          <app-cw-new-card [showHeader]="true" [showBody]="true">
            <div header>
              <app-cw-card-header
                title="{{ brokerCustomerProfile | translation: 'title': lang }}"
                subtitle="{{ brokerCustomerProfile | translation: 'description': lang }}"
              />
            </div>
            <div body class="data-container mb-4">
              <app-customer-form
                [index]="2"
                [customerRequiredFields]="brokerRequiredCustomerFields"
                [customerFormFields]="brokerCustomerFields"
                [userState]="brokerState"
                [globals]="globals"
                [partner]="partner"
                [widgetTemplates]="widgetTemplates"
                [type]="type"
                [lang]="lang"
                [meetingTypes]="meetingTypes"
                [appointmentCart]="appointmentCart"
                [notificationTypes]="NOTIFICATION_TYPES"
                [appointmentState]="appointmentState"
              />
            </div>
          </app-cw-new-card>
        </div>
      }
    }
  }
}
