import { NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'calio-mat-icon',
  templateUrl: './mat-icon.component.html',
  styleUrls: ['./mat-icon.component.scss'],
  standalone: true,
  imports: [MatIcon, NgStyle, NgbTooltip]
})
export class MatIconComponent {

  @Input() iconType: string;
  @Input() customClass: string;
  @Input() height: string;
  @Input() width: string;
  @Input() fill: string;
  @Input() tooltip: string;
  @Input() placement: string;

  @Output() clickEvent = new EventEmitter<void>();
}
