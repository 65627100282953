import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cw-checkbox',
  templateUrl: './cw-checkbox.component.html',
  styleUrls: ['./cw-checkbox.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: true,
  imports: [FormsModule, NgClass, TranslateModule]
})
export class CwCheckboxComponent {

  @Input() id: string;
  @Input() name: string;
  @Input() isDisabled = false;
  @Input() fieldNgModel: any;
  @Input() isRequired = false;
  @Input() customLabelClass = 'ps-2';

  @Output() selectionChange = new EventEmitter<{ event: any, id: any }>();

  onChange(event: any, id: any): void {
    this.selectionChange.emit({ event: event, id: id });
  }
}
