import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { APPOINTMENT_CONSTANT } from '../../../constants/appointment-constants';
import { TRANSLATION_TEMPLATES } from '../../../constants/translation-templates-constants';
import { WIDGET_CONSTANTS } from '../../../constants/widget-constants';
import { CustomFieldsDao } from '../../../db-models/appointment-custom-fields-dao';
import { CustomBookingMessageTemplate } from '../../../db-models/widget-conf-dao';
import { GlobalObjects, Partner } from '../../../models/global';
import { AppointmentState, UserState } from '../../../models/state.model';
import { WidgetColorConf } from '../../../models/widget-color.model';
import { FormsService } from '../../../services/forms.service';
import { HelperService } from '../../../services/helper.service';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { TranslationPipe } from '../../../shared/pipes/translation.pipe';
import { CustomfieldsComponent } from '../customfields/customfields.component';

@Component({
  selector: 'app-qualification-question',
  templateUrl: './qualification-question.component.html',
  styleUrls: ['./qualification-question.component.scss'],
  standalone: true,
  imports: [NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, FormsModule, CustomfieldsComponent, ButtonComponent, TranslationPipe]
})
export class QualificationQuestionComponent {

  @ViewChild('qualificationQuestion', { read: NgForm }) qualificationQuestionForm: NgForm;

  @Input() appointmentState: AppointmentState;
  @Input() qualificationQuestionsValues: {
    [key: number]: {
      type: string,
      value: any | any[],
      option_values: {
        custom_field_option_id: number,
        custom_field_option_value: string,
      }[]
    } | any
  };
  @Input() qualificationQuestionLSNameKey: string;
  @Input() globals: GlobalObjects;
  @Input() qualificationQuestions: CustomFieldsDao[] = [];
  @Input() lang: string;
  @Input() isFrame: boolean;
  @Input() userState: UserState;
  @Input() partner: Partner;
  @Input() widgetColorConf: WidgetColorConf;
  @Input() set widgetTemplates(templates: CustomBookingMessageTemplate[]) {
    if (templates?.length > 0) {
      this._widgetTemplates = templates;
      this.setupTemplates();
    }
  };
  @Input() supportedWidgetLanguages: string[] = [];
  @Input() langSwitcher = true;

  @Output() submitted = new EventEmitter<boolean>();

  readonly appointmentConstant = APPOINTMENT_CONSTANT;
  readonly languages = WIDGET_CONSTANTS.LANGUAGES;
  readonly widgetLabels = WIDGET_CONSTANTS.WIDGET_LABELS;
  readonly deployUrl = environment.deployUrl;
  readonly templateContent = TRANSLATION_TEMPLATES;

  _widgetTemplates: CustomBookingMessageTemplate[] = [];
  widgetQualificationBasedQuestionsTitle: CustomBookingMessageTemplate;
  widgetQualificationBasedQuestionsDescription: CustomBookingMessageTemplate;
  widgetQualificationBasedQuestionsSkipButton: CustomBookingMessageTemplate;
  widgetQualificationBasedQuestionsNextButton: CustomBookingMessageTemplate;

  constructor(
    private translateService: TranslateService,
    private helperService: HelperService,
    private formsService: FormsService
  ) {
    this.translateService.onLangChange.subscribe(lang => this.lang = lang.lang);
  }

  switchLanguage(language: string): void {
    this.lang = language;
    this.translateService.use(language);
    this.globals.user_selected_language = language;
  }

  setupTemplates(): void {
    this.widgetQualificationBasedQuestionsTitle = this.helperService.findTemplate(
      this._widgetTemplates,
      this.widgetLabels.WIDGET_QUALIFICATION_BASED_QUESTIONS_TITLE
    );
    this.widgetQualificationBasedQuestionsDescription = this.helperService.findTemplate(
      this._widgetTemplates,
      this.widgetLabels.WIDGET_QUALIFICATION_BASED_QUESTIONS_DESCRIPTION
    );
    this.widgetQualificationBasedQuestionsSkipButton = this.helperService.findTemplate(
      this._widgetTemplates,
      this.widgetLabels.WIDGET_QUALIFICATION_BASED_QUESTIONS_SKIP_BUTTON
    );
    this.widgetQualificationBasedQuestionsNextButton = this.helperService.findTemplate(
      this._widgetTemplates,
      this.widgetLabels.WIDGET_QUALIFICATION_BASED_QUESTIONS_NEXT_BUTTON
    );
  }

  submitQualificaionQuestion(invalidForm: boolean): void {
    this.qualificationQuestionForm.control.markAllAsTouched();
    if (!invalidForm && this.formsService.imageUploaded === true) {
      this.submitted.emit(true);
    }
  }

  skipQualificaionQuestion(invalidForm: boolean): void {
    this.qualificationQuestionForm.control.markAllAsTouched();
    if (!invalidForm) {
      this.submitted.emit(null);
    }
  }
}
