import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl, ValidationErrors, Validator } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { CalioTranslationSortPipe } from '../../pipes/calio-translation-sort.pipe';
import { TranslationPipe } from '../../pipes/translation.pipe';

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CountrySelectComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CountrySelectComponent),
    multi: true
  }],
  standalone: true,
  imports: [NgSelectModule, FormsModule, ReactiveFormsModule, TranslationPipe, CalioTranslationSortPipe]
})

export class CountrySelectComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() id: string;
  @Input() countries: any[];
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() searchable: boolean;
  @Input() clearable: boolean;
  @Input() placeholder: string;
  @Input() multiple: boolean;
  @Input() closeOnSelect = true;
  @Input() isDisabled = false;
  @Input() required = false;
  @Output() selectChange = new EventEmitter<any>();
  selectInputControl: UntypedFormControl = new UntypedFormControl({ disabled: this.isDisabled });
  currentLang: string;
  private onTouch: Function;
  private onModelChange: Function;
  private onValidatorChange: Function;

  constructor(
    private translate: TranslateService,
  ) {
    this.currentLang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe(language => this.currentLang = language.lang);
  }

  ngOnInit(): void {
    this.selectInputControl.valueChanges.subscribe({
      next: (result: any) => {
        if (!result && this.onModelChange) {
          this.onModelChange(result);
        }
        if (result && this.onModelChange) {
          this.onModelChange(result);

          if (this.onTouch && !this.selectInputControl.touched) {
            this.onTouch();
          }
          if (this.onValidatorChange) {
            this.onValidatorChange();
          }
        }
      }
    });
  }

  onSelectChange(event: any): void {
    this.selectChange.emit(event);
  }

  onClick(): void {
    if (this.onTouch) {
      this.onTouch();
    }
  }

  onBlur(): void {
    if (this.onTouch) {
      this.onTouch();
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.selectInputControl.setValue(obj);
  }

  validate(control: AbstractControl): ValidationErrors {
    return null;
  }

  registerOnValidatorChange?(fn: any): void {
    this.onValidatorChange = fn;
  }
}
