import { Component, EventEmitter, inject, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { WidgetColorConf } from '../../../models/widget-color.model';
import { HelperService } from '../../../services/helper.service';
@Component({
  selector: 'calenso-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule]
})
export class ButtonComponent implements OnInit {

  @Input() id: any;
  @Input() text = '';
  @Input() icon = '';
  @Input() disabled: boolean;
  @Input() placement = 'left';
  @Input() customCss = '';
  @Input() widgetColorConf?: WidgetColorConf;

  @Output('clickEvent') clickEvent = new EventEmitter<any>();

  @ViewChildren('button') buttons: QueryList<any>;

  private helperService = inject(HelperService);
  protected loader = false;

  ngOnInit() {
    this.helperService.bookingLoader.subscribe(value => {
      this.loader = value ? true : false;
    });
  }

  // TODO(Devs): Check this
  customizeAppearance(): void {
  }

  // TODO(Devs): Check this
  setButtonStyle() {
    return {};
  }
}
