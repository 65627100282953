@if (!isGuestEditModeEnable) {
  <div class="row cbw-event-additional-guest-layout">
    @for (additionalGuest of additionalGuests; track additionalGuest; let index = $index) {
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3 cbw-event-additional-guest-cards">
        <div class="additional-guest-card d-flex align-items-center justify-content-between gap-2 p-3 w-100">
          <div class="d-flex flex-column justify-content-start additional-guest-info">
            <div class="title">{{ additionalGuest.prename }} {{ additionalGuest.lastname }}</div>
            @if (additionalGuest?.email) {
              <div class="sub-title">{{ additionalGuest?.email }}</div>
            }
            @if (additionalGuest?.phone ) {
              <div class="sub-title">{{ additionalGuest?.phone }}</div>
            }
          </div>
          <div class="d-flex align-items-center justify-content-center gap-2">
            <calio-mat-icon iconType="edit" height="20px" width="20px" fill="#000000" (clickEvent)="editAdditionalGuests(additionalGuest, index)" customClass="cursor-pointer" />
            <calio-mat-icon iconType="close" height="20px" width="20px" fill="#000000" (clickEvent)="removeAdditionalGuest(index)" customClass="cursor-pointer" />
          </div>
        </div>
      </div>
    }

    @if (showAddAdditionalGuestButton) {
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3 cbw-event-additional-guest-cards" (click)="addNewAdditionalGuest()">
        <div class="additional-guest-card additional-guest d-flex align-items-center justify-content-center gap-2 w-100">
          <calio-mat-icon iconType="person_add" height="25px" width="25px" fill="#3A4A66" />
          <div>{{ 'common.addAdditionalGuest' | translate }}</div>
        </div>
      </div>
    }
  </div>
} @else {
  <form #additionalGuestForm="ngForm">
    <div class="row cb-event-additional-guest-layout">
      <div class="col-md-6 col-lg-3 custom-field form-group summary-padding cbw-event-additional-guest-firstname">
        <label class="input-label" for="guestFirstName" id="label_guestFirstName">
          {{ 'customer.prename.label' | translate | addRequired}}
        </label>
        <input
          maxlength="100"
          autocomplete="name"
          [(ngModel)]="tempAdditionalGuests.prename"
          placeholder="{{ 'customer.prename.placeholder' | translate }}"
          name="guestFirstName"
          id="guestFirstName"
          type="text"
          class="form-control cbw-input-field"
          required
          #firstName="ngModel"
          [disabled]="disableAdditionalParticipantsEditing"
          attr.aria-disabled="{{ disableAdditionalParticipantsEditing }}"
          attr.aria-labelledby="label_guestFirstName"
        />
        <ng-container *ngTemplateOutlet="errorHandler; context: { control: firstName, message: 'customer.prename.error' }"/>
      </div>

      <div class="col-md-6 col-lg-3 custom-field form-group summary-padding cbw-event-additional-guest-lastname">
        <label class="input-label" for="guestLastName" id="label_guestLastName">{{ 'customer.lastname.label' | translate | addRequired}}</label>
        <input
          id="guestLastName"
          name="guestLastName"
          maxlength="100"
          autocomplete="name"
          [(ngModel)]="tempAdditionalGuests.lastname"
          placeholder="{{ 'customer.lastname.placeholder' | translate }}"
          type="text"
          class="form-control cbw-input-field"
          required
          #lastName="ngModel"
          [disabled]="disableAdditionalParticipantsEditing"
          attr.aria-disabled="{{ disableAdditionalParticipantsEditing }}"
          attr.aria-labelledby="label_guestLastName"
        />
        <ng-container *ngTemplateOutlet="errorHandler; context: { control: lastName, message: 'customer.lastname.error' }"/>
      </div>

      <div class="col-md-6 col-lg-3 custom-field form-group summary-padding cbw-event-additional-guest-email">
        <label class="input-label" for="guestEmail" id="label_guestEmail">{{ 'customer.eMail.label' | translate | addRequired: { isRequired: eventState?.is_widget_event_guest_email_required } }}</label>
        <input
          id="guestEmail"
          name="guestEmail"
          autocomplete="email"
          [(ngModel)]="tempAdditionalGuests.email"
          required="{{ !!eventState?.is_widget_event_guest_email_required }}"
          [placeholder]="((widgetTemplates | calioMeetingTemplate: { id: 169, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang )"
          type="email"
          email
          #internaleMail="ngModel"
          class="form-control cbw-input-field"
          [disabled]="disableAdditionalParticipantsEditing"
          attr.aria-disabled="{{ disableAdditionalParticipantsEditing }}"
          attr.aria-labelledby="label_guestEmail"
        />
        <ng-container *ngTemplateOutlet="errorHandler; context: { control: internaleMail, message: 'customer.eMail.error' }"/>
      </div>

      <div class="col-md-6 col-lg-3 custom-field form-group summary-padding cbw-event-additional-guest-phone">
        <label class="input-label" for="guestphone" id="label_guestphone">{{ 'customer.phone.label' | translate | addRequired: { isRequired: eventState?.is_widget_event_guest_phone_required } }}</label>
        <app-cw-intl-tel-input
          [(ngModel)]="tempAdditionalGuests.phone"
          required="{{ !!eventState?.is_widget_event_guest_phone_required }}"
          name="phoneNumber"
          id="phoneNumber"
          #phoneNumber="ngModel"
          cssClass="{{phoneNumber.touched && phoneNumber.invalid ? 'guest-form-tel-input' : ''}}"
          [defaultCountry]="partner?.countryCode"
          attr.autocomplete="tel"
          tabIndex="4"
          attr.aria-invalid="{{ phoneNumber.touched && phoneNumber.invalid }}"
          attr.aria-describedby="phoneNumber-hint"
          attr.aria-labelledby="label_phoneNumber"
        />
        <ng-container *ngTemplateOutlet="errorHandler; context: { control: phoneNumber, message: 'customer.phone.error' }"/>
      </div>

      <div class="col-12 mt-3">
        <div class="d-flex justify-content-end gap-2">
          <calenso-button
            text="{{ 'common.cancel' | translate }}"
            customCss="cbw-add-additional-guest-button"
            id="cbw-add-additional-guest-button"
            [widgetColorConf]="widgetColorConf"
            (clickEvent)="cancel();"
          />
          <calenso-button
            text="{{ 'common.save' | translate }}"
            customCss="cbw-cancel-additional-guest-button"
            id="cbw-cancel-additional-guest-button"
            [widgetColorConf]="widgetColorConf"
            (clickEvent)="save()"
          />
        </div>
      </div>
    </div>
  </form>
}

<ng-template #errorHandler let-control="control" let-message="message">
  @if (control.touched && control.invalid) {
    <div role="alert" class="alert alert-danger summary-warning">
      @if (control.errors?.required) {
        {{ message + '.required' | translate }}
      }
      @if (control.errors?.invalidNumber) {
        {{ message + '.invalid' | translate }}
      }
      @if (control.errors?.minlength) {
        {{ message + '.minChars' | translate: control.errors?.minlength }}
      }
      @if (control.errors?.pattern) {
        {{ message + '.pattern' | translate }}
      }
      @if (control.errors?.email) {
        {{ message + '.pattern' | translate }}
      }
    </div>
  }
</ng-template>
