<div class="row mt-4 d-flex justify-content-center">

  @for (customerProfile of partner?.customer_profiles; track customerProfile; let index = $index; let odd = $odd; let even = $even) {
    <div class="col-lg-4 col-md-4 col-sm-6 mt-3"
      [ngClass]="{ 'offset-md-1': even }"
      >
      <app-cw-card
        [cardBodyClass]="'d-flex align-items-center justify-content-center'"
        [cardClass]="(customerProfile.id === appointmentState.customerProfileId) ? 'selected-card cbw-customer-profile-card': 'cbw-customer-profile-card'"
        (cardClickEvent)="onSelectCustomerProfile(customerProfile);"
        >
        <div body class="d-flex flex-column align-items-center justify-content-center"
          >
          @if (customerProfile.icon) {
            <div class="">
              <fa-icon class="icon customer-profile-icon" [icon]="customerProfile.icon"></fa-icon>
            </div>
          }
          <div class="cbw-title">
            {{ customerProfile | translation: 'title': lang }}
          </div>
          <div class="cbw-function text-muted text-center">
            {{ customerProfile | translation: 'description': lang }}
          </div>
      </div>
    </app-cw-card>
  </div>
}

</div>

<div class="d-flex align-items-center justify-content-end mt-4 next-btn-custom-right-padding">
  <calenso-button
    id="{{ 'cbw-next-button' }}"
    text="{{ nextButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}"
    icon="chevron-right"
    placement="right"
    [disabled]="!appointmentState.customerProfileId"
    [widgetColorConf]="widgetColorConf"
    (clickEvent)="navigateTo(appointmentConstant.APPOINTMENT)"
  >
  </calenso-button>
</div>
