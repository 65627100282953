<div
  class="calenso-booking"
  [ngClass]="{
    'compact-layout': isCompactMode
  }"
  >
  <div #calioSection id="calenso-booking" class="container-fluid">
    @if (!isCompactMode) {
      @if (widgetConf != null && bookTypesEnabled.length > 1) {
        <header>
          <div class="row cal-bookingtype-row">
            <br/>
            <div class="col-xs-12 col-md-12">
              <span>
                <h4 class="switcher-title">{{ 'common.choseBookType' | translate }}</h4>
              </span>
            </div>
          </div>
          <ng-select
            [multiple]="false"
            [items]="bookTypesEnabled"
            [(ngModel)]="bookTypesEnabled[0]"
            (change)="switchForm($event)"
            #bookSelect
            >
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <div class="">
                {{ item | translate}}
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="">
                {{ item | translate}}
              </div>
            </ng-template>
          </ng-select>
        </header>
      }
      @if (tokenError === false && showInternalWidgetDisabledError === false && showDevAlert) {
        <div>
          <app-alert [text]="'common.devNotice' | translate" type="warning" />
        </div>
      }
      @if (tokenError === false && showInternalWidgetDisabledError === false && !localStorageaccessible) {
        <div>
          <app-alert [text]="'common.store_access_warning' | translate" type="warning" />
        </div>
      }
      @if (currentBookingType === widgetConstants.APPOINTMENT) {
        @if (tokenError === false && partnerGlobalData && showWorkersNotAvailableError && partnerGlobalData.bookingwidget_enabled === 1 && showInternalWidgetDisabledError === false) {
          <div>
            <app-new-alert
              [title]="'common.workers_not_available' | translate"
              [subTitle]="'common.workers_not_available_description' | translate"
              [date]="now"
              type="warning"
              customClass="mt-3 mb-3"
              />
            </div>
          }
        }
        @if (partnerGlobalData && partnerGlobalData.bookingwidget_enabled === 0) {
          <div>
            <app-new-alert
              [title]="'common.widget_disabled' | translate"
              [subTitle]="'common.widget_disabled_description' | translate"
              [date]="now"
              type="warning"
              customClass="mt-3 mb-3"
              />
            </div>
          }
          @if (showSubscriptionError) {
            <div>
              <app-new-alert
                [title]="'common.subscription_error_title' | translate"
                [subTitle]="'common.subscription_error_desc' | translate"
                [date]="now"
                type="danger"
                customClass="mt-3 mb-3"
                />
              </div>
            }
            @if (showInternalWidgetDisabledError) {
              <div>
                <app-new-alert
                  [title]="'common.configuration_error' | translate"
                  [subTitle]="'common.internal_disabled' | translate"
                  [date]="now"
                  type="danger"
                  customClass="mt-3 mb-3"
                  />
                </div>
              }
              @if (!partnerGlobalData && !showSubscriptionError && tokenError === false && showInternalWidgetDisabledError === false) {
                <div>
                  <div class="skeleton-stats-card mt-3">
                    <div class="">
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-md-0">
                          <div class="d-flex stats-card shadow align-items-center">
                            <div class="skeleton-animation px-3 w-100">
                              <div class="bar bar-1"></div>
                              <div class="bar bar-2"></div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-md-0">
                          <div class="d-flex stats-card shadow align-items-center">
                            <div class="skeleton-animation px-3 w-100">
                              <div class="bar bar-1"></div>
                              <div class="bar bar-2"></div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-md-0">
                          <div class="d-flex stats-card shadow align-items-center">
                            <div class="skeleton-animation px-3 w-100">
                              <div class="bar bar-1"></div>
                              <div class="bar bar-2"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="skeleton-box-card mt-4">
                    <div class="">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="d-flex stats-card shadow">
                            <div class="skeleton-animation py-5 px-5 w-100">
                              <div class="">
                                <div class="stat-box"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              @if (partnerGlobalData && partnerGlobalData.is_maintenance_mode_enabled === 1 && tokenError === false) {
                <div>
                  <div class="d-flex flex-column justify-content-center align-items-center">
                    <div>
                      <img class="maintenance-image" [src]="baseUrl + '/img/calenso_maintenance.svg'" alt="Maintenance" />
                    </div>
                    <div class="maintenance-title">
                      {{ 'maintenance_page_title' | translate }}
                    </div>
                    <div class="mt-3 maintenance-text">
                      {{ 'maintenance_page_subtitle' | translate }}
                    </div>
                    <div class="mt-3 maintenance-text">
                      <calenso-button
                        [id]="'cbw-maintenance-page-button'"
                        #button
                        (clickEvent)="onMaintenancePageButton()"
                        [widgetColorConf]="widgetColorConf"
                        placement="left"
                        [text]="'maintenance_page_button' | translate"
                        />
                      </div>
                    </div>
                  </div>
                }
                @if (errorInConforence) {
                  <div>
                    <app-new-alert
                      subTitle="{{ errorInConforence | translate }}"
                      type="danger"
                      customClass="mt-3 mb-3"
                      />
                    </div>
                  }
                  @if (tokenError === false && partnerGlobalData && showInternalWidgetDisabledError === false && partnerGlobalData.bookingwidget_enabled === 1 && partnerGlobalData.is_maintenance_mode_enabled !== 1 && errorInConforence === undefined) {
                    @if (widgetConf) {
                      <div>
                        @if (currentBookingType === widgetConstants.APPOINTMENT && !showWorkersNotAvailableError) {
                          @if (partnerGlobalData) {
                            <app-appointment
                              [widgetColorConf]="widgetColorConf"
                              [partner]="partnerGlobalData"
                              [globals]="globals"
                              [widgetBookingInfo]="widgetBookingInfo"
                              [lang]="_lang"
                              [isFrame]="isFrame"
                              [selectedStoreId]="selectedStoreId"
                              [selectedStoreZipCode]="selectedStoreZipCode"
                              [selectedStoreName]="selectedStoreName"
                              [selectedAppointmentServiceIds]="selectedAppointmentServiceIds"
                              [selectedAppointmentServiceCategoryIds]="selectedAppointmentServiceCategoryIds"
                              [selectedWorkerId]="selectedWorkerId"
                              [selectedJsonCustomFields]="selectedJsonCustomFields"
                              [selectedJsonCustomerDetail]="selectedJsonCustomerDetail"
                              [calendarPreselectedDate]="calendarPreselectedDate"
                              [dateContext]="dateContext"
                              [dateContextStart]="dateContextStart"
                              [dateContextEnd]="dateContextEnd"
                              [widgetConf]="widgetConf"
                              [bookerWorkerId]="bookerWorkerId"
                              [utmSource]="utmSource"
                              [utmMedium]="utmMedium"
                              [utmContent]="utmContent"
                              [utmCampaign]="utmCampaign"
                              [utmTerm]="utmTerm"
                              [isBookingDisabled]="isBookingDisabled"
                              [foundedWorker]="foundedWorker"
                              [meeting_type_id]="meeting_type_id"
                              [customer_notification_preference]="customer_notification_preference"
                              [internalComment]="internalComment"
                              [langSwitcher]="langSwitcher"
                              [conferenceState]="conferenceState"
                              [hideStoreSelection]="hideStoreSelection"
                              [debug]="debug"
                              [token]="token"
                              (bookingSuccessEvent)="onBookingSuccessfull()"
                              />
                          }
                        }
                        @if (currentBookingType === widgetConstants.EVENT) {
                          @if (partnerGlobalData) {
                            <app-event
                              [widgetColorConf]="widgetColorConf"
                              [partner]="partnerGlobalData"
                              [globals]="globals"
                              [widgetBookingInfo]="widgetBookingInfo"
                              [lang]="_lang"
                              [selectedJsonCustomFields]="selectedJsonCustomFields"
                              [selectedJsonCustomerDetail]="selectedJsonCustomerDetail"
                              [filterStores]="filterStores"
                              [filterCategories]="filterCategories"
                              [widgetConf]="widgetConf"
                              [utmSource]="utmSource"
                              [utmMedium]="utmMedium"
                              [utmContent]="utmContent"
                              [utmCampaign]="utmCampaign"
                              [utmTerm]="utmTerm"
                              [meeting_type_id]="meeting_type_id"
                              [customer_notification_preference]="customer_notification_preference"
                              [uiMode]="uiMode"
                              [hideGridSwitcher]="hideGridSwitcher"
                              [langSwitcher]="langSwitcher"
                              />
                          }
                        }
                        @if (currentBookingType === widgetConstants.SUCCESS) {
                          <div>
                            @if (partnerGlobalData) {
                              <app-finalpage
                                [partner]="partnerGlobalData"
                                [success]="true"
                                />
                            }
                          </div>
                        }
                        @if (currentBookingType === widgetConstants.ERROR) {
                          <div>
                            @if (partnerGlobalData) {
                              <app-finalpage
                                [partner]="partnerGlobalData"
                                [success]="false"
                                />
                            }
                          </div>
                        }
                      </div>
                    } @else {
                      <div class="init-container"></div>
                    }
                  }
                  @if (tokenError === true) {
                    <div>
                      <app-new-alert
                        title="{{ 'common.configuration_error' | translate }}"
                        type="danger"
                        customClass="mt-3 mb-3"
                        [subTitle]="tokenErrorMessage | translate"
                        [date]="now"
                        />
                      </div>
                    }
                  }

                  @if (isCompactMode) {
                    @if (partnerGlobalData) {
                      <app-compact-widget
                        [calendarPreselectedDate]="calendarPreselectedDate"
                        [dateContext]="dateContext"
                        [dateContextStart]="dateContextStart"
                        [dateContextEnd]="dateContextEnd"
                        [globals]="globals"
                        [lang]="_lang"
                        [isFrame]="isFrame"
                        [langSwitcher]="langSwitcher"
                        [partner]="partnerGlobalData"
                        [selectedAppointmentServiceIds]="selectedAppointmentServiceIds"
                        [customer_notification_preference]="customer_notification_preference"
                        [selectedStoreId]="selectedStoreId"
                        [selectedJsonCustomerDetail]="selectedJsonCustomerDetail"
                        [selectedJsonCustomFields]="selectedJsonCustomFields"
                        [showSubscriptionError]="showSubscriptionError"
                        [widgetColorConf]="widgetColorConf"
                        [worker]="foundedWorker"
                        [bookerWorkerId]="bookerWorkerId"
                        [utmSource]="utmSource"
                        [utmMedium]="utmMedium"
                        [utmContent]="utmContent"
                        [utmCampaign]="utmCampaign"
                        [utmTerm]="utmTerm"
                        [isBookingDisabled]="isBookingDisabled"
                        [widgetConf]="widgetConf"
                        [debug]="debug"
                        [token]="token"
                        [widgetBookingInfo]="widgetBookingInfo"
                        />
                    }
                  }
                </div>

                @if (analytics) {
                  <div>
                    @if (analytics.GTM && analytics?.GTM.trim() !== '') {
                      <app-gtm-operation
                        [gtmID]="analytics.GTM"
                        />
                    }
                  </div>
                }
              </div>
