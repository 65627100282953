import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CustomFieldValueModel, CustomFieldsDao } from '../../../db-models/appointment-custom-fields-dao';
import { GlobalObjects, Partner } from '../../../models/global';
import { AppointmentState, UserState } from '../../../models/state.model';
import { CustomEventService } from '../../../services/custom-event.service';
import { FormsService } from '../../../services/forms.service';
import { LoggerService } from '../../../services/logger.service';
import { CustomFieldViewComponent } from '../custom-field-view/custom-field-view.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-customfields',
  templateUrl: './customfields.component.html',
  styleUrls: ['./customfields.component.scss'],
  viewProviders: [{
    provide: ControlContainer, useExisting: NgForm
  }],
  standalone: true,
  imports: [NgClass, CustomFieldViewComponent]
})
export class CustomfieldsComponent implements OnInit {

  @Input() appointmentState: AppointmentState;
  @Input() userState: UserState;
  @Input() globals: GlobalObjects;
  @Input() customFields: CustomFieldsDao[];
  @Input() partner: Partner;
  @Input() selectedJsonCustomFields: {
    [key: number]: { type: string; value: any } | any;
  };
  @Input() customFieldValues: {
    [key: number]: {
      type: string, value: any, option_values: {
        custom_field_option_id: number, custom_field_option_value: string,
      }[]
    } | any
  };
  @Input() isMobile: boolean;
  @Input() type: string;
  @Input() renderBefore: boolean;
  @Input() lang: string;
  @Input() customFieldValuesLSNameKey: string;
  @Input() widgetType: string;

  constructor(
    public translate: TranslateService,
    private formsService: FormsService,
    private customEventService: CustomEventService
  ) {
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
  }

  ngOnInit() {
    this.customEventService.customerChangedInInternalWidgetEvent.subscribe((data: { customerUuid: number }) => {
      this.getCustomFieldValues(data.customerUuid);
    });
  }

  getCustomFieldValues(customerUuid: number) {
    const tempCustomFields = Object.assign([], this.customFields) as CustomFieldsDao[];
    this.customFields = [];
    this.formsService.getCustomFieldValues(customerUuid).subscribe({
      next: (data) => {
        if (tempCustomFields && tempCustomFields.length > 0 && data && data.length > 0) {
          for (const customField of tempCustomFields) {
            const filteredCustomFieldValues: CustomFieldValueModel[] = data.filter((item: CustomFieldValueModel) => {
              return item.custom_field_id === customField.id;
            });
            if (filteredCustomFieldValues.length > 0) {
              switch (customField.type) {
                case 'text':
                case 'textarea':
                case 'date':
                case 'radio':
                  this.customFieldValues[customField.id] = filteredCustomFieldValues[0].value;
                  break;
                case 'select':
                case 'card-select':
                case 'image-select':
                  if (customField.is_multiple_select === 1) {
                    this.customFieldValues[customField.id] = {
                      type: 'select',
                      value: filteredCustomFieldValues.map(item => Number(item.value))
                    };
                  } else {
                    this.customFieldValues[customField.id] = {
                      type: 'select',
                      value: Number(filteredCustomFieldValues[0].value)
                    };
                  }
                  break;
                case 'number-select':
                  // @TODO: need to handle this once backend is ready
                  break;
                case 'checkbox':
                  this.customFieldValues[customField.id] = Boolean(filteredCustomFieldValues[0].value);
                  break;
                case 'file':
                  break;
                default:
              }
            }
          }
        }
        this.customFields = tempCustomFields;
      },
      error: (error: HttpErrorResponse) => LoggerService.log(error)
    });
  }
}
