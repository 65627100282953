export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_ISO_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATE_TIME_ISO_T_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_TIME_ISO_FORMAT = 'YYYY-MM-DD HH:mm';

export const TIME_FORMAT = 'HH:mm';

export const DATE_PIPE_WITH_COMMA = 'DD.MM.YYYY, HH:mm';
