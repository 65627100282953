import {Component, OnInit, Input} from '@angular/core';


@Component({
    selector: 'app-cw-new-card',
    templateUrl: './cw-new-card.component.html',
    styleUrls: ['./cw-new-card.component.scss'],
    standalone: true,
    imports: []
})
export class CwNewCardComponent implements OnInit {

  shadowClass = 'shadow';
  @Input() customCardClass = '';
  @Input() showShadow = true;
  @Input() showHeader= false;
  @Input() showBody= true;
  @Input() showFooter= false;

  constructor() { }

  ngOnInit() {
    if (!this.showShadow) { this.shadowClass = ''; }
  }
}
