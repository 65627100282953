import * as Sentry from "@sentry/angular-ivy";
import { environment } from "./environments/environment";

export const SentryConfig: Sentry.BrowserOptions = {
  environment: 'production',
  release: environment.version,
  dsn: "https://4a3a465a05d39ec80baf0f227dce9786@o4506308503273472.ingest.sentry.io/4506339323609088",
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['https://webcomponent.widget.calenso.com', 'https://widget.calenso.com', 'https://my.calenso.com/api'],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.8,
};
