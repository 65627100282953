import { Component, Input, OnInit } from '@angular/core';


@Component({
    selector: 'app-cw-card-header',
    templateUrl: './cw-card-header.component.html',
    styleUrls: ['./cw-card-header.component.scss'],
    standalone: true,
    imports: []
})
export class CwCardHeaderComponent implements OnInit {

  @Input() showHeader: boolean = Boolean(true);
  @Input() title: string;
  @Input() subtitle: string;

  constructor() {
  }

  ngOnInit() {
  }

}
