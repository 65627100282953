import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { GTM_EVENTS } from '../constants/widget-constants';
import { MappedAppointmentServicesDao } from '../db-models/appointment-service-dao';
import { GoogleAnalyticsService } from './google-analytics.service';
import { LocalStorageService } from './local-storage.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  protected _mappedServiceIds: MappedAppointmentServicesDao = {};
  appointment_services_uuids: string[] = [];

  get mappedServiceIds(): number[] {
    const mappedServiceIds = [].concat(...Object.values(this._mappedServiceIds).map(customFieldMapeedServices => customFieldMapeedServices.serviceIds));
    return [... new Set(mappedServiceIds)];
  }
  set mappedServiceIds(serviceIdObj: MappedAppointmentServicesDao) {
    for (const customFieldIdKey in serviceIdObj) {
      let mappedServiceIdsArray = [];
      if (serviceIdObj[customFieldIdKey].is_multiple_select) {
        const oldValues = this._mappedServiceIds[customFieldIdKey]?.serviceIds || [];
        mappedServiceIdsArray = [...oldValues, ...serviceIdObj[customFieldIdKey].serviceIds];
      } else {
        mappedServiceIdsArray = serviceIdObj[customFieldIdKey].serviceIds;
      }

      if (this._mappedServiceIds[customFieldIdKey]) {
        this._mappedServiceIds[customFieldIdKey].serviceIds = mappedServiceIdsArray;
      } else {
        this._mappedServiceIds[customFieldIdKey] = {
          serviceIds: mappedServiceIdsArray,
          is_multiple_select: serviceIdObj[customFieldIdKey].is_multiple_select,
        };
      }
      break;
    }
  }

  disableFirstTwoButtonsEvent = new EventEmitter<any>();
  resetSlotSettingsEvent = new EventEmitter<any>();
  resetAppointmentCategoryWiseListEvent = new EventEmitter<void>();
  gtmStartTriggered = false;

  constructor(
    public http: HttpClient,
    protected localStorageService: LocalStorageService,
    private utilService: UtilService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
  }

  resetmappedServiceIds(): void {
    this._mappedServiceIds = {};
  }

  // Form cart item unique id identificator
  public createCartItemId(workerId: number, selectedDay: Date, shortStart: number): string {
    return workerId + '-' + this.utilService.dateToStr(selectedDay) + '-' + shortStart;
  }

  // Get services by their ids
  public getServicesByIds(
    { serviceIds, returnKey = 'services' }: { serviceIds: number[]; returnKey?: string },
    appoinrmentServicesAvailable: any
  ): any {
    const res: {
      services: object[], totalDuration: number, totalPrice: number, tax: number, isPriceFromActivated: number
    } = {
      services: [],
      totalDuration: 0,
      totalPrice: 0,
      tax: 0,
      isPriceFromActivated: 0
    };

    for (const i in appoinrmentServicesAvailable) {
      if (serviceIds.indexOf(appoinrmentServicesAvailable[i]['id']) !== -1) {
        res.isPriceFromActivated = ((appoinrmentServicesAvailable[i].is_price_from_activated !== undefined)
          ? appoinrmentServicesAvailable[i].is_price_from_activated
          : 0);
        res.tax = appoinrmentServicesAvailable[i].tax ? appoinrmentServicesAvailable[i].tax.rate : 0;
        res.services.push(appoinrmentServicesAvailable[i]);

        if (appoinrmentServicesAvailable[i]['duration']) {
          res.totalDuration += Number(appoinrmentServicesAvailable[i]['duration'].replace(',', '.').replace(' ', ''));
        }
        if (appoinrmentServicesAvailable[i]['price'] && appoinrmentServicesAvailable[i]['price'] > 0) {
          if (res.totalPrice === -1) {
            res.totalPrice = Number(appoinrmentServicesAvailable[i]['price']);
          } else {
            res.totalPrice += Number(appoinrmentServicesAvailable[i]['price']);
          }
        } else if (appoinrmentServicesAvailable[i]['price'] && appoinrmentServicesAvailable[i]['price'] === -1) {
          if (res.totalPrice > 0) {
            // res.totalPrice += Number(appoinrmentServicesAvailable[i]['price']);
          } else {
            res.totalPrice = -1;
          }
        }
      }
    }

    switch (returnKey) {
      case 'services':
        return res.services;
      case 'total':
        return {
          duration: res.totalDuration,
          price: res.totalPrice,
          tax: res.tax,
          taxPrice: (res.totalPrice > 0) ? Number((res.totalPrice / 100 * res.tax).toFixed(2)) : 0,
          isPriceFromActivated: res.isPriceFromActivated
        };
    }
  }

  triggerApoointmentStartEvent(): void {
    if (this.gtmStartTriggered === false) {
      this.googleAnalyticsService.emitBookingEvent(GTM_EVENTS.APPOINTMENT_BOOKING_START);
      this.gtmStartTriggered = true;
    }
  }

  triggerCompactWidgetStartEvent(): void {
    if (this.gtmStartTriggered === false) {
      this.googleAnalyticsService.emitBookingEvent(GTM_EVENTS.COMPACT_WIDGET_BOOKING_START);
      this.gtmStartTriggered = true;
    }
  }
}
