import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'isEmptyString',
    standalone: true
})
export class IsEmptyStringPipe implements PipeTransform {

  transform(value: string): any {
    if (!value || value?.trim() === '' || value === 'null' || value === null || value === undefined) {
      return undefined;
    }
    return value;
  }

}
