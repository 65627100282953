import { Injectable } from '@angular/core';
import { EventDao } from '../db-models/event-dao';
import { EventDateDao } from '../db-models/event-date-dao';
import { CurrentEvent, EventCartItem } from '../models/cart.model';
import { EventState } from '../models/state.model';
import { HelperService } from './helper.service';
import { LocalStorageService } from './local-storage.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class EventCartService {

  totalCartDuration = 0;
  totalCartPrice = 0;

  constructor(
    private localStorageService: LocalStorageService,
    private utilService: UtilService,
    private helperService: HelperService
  ) { }

  addToCart(
    eventInfo: EventDateDao,
    eventState: EventState,
    cart: EventCartItem[],
    cartLsName: string,
    events: EventDao[],
    overwrite: boolean,
    callback: any
  ): void {
    const cartItem = this.createEventCartItem(eventState, eventInfo, events);
    this.localStorageService.getItem(cartLsName, (lsCart: EventCartItem[]) => {
      cart = this.initCartValue(lsCart, cart);

      if (overwrite) {
        cart.push(cartItem);
      } else {
        cart = [];
        cart.push(cartItem);
      }

      this.utilService.updateLocalStorage(cartLsName, cart);
      this.recalculateCart(cart);
      callback(cart);
    });
  }

  removeFromCart(
    cartItemId: number,
    cartLsName: string,
    cart: EventCartItem[],
    callback: any
  ): void {
    this.localStorageService.getItem(cartLsName, (lsCart: EventCartItem[]) => {
      cart = this.initCartValue(lsCart, cart);
      cart = this.deleteCartItemFromCart(cartItemId, cart);
      this.utilService.updateLocalStorage(cartLsName, cart);
      this.recalculateCart(cart);
      callback(cart);
    });
  }

  recalculateCart(cart: EventCartItem[]): void {
    if (cart) {
      this.totalCartDuration = 0;
      this.totalCartPrice = 0;
      cart.forEach(cartItem => {
        this.totalCartDuration = this.totalCartDuration + cartItem.duration;
        if (cartItem.currentEvent?.price > 0) {
          this.totalCartPrice += cartItem.currentEvent.price;
        }
      });
    }
  }

  initCartValue(
    lsCart: EventCartItem[],
    cart: EventCartItem[]
  ): EventCartItem[] {
    cart = (lsCart != null) ? lsCart : [];
    return cart;
  }

  deleteCartItemFromCart(
    cartItemId: number,
    cart: EventCartItem[]
  ): EventCartItem[] {
    for (let i = cart.length - 1; i >= 0; i--) {
      if (cart[i].slotId === cartItemId) {
        cart.splice(i, 1);
      }
    }
    return cart;
  }

  createEventCartItem(
    eventState: EventState,
    eventInfo: EventDateDao,
    events: EventDao[]
  ): EventCartItem {
    const cartItem = {
      slotId: eventInfo?.id,
      workerId: eventInfo?.worker_id,
      date: eventState.date,
      startTime: new Date(eventInfo?.start),
      endTime: new Date(eventInfo?.end),
      duration: eventInfo?.duration,
      eventId: eventState.eventId,
      currentEvent: this.getCurrentEvent(eventState.eventId, events),
      category: eventInfo?.event_category || null,
      is_waitinglist_enabled: !!Number(eventInfo.is_waitinglist_enabled),
      is_waitinglist: eventInfo.isWaitingList,
      regular_booking_count: eventInfo.regular_booking_count,
      regular_capacity: eventInfo.regular_capacity,
      waitinglist_booking_count: eventInfo.waitinglist_booking_count,
      waitinglist_capacity: eventInfo.waitinglist_capacity,
      available_regular_capacity: eventInfo.regular_capacity - eventInfo.regular_booking_count,
      available_waitinglist_capacity: eventInfo.waitinglist_capacity - eventInfo.waitinglist_booking_count,
      location: eventInfo?.location || null,
      meeting_type_id: eventInfo?.meeting_type_id
    } as EventCartItem;

    if (events?.length) {
      const foundedEvent = events?.find(event => (event.uuid == eventState.eventId || event.id == eventState.eventId));
      foundedEvent && (cartItem.referenceEvent = foundedEvent);
    }

    // If `has_one_slot` is enabled for mulitday event then find child slots as needs to be shown
    // in summary page
    cartItem.childSlots = [];
    if (eventInfo?.parent_id && eventState?.has_one_slot === 1 && eventState?.slots?.length) {
      cartItem.childSlots = eventState.slots.filter(slot => slot.parent_id === eventInfo.parent_id);
      cartItem.childSlots.sort(this.helperService.sortArrayObjectsByField('date'));
    }

    return cartItem;
  }

  getCurrentEvent(eventId: string | number, events: EventDao[]): CurrentEvent {
    for (const i in events) {
      if (events[i]['uuid'] == eventId || events[i]['id'] == eventId) {
        const tax = events[i]['tax'] ? events[i]['tax'].rate : 0;
        const taxPrice = events[i]['price'] > 0 ? Number(((events[i]['price'] / 100) * tax).toFixed(2)) : 0;

        return {
          title: events[i]['title'],
          description: events[i]['description'],
          place: events[i]['place'],
          image: events[i]['image'],
          price: events[i]['price'],
          tax: taxPrice,
          taxRate: tax,
        };
      }
    }
  }
}
