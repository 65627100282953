import {Component, Input, OnInit} from '@angular/core';
import { TrustHtmlPipe } from '../../pipes/trust-html.pipe';
@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    standalone: true,
    imports: [TrustHtmlPipe]
})
export class AlertComponent implements OnInit {
  @Input() text: string | string[] = '';
  @Input() type = '';
  @Input() title = '';
  @Input() dismissableButton: boolean;
  isMultipleMessages = false;

  constructor() {
  }

  ngOnInit(): void {
    this.isMultipleMessages = Array.isArray(this.text);
    if (!this.type) {
      this.type = 'success';
    }
  }
}
